import './index.css';
import { Auth } from 'aws-amplify';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectTemporaryUser } from '../../app/userSlice';
import { FormHeader } from '../../components/formHeader';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchUserDataSuccess } from '../../app/authSlice';

export function SetPassword(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    const [passwordShown, setPasswordShown] = useState<boolean>(false);
    const [repeatPasswordShown, setRepeatPasswordShown] =
        useState<boolean>(false);
    const [error, setError] = useState<string>();

    const user = useAppSelector(selectTemporaryUser);

    const handleSetPassword = useCallback(
        async (data) => {
            try {
                if (data.password === '') {
                    setError('Passwords should not be empty!');
                } else if (data.password !== data.repeatPassword) {
                    setError('Passwords must match!');
                } else {
                    Auth.completeNewPassword(user, data.password)
                        .then((response) => {
                            dispatch(fetchUserDataSuccess(response));
                            navigate('/');
                        })
                        .catch((error) => setError(error.message));
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
                setError(e.message);
            }
        },
        [user]
    );

    return (
        <div className='login-box set-password'>
            <FormHeader />
            <div className='login-form'>
                <h1>{t('welcome_email', { email })}</h1>
                <p>{t('set_new_password_hint')}</p>
                <form>
                    <div className='password-wrapper'>
                        <label>{t('password')}</label>
                        <input
                            className={`input-form ${
                                error !== undefined ? 'error-form' : ''
                            }`}
                            type={passwordShown ? 'text' : 'password'}
                            id='password'
                            {...register('password')}
                        />
                        <div
                            className='toggle-password'
                            onClick={() => setPasswordShown(!passwordShown)}
                        >
                            <img
                                alt='hide-password'
                                src='/images/ico_hide_password.svg'
                            />
                        </div>
                    </div>
                    <div className='password-wrapper'>
                        <label>{t('repeat_password')}</label>
                        <input
                            className={`input-form ${
                                error !== undefined ? 'error-form' : ''
                            }`}
                            type={repeatPasswordShown ? 'text' : 'password'}
                            id='repeat-password'
                            {...register('repeatPassword')}
                        />
                        <div
                            className='toggle-password'
                            onClick={() =>
                                setRepeatPasswordShown(!repeatPasswordShown)
                            }
                        >
                            <img
                                alt='hide-password'
                                src='/images/ico_hide_password.svg'
                            />
                        </div>
                    </div>
                    {error !== undefined && (
                        <div className='error-toast'>
                            <img alt='error' src='/images/ico_error.svg' />
                            {error}
                        </div>
                    )}
                    <button
                        className='login-button'
                        onClick={handleSubmit(handleSetPassword)}
                    >
                        {t('set_new_password')}
                    </button>
                </form>
            </div>
        </div>
    );
}
