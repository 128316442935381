export function getColorByAttendance(percentage: number): string {
    switch (true) {
        case percentage < 0.6:
            return 'green';
        case percentage < 0.9:
            return 'orange';
        default:
            return 'red';
    }
}
