import { MenuItemDto } from '../../api-types';
import { capitalizeFirstLetter } from '../../utils/capitalizeCase';
import { FoodIcon } from '../foodIcon';

interface IProps {
    menuItem: MenuItemDto;
}

export function DishItem({ menuItem }: IProps): JSX.Element {
    return (
        <div className='menu-item-main'>
            <FoodIcon
                foodType={menuItem.icon}
                color={menuItem.icon.toLowerCase()}
            />
            <div className='menu-item-name' role='menu-item-name'>
                {capitalizeFirstLetter(menuItem.title)}
            </div>
        </div>
    );
}
