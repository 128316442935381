import './index.css';
import Calendar from '../calendar';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { selectCanUpdate, selectUserSettings } from '../../app/userSlice';
import { useCallback } from 'react';
import { LoginType } from '../../api-types/models/LoginDto';
import { selectAuthUser } from '../../app/authSlice';
import { getUserGroups } from '../../utils/getUserGroups';
import { getUserInitials } from '../../utils/getUserInitials';
import { SideMenuItem } from '../sideMenuItem';
import useAmplifyAuth from '../../hooks/useAmplifyAuth';
import { getRoutesBasedOnUserGroups } from '../../utils/getRoutesBasedOnUserGroups';
import { isOneOfTheFitnessGroups } from 'utils/isOneOfTheFitnessGroups';

const SideBar: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { handleSignout } = useAmplifyAuth();

    const isProfilePage = location.pathname === '/profile';

    const canUpdate = useAppSelector(selectCanUpdate);
    const user = useAppSelector(selectAuthUser);
    const profile = useAppSelector(selectUserSettings);

    const handleRedirectBack = useCallback(() => {
        if (canUpdate) {
            if (confirm(t('continue_message'))) {
                navigate('/');
            }
        } else navigate('/');
    }, [canUpdate]);

    if (['/status'].includes(location.pathname)) {
        return <></>;
    }

    const routes = getRoutesBasedOnUserGroups(user);

    if (profile?.officeManagement && !routes.includes('/users')) {
        routes.push('/users');
    }

    const isBrunchOrFitness =
        getUserGroups(user).includes(LoginType.BRUNCH) ||
        isOneOfTheFitnessGroups(user);

    return (
        <div className='sidebar'>
            {isProfilePage ? (
                <div onClick={handleRedirectBack}>
                    <div
                        className='profile-button'
                        hidden={user !== undefined && isBrunchOrFitness}
                    >
                        <img
                            className='switch-image back'
                            src='/images/ico_close.png'
                            alt='Close'
                        />
                    </div>
                </div>
            ) : !user || !isBrunchOrFitness ? (
                <div className='profile-wrapper'>
                    <NavLink to='/profile'>
                        <div className='profile-button'>
                            <img
                                className='switch-image profile'
                                src='/images/profile.png'
                                alt='Profile'
                            />
                        </div>
                    </NavLink>
                </div>
            ) : (
                <div className='profile-wrapper for-initials'>
                    <div className='initials' onClick={handleSignout}>
                        <div className='content'>
                            {getUserInitials(user.attributes?.email ?? '')}
                        </div>
                        <div className='signout'>
                            <img
                                src='/images/ico_logout.svg'
                                alt='logout'
                                height='14px'
                            />
                        </div>
                    </div>
                </div>
            )}
            {location.pathname !== '/profile' && (
                <div className='sidemenu'>
                    {
                        // Remove profile from routes as it is already displayed
                        routes
                            .filter((path) => path !== '/profile')
                            .map((route) => (
                                <SideMenuItem
                                    route={route}
                                    key={route}
                                    pathname={location.pathname}
                                />
                            ))
                    }
                </div>
            )}
            {!isBrunchOrFitness && location.pathname === '/' && (
                <div
                    className={`calendar ${isProfilePage ? 'hidden' : 'show'}`}
                >
                    <Calendar />
                </div>
            )}
        </div>
    );
};

export default SideBar;
