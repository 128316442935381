import { useTranslation } from 'react-i18next';
import IFoodFilter from '../../../../interfaces/foodFilter';
import { FoodIcon } from '../../../../components/foodIcon';

import './index.css';

export function FoodFilterElement({
    foodName,
    enabled
}: IFoodFilter): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className='food-type'>
            <div
                className={
                    'food-type-icon-wrapper ' +
                    (enabled ? 'food-type-enabled' : 'food-type-disabled')
                }
            >
                <FoodIcon
                    foodType={foodName.toLowerCase()}
                    color={enabled ? 'blue' : undefined}
                />
            </div>
            <div className='food-type-value'>{t(foodName.toLowerCase())}</div>
        </div>
    );
}

export default FoodFilterElement;
