import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormHeader } from '../../components/formHeader';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

export function ForgotPassword(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { isDirty, isValid }
    } = useForm({ mode: 'all' });

    const [error, setError] = useState<string>();

    const email = watch('email');

    const handleForgotPassword = useCallback(
        async (data) => {
            try {
                await Auth.forgotPassword(data.email);
                navigate(`/reset-password?email=${encodeURIComponent(email)}`);

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                setError(error.message);
            }
        },
        [email]
    );

    return (
        <div className='login-box'>
            <FormHeader />
            <div className='login-form'>
                <h1>{t('forgot_password')}</h1>
                <form>
                    <div>
                        <label>{t('email')}</label>
                        <input
                            className={`input-form ${
                                error !== undefined ? 'error-form' : ''
                            }`}
                            type={'email'}
                            id='email'
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: t('invalid_email')
                                }
                            })}
                        />
                    </div>
                    {error !== undefined && (
                        <div className='error-toast'>
                            <img alt='error' src='/images/ico_error.svg' />
                            {error}
                        </div>
                    )}
                    <button
                        className='login-button'
                        onClick={handleSubmit(handleForgotPassword)}
                        disabled={!isValid || !isDirty}
                    >
                        {t('send_code')}
                    </button>
                </form>
            </div>
        </div>
    );
}
