import './index.css';
import { useTranslation } from 'react-i18next';

export function Language(): JSX.Element {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng);
    };

    return (
        <div className='language-options box'>
            <div className='options-title'>{t('language')}</div>
            <div className='buttons'>
                <div
                    className={
                        'language-button ' +
                        (lang === 'sl' && 'selected-language')
                    }
                    onClick={() => changeLanguage('sl')}
                >
                    Slovenščina
                </div>
                <div
                    className={
                        'language-button ' +
                        (lang === 'en' && 'selected-language')
                    }
                    onClick={() => changeLanguage('en')}
                >
                    English
                </div>
            </div>
        </div>
    );
}
