import './index.css';
import { BookingCalendar } from '../bookingCalendar';
import { useEffect, useState } from 'react';
import { getBookingRooms } from '../../api/booking';
import { BookingTab } from '../../api-types/models/Booking';
import { NavigationTabs } from '../navigationTabs';
import { BookingSessions } from '../bookingSessions';
import { CustomEvent } from '@piwikpro/react-piwik-pro';
import { capitalizeFirstLetter } from 'utils/capitalizeCase';

export function BookingAdmin() {
    const [activeTab, setActiveTab] = useState<BookingTab>(BookingTab.CALENDAR);
    const [rooms, setRooms] = useState<string[]>([]);

    useEffect(() => {
        getBookingRooms().then((response) => {
            if (!response.error) {
                setRooms(response);
            }
        });
    }, []);

    useEffect(() => {
        CustomEvent.trackEvent(
            'page_view',
            `Booking (admin) - ${capitalizeFirstLetter(activeTab)}`
        );
    }, [activeTab]);

    return (
        <div className='booking-page'>
            <NavigationTabs
                tabs={Object.values(BookingTab)}
                activeTab={activeTab}
                setActiveTab={setActiveTab as (tab: string) => void}
            />
            <div className='booking-page-content'>
                {activeTab === BookingTab.CALENDAR ? (
                    <BookingCalendar rooms={rooms} />
                ) : (
                    <BookingSessions rooms={rooms} />
                )}
            </div>
        </div>
    );
}
