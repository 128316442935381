import IRoute from '../interfaces/route';
import HomePage from '../pages/home';
import ProfilePage from '../pages/profile';
import LoginPage from '../pages/login';
import { SetPassword } from '../pages/setPassword';
import { ResetPassword } from '../pages/resetPassword';
import ManualLogin from '../pages/manualLogin';
import { ForgotPassword } from '../pages/forgotPassword';
import { UsersPage } from '../pages/users';
import { StatusPage } from '../pages/status';
import { RegistryPage } from '../pages/registry';
import { StatisticsPage } from '../pages/statistics';
import { ObserverPage } from '../pages/observer';
import { BarPage } from '../pages/bar';
import { BookingPage } from '../pages/booking';

export const routes: IRoute[] = [
    {
        path: '/login',
        name: 'Login Page',
        component: LoginPage,
        exact: true
    },
    {
        path: '/logout',
        name: 'LogOut',
        component: LoginPage,
        exact: true
    },
    {
        path: '/reset-password',
        name: 'Reset Pasword',
        component: ResetPassword,
        exact: true
    },
    {
        path: '/set-password',
        name: 'Set Pasword',
        component: SetPassword,
        exact: true
    },
    {
        path: '/forgot-password',
        name: 'Forgot Pasword',
        component: ForgotPassword,
        exact: true
    },
    {
        path: '/external',
        name: 'External Login',
        component: ManualLogin,
        exact: true
    },
    {
        path: '/status',
        name: 'Status Page',
        component: StatusPage,
        exact: true
    },
    {
        path: '/observer',
        name: 'Observer Page',
        component: ObserverPage,
        exact: true
    },
    {
        path: '/bar',
        name: 'Bar page',
        component: BarPage,
        exact: true
    }
];

export const protectedRoutes: IRoute[] = [
    {
        path: '/profile',
        name: 'Profile Page',
        component: ProfilePage,
        exact: true
    },
    {
        path: '/users',
        name: 'Users Page',
        component: UsersPage,
        exact: true,
        admin: true
    },
    {
        path: '/:date',
        name: 'Home Page',
        component: HomePage,
        exact: false
    },
    {
        path: '/',
        name: 'Home Page',
        component: HomePage,
        exact: false
    },
    {
        path: '/registry',
        name: 'Registry page',
        component: RegistryPage,
        exact: true,
        brunchOnly: true
    },
    {
        path: '/statistics',
        name: 'Statistics page',
        component: StatisticsPage,
        exact: true,
        sidebar: true,
        brunchOnly: true
    },
    {
        path: '/booking',
        name: 'Booking page',
        component: BookingPage,
        exact: true
    }
];
