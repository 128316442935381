import { switchProps } from './switch';
import { useTranslation } from 'react-i18next';
import ReactSwitch from 'react-switch';

interface IProps {
    checked: boolean;
    onChange: (value: boolean) => void;
    label?: string;
}

export function Switch({ checked, onChange, label }: Readonly<IProps>) {
    const { t } = useTranslation();

    return (
        <div className='input-group switch-container'>
            <ReactSwitch
                checked={checked}
                onChange={onChange}
                {...switchProps}
            />
            {label && <label>{t(label)}</label>}
        </div>
    );
}
