import './index.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectRegistryItems, setRegistryItems } from '../../app/registrySlice';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { getRegistryItems } from '../../api/registry';
import MenuItem from '../../components/menuItem';
import {
    MenuItemCategory,
    MenuItemGroup,
    MenuItemType
} from '../../api-types/models/MenuItemDto';
import { RegistryFilters } from '../../components/registryFilters';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import { RegistryItemPopup } from '../../components/registryItemPopup';
import Modal from 'react-modal';
import { modalStyles } from '../../config/modal';
import { selectEditItem, setEditItem } from '../../app/menuSlice';
import { getCategories, getGroups, getTaxOptions } from '../../api/menu';
import { Category } from '../../api-types/models/Category';
import { Tax } from '../../api-types/models/Tax';
import { CustomEvent } from '@piwikpro/react-piwik-pro';

const ITEMS_PER_PAGE = 10;

export function RegistryPage() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [activeGroups, setActiveGroups] = useState<string[]>([]);
    const [activeCategories, setActiveCategories] = useState<string[]>([]);
    const [allGroups, setAllGroups] = useState<string[]>([]);
    const [allCategories, setAllCategories] = useState<string[]>([]);
    const [activeTypes, setActiveTypes] = useState<MenuItemType[]>(
        Object.values(MenuItemType)
    );
    const [currentPage, setCurrentPage] = useState(0);
    const [isAddRegistryItemModalOpen, setIsAddRegistryItemModalOpen] =
        useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [taxOptions, setTaxOptions] = useState<Tax>();
    const [showAll, setShowAll] = useState<boolean>(false);

    const registryItems = useAppSelector(selectRegistryItems);
    const editItem = useAppSelector(selectEditItem);

    const handleChangeActiveGroup = useCallback(
        (item: MenuItemGroup | null) => {
            setActiveGroups(item ? [item] : allGroups);
            setCurrentPage(0);
        },
        [allGroups]
    );

    const handleChangeActiveCategory = useCallback(
        (item: MenuItemCategory | null) => {
            setActiveCategories(item ? [item] : allCategories);
            setCurrentPage(0);
        },
        [allCategories]
    );

    const handleAddOrRemoveActiveType = useCallback(
        (type: MenuItemType) => {
            if (activeTypes.includes(type)) {
                setActiveTypes(activeTypes.filter((filter) => filter !== type));
            } else {
                setActiveTypes(activeTypes.concat(type));
            }
            setCurrentPage(0);
        },
        [activeTypes]
    );

    const handleCloseModal = useCallback(() => {
        setIsAddRegistryItemModalOpen(false);
        dispatch(setEditItem(undefined));
    }, []);

    useEffect(() => {
        CustomEvent.trackEvent('page_view', 'Registry');
    }, []);

    useEffect(() => {
        getRegistryItems().then((response) =>
            dispatch(setRegistryItems(response))
        );
        getGroups().then((response) => {
            if (!Object.keys(response).includes('error')) {
                const groups = (response as Category[]).map(
                    (group) => group.name
                );

                setActiveGroups(groups);
                setAllGroups(groups);
            }
        });
        getCategories().then((response) => {
            if (!Object.keys(response).includes('error')) {
                const categories = (response as Category[]).map(
                    (category) => category.name
                );

                setActiveCategories(categories);
                setAllCategories(categories);
            }
        });
        getTaxOptions().then((response) => {
            if (!Object.keys(response).includes('error')) {
                setTaxOptions(response as Tax);
            }
        });
    }, []);

    const registryItemsCopy = registryItems.filter(
        (registryItem) =>
            activeGroups.includes(registryItem.group) &&
            (activeTypes.includes(MenuItemType.KITCHEN) &&
            registryItem.costCenter === MenuItemType.KITCHEN
                ? activeCategories.includes(registryItem.category as string)
                : true) &&
            activeTypes.includes(registryItem.costCenter) &&
            registryItem.title.toLowerCase().includes(searchQuery) &&
            (showAll ? true : registryItem.active)
    );

    return (
        <div className='registry-page'>
            <div className='header'>
                <button
                    type='button'
                    className='btn btn-gray'
                    onClick={() => setIsAddRegistryItemModalOpen(true)}
                >
                    + {t('add_item')}
                </button>
            </div>
            <div className='content'>
                <RegistryFilters
                    setActiveCategory={handleChangeActiveCategory}
                    setActiveGroup={handleChangeActiveGroup}
                    handleAddOrRemoveActiveType={handleAddOrRemoveActiveType}
                    allGroups={allGroups}
                    allCategories={allCategories}
                    searchItems={setSearchQuery}
                    activeTypes={activeTypes}
                    showAll={showAll}
                    setShowAll={setShowAll}
                />
                {registryItemsCopy
                    .slice(
                        currentPage * ITEMS_PER_PAGE,
                        currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE
                    )
                    .map((registryItem) => (
                        <Fragment key={registryItem.uuid}>
                            <MenuItem
                                menuItem={registryItem}
                                registryItem={true}
                            />
                        </Fragment>
                    ))}
                {registryItemsCopy.length > ITEMS_PER_PAGE && (
                    <div className='pagination'>
                        <ReactPaginate
                            breakLabel='...'
                            nextLabel='>'
                            onPageChange={(pageNumber: { selected: number }) =>
                                setCurrentPage(pageNumber.selected)
                            }
                            pageRangeDisplayed={5}
                            pageCount={Math.ceil(
                                registryItemsCopy.length / ITEMS_PER_PAGE
                            )}
                            previousLabel='<'
                            renderOnZeroPageCount={null}
                            activeClassName='active'
                        />
                    </div>
                )}
                <Modal
                    isOpen={
                        isAddRegistryItemModalOpen || editItem !== undefined
                    }
                    style={modalStyles}
                    onRequestClose={handleCloseModal}
                    ariaHideApp={false}
                >
                    {taxOptions && (
                        <RegistryItemPopup
                            closeModal={handleCloseModal}
                            editItem={editItem}
                            taxOptions={taxOptions}
                        />
                    )}
                </Modal>
            </div>
        </div>
    );
}
