import { getDaysArray } from '../../utils/getDaysArray';
import { useEffect } from 'react';
import { addDays, format } from 'date-fns';
import ReactTooltip from 'react-tooltip';
import { BookingSlot } from '../../api-types/models/Booking';
import { BookingTableCell } from '../bookingTableCell';
import { useAppSelector } from '../../app/hooks';
import { selectWeekType } from '../../app/bookingSlice';

import './index.css';

interface IProps {
    startOfWeekDate: Date;
    setSelectedDate: (date: Date) => void;
    slotsPerWeek: BookingSlot[];
    setSelectedSlot: (slot: BookingSlot) => void;
}

export function BookingTable({
    startOfWeekDate,
    setSelectedDate,
    slotsPerWeek,
    setSelectedSlot
}: IProps) {
    const weekType = useAppSelector(selectWeekType);

    useEffect(() => {
        const table = document.getElementById(
            'booking-table'
        ) as HTMLTableElement;

        // Attach a click event listener to the table
        table.addEventListener('click', (event) => {
            const cell = (event.target as HTMLElement)
                .parentElement as HTMLTableCellElement;

            if (
                cell &&
                !cell.className
                    .split(' ')
                    .some((className) =>
                        ['active', 'wrapper'].includes(className)
                    )
            ) {
                const rowIndex = (cell.parentElement as HTMLTableRowElement)
                    .rowIndex;
                const cellIndex = cell.cellIndex;

                if (cellIndex && rowIndex) {
                    setSelectedDate(
                        new Date(
                            addDays(startOfWeekDate, cellIndex - 1).setHours(
                                rowIndex + 5
                            )
                        )
                    );
                }
            }
        });
    }, [startOfWeekDate]);

    return (
        <>
            <table className='booking-table' id='booking-table'>
                <thead>
                    <tr>
                        <th></th>
                        {getDaysArray(weekType).map((day, index) => (
                            <th
                                key={day}
                                data-for='tooltip-date'
                                data-tip={format(
                                    addDays(startOfWeekDate, index),
                                    'PPP'
                                )}
                            >
                                {day}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: 15 }, (_, index) => 6 + index).map(
                        (hour) => (
                            <tr key={hour}>
                                <th>{hour}</th>
                                {getDaysArray(weekType).map((day, index) => (
                                    <BookingTableCell
                                        key={`${day}-${hour}`}
                                        hour={hour}
                                        index={index}
                                        startOfWeekDate={startOfWeekDate}
                                        slotsPerWeek={slotsPerWeek}
                                        setSelectedSlot={setSelectedSlot}
                                    />
                                ))}
                            </tr>
                        )
                    )}
                </tbody>
            </table>
            <ReactTooltip id='tooltip-date' />
        </>
    );
}
