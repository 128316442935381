import './index.css';
import {
    BookingSlot as IBookingSlot,
    UpdateSlotAction
} from '../../api-types/models/Booking';
import { format, isFuture } from 'date-fns';
import { Attendance } from '../attendance';
import { BookSlotButton } from '../bookSlotButton';
import { getActivityDuration } from '../../utils/getActivityDuration';
import { AddGuestToBooking } from 'components/addGuestToBooking';

interface IProps {
    slot: IBookingSlot;
    updateSlot: (slot: IBookingSlot, action: UpdateSlotAction) => void;
    removeSlot: (slot: IBookingSlot) => void;
    booked: boolean;
}

export function BookingSlot({
    slot,
    updateSlot,
    removeSlot,
    booked
}: Readonly<IProps>) {
    return (
        <div className='booking-slot'>
            <div className='main-line'>
                <img
                    src={`/images/booking/${slot.room.toLowerCase()}.svg`}
                    alt={slot.room}
                />
                <h3>{slot.activityName}</h3>
                {slot.lead && <p>({slot.lead})</p>}
            </div>
            <p>
                {`${format(slot.from, 'k:mm')} ${getActivityDuration(
                    slot.duration
                )} - ${slot.room}`}
            </p>
            <Attendance
                attendees={slot.attendees}
                capacity={slot.capacity}
                guests={slot.guests}
            />

            <div className='actions'>
                {isFuture(slot.from) && (
                    <BookSlotButton
                        booked={booked}
                        slot={slot}
                        disabled={
                            !booked && slot.attendees?.length >= slot.capacity
                        }
                        updateSlot={updateSlot}
                        removeSlot={removeSlot}
                    />
                )}
                {isFuture(slot.from) && booked && (
                    <AddGuestToBooking slot={slot} updateSlot={updateSlot} />
                )}
            </div>
        </div>
    );
}
