import { addDays, format, isWeekend } from 'date-fns';

export function getWorkingDaysBetweenTwoDayes(startDate: Date, endDate: Date) {
    const dates: string[] = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
        if (!isWeekend(currentDate)) {
            dates.push(format(currentDate, 'yyyy-MM-dd'));
        }
        currentDate = addDays(currentDate, 1);
    }

    return dates;
}
