import { useCallback, useEffect, useState } from 'react';
import './index.css';
import Select from 'react-select';
import { getRegistryItems } from '../../api/registry';
import { selectRegistryItems, setRegistryItems } from '../../app/registrySlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { MenuItemDto } from '../../api-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import {
    addPreparedConstantMenuItems,
    addPreparedMenuItem
} from '../../app/menuSlice';
import { getWorkingDaysBetweenTwoDayes } from '../../utils/getWorkingDays';
import { getMenu } from '../../api/menu';
import { isWeekday } from '../../utils/isWeekday';
import { MenuItemType } from '../../api-types/models/MenuItemDto';
import { MenuItemOption } from '../menuItemOption';
import { CustomEvent } from '@piwikpro/react-piwik-pro';

interface IProps {
    closeModal: () => void;
}

export function AddMenuItem({ closeModal }: Readonly<IProps>) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [selectedRegistryItems, setSelectedRegistryItems] =
        useState<MenuItemDto[]>();
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [multipleDays, setMultipleDays] = useState(false);
    const [errorArr, setErrorArr] = useState<string[]>([]);
    const [successArr, setSuccessArr] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [filteredRegistryItems, setFilteredRegistryItems] = useState<
        MenuItemDto[]
    >([]);
    const [isConstant, setIsConstant] = useState<boolean>(false);

    const registryItems = useAppSelector(selectRegistryItems);

    const handleAddMenuItem = useCallback(() => {
        setLoading(true);
        if (isConstant) {
            dispatch(
                addPreparedConstantMenuItems({
                    menuItems: selectedRegistryItems
                })
            );
            closeModal();
            setLoading(false);
        } else {
            CustomEvent.trackEvent(
                'add_menu_items',
                (registryItems?.length ?? 0).toString()
            );
            selectedRegistryItems?.map((registryItem) => {
                if (!multipleDays) {
                    if (startDate !== undefined) {
                        getMenu(new Date(startDate))
                            .then((menuItems) => {
                                if (
                                    menuItems.find(
                                        (menuItem) =>
                                            menuItem.uuid === registryItem.uuid
                                    )
                                ) {
                                    setErrorArr([
                                        t('menu_item_exists', {
                                            menuItem: registryItem.title,
                                            date: startDate
                                        })
                                    ]);
                                } else {
                                    dispatch(
                                        addPreparedMenuItem({
                                            menuItem: registryItem,
                                            date: startDate
                                        })
                                    );

                                    closeModal();
                                }
                            })
                            .finally(() => setLoading(false));
                    }
                } else {
                    const dates = getWorkingDaysBetweenTwoDayes(
                        new Date(startDate ?? ''),
                        new Date(endDate ?? '')
                    );

                    let errors = [];
                    let success = [];
                    dates.forEach((date) => {
                        getMenu(new Date(date))
                            .then((menuItems) => {
                                if (
                                    menuItems.find(
                                        (menuItem) =>
                                            menuItem.uuid === registryItem.uuid
                                    )
                                ) {
                                    errors = [
                                        ...errors,
                                        t('menu_item_exists', {
                                            menuItem: registryItem.title,
                                            date
                                        })
                                    ];
                                } else {
                                    dispatch(
                                        addPreparedMenuItem({
                                            menuItem: registryItem,
                                            date
                                        })
                                    );
                                    success = [
                                        ...success,
                                        t('menu_item_success', {
                                            menuItem: registryItem.title,
                                            date
                                        })
                                    ];
                                }
                            })
                            .finally(() => {
                                if (errors.length === 0) {
                                    closeModal();
                                } else {
                                    setErrorArr(errors);
                                    setSuccessArr(success);
                                }
                                setLoading(false);
                            });
                    });
                }
            });
        }
    }, [selectedRegistryItems, multipleDays, startDate, endDate, isConstant]);

    useEffect(() => {
        getRegistryItems().then((response) =>
            dispatch(setRegistryItems(response))
        );
    }, []);

    useEffect(() => {
        setFilteredRegistryItems(registryItems);
    }, [registryItems]);

    return (
        <div className='add-menu-item'>
            <div className='flex'>
                <Select
                    className='select-type'
                    placeholder={t('select_type_placeholder')}
                    options={Object.keys(MenuItemType).map((type) => ({
                        value: type,
                        label: type
                    }))}
                    isMulti={false}
                    onChange={(selectedOption) => {
                        setFilteredRegistryItems(
                            registryItems.filter(
                                (item) =>
                                    item.costCenter === selectedOption?.value
                            )
                        );
                        if (selectedOption?.value === MenuItemType.BAR) {
                            setIsConstant(true);
                        }
                    }}
                />
                <Select
                    className='select-registry'
                    options={
                        filteredRegistryItems.map((item) => ({
                            value: item,
                            label: item.title,
                            menuItem: item
                        })) ?? []
                    }
                    isClearable
                    isSearchable
                    value={selectedRegistryItems?.map((registryItem) => ({
                        value: registryItem,
                        label: registryItem.title,
                        menuItem: registryItem
                    }))}
                    placeholder={t('select-registry-placeholder')}
                    onChange={(selectedOption) =>
                        setSelectedRegistryItems(
                            selectedOption.map((option) => option.value)
                        )
                    }
                    isMulti={true}
                    components={{
                        Option: MenuItemOption
                    }}
                />
                <button
                    onClick={() =>
                        setSelectedRegistryItems(filteredRegistryItems ?? [])
                    }
                    className='btn btn-blue'
                >
                    {t('select_all')}
                </button>
            </div>
            <div className='weekly'>
                <input
                    type='checkbox'
                    checked={isConstant}
                    onChange={(event) => setIsConstant(event.target.checked)}
                />
                <label>{t('is_constant')}</label>
            </div>
            {!isConstant && (
                <>
                    <div className='weekly'>
                        <input
                            type='checkbox'
                            defaultChecked={multipleDays}
                            onChange={(event) =>
                                setMultipleDays(event.target.checked)
                            }
                        />
                        <label>{t('multiple_days')}</label>
                    </div>
                    <div className='date-picker'>
                        <DatePicker
                            selected={
                                startDate !== undefined
                                    ? new Date(startDate)
                                    : null
                            }
                            onChange={(date) =>
                                setStartDate(format(date as Date, 'yyyy-MM-dd'))
                            }
                            selectsStart
                            startDate={
                                startDate !== undefined
                                    ? new Date(startDate)
                                    : null
                            }
                            endDate={
                                endDate !== undefined ? new Date(endDate) : null
                            }
                            minDate={new Date()}
                            placeholderText={t('start_date_placeholder')}
                            calendarStartDay={1}
                            dateFormat='yyyy-MM-dd'
                            filterDate={isWeekday}
                        />
                        {multipleDays && (
                            <DatePicker
                                selected={
                                    endDate !== undefined
                                        ? new Date(endDate)
                                        : null
                                }
                                onChange={(date) =>
                                    setEndDate(
                                        format(date as Date, 'yyyy-MM-dd')
                                    )
                                }
                                selectsEnd
                                startDate={
                                    startDate !== undefined
                                        ? new Date(startDate)
                                        : null
                                }
                                endDate={
                                    endDate !== undefined
                                        ? new Date(endDate)
                                        : null
                                }
                                minDate={
                                    startDate !== undefined
                                        ? new Date(startDate)
                                        : new Date()
                                }
                                placeholderText={t('end_date_placeholder')}
                                calendarStartDay={1}
                                dateFormat='yyyy-MM-dd'
                            />
                        )}
                    </div>
                </>
            )}
            <div className='success-wrapper'>
                {successArr.length > 0 &&
                    successArr.map((success) => (
                        <small key={success} className='success'>
                            {success}
                        </small>
                    ))}
            </div>
            <div className='errors'>
                {errorArr.length > 0 &&
                    errorArr.map((error) => (
                        <small key={error} className='error'>
                            {error}
                        </small>
                    ))}
            </div>
            <div className='actions'>
                <button
                    className='btn btn-gray'
                    onClick={closeModal}
                    disabled={loading}
                >
                    {t('cancel')}
                </button>
                <button
                    className='btn btn-blue'
                    onClick={handleAddMenuItem}
                    disabled={
                        loading ||
                        !selectedRegistryItems?.length ||
                        (!isConstant
                            ? multipleDays
                                ? !startDate || !endDate
                                : !startDate
                            : false)
                    }
                >
                    {loading ? t('processing') : t('add')}
                </button>
            </div>
        </div>
    );
}
