import './index.css';
import { useForm } from 'react-hook-form';
import { EditMenuItem } from '../editMenuItem';
import { MenuItemDto } from '../../api-types';
import { InputField } from '../inputField';
import { useTranslation } from 'react-i18next';
import {
    MenuItemGroup,
    MenuItemType,
    TaxType
} from '../../api-types/models/MenuItemDto';
import { useCallback, useEffect, useState } from 'react';
import {
    createRegistryItem,
    updateRegistryItemRequest
} from '../../api/registry';
import {
    addRegistryItem,
    selectRegistryItems,
    updateRegistryItem
} from '../../app/registrySlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setEditItem } from '../../app/menuSlice';
import { Tax } from '../../api-types/models/Tax';
import debounce from 'lodash.debounce';
import { MenuItemOption } from '../menuItemOption';
import { CustomEvent } from '@piwikpro/react-piwik-pro';

interface IProps {
    closeModal: () => void;
    editItem?: MenuItemDto;
    taxOptions: Tax;
}

export function RegistryItemPopup({
    closeModal,
    editItem,
    taxOptions
}: Readonly<IProps>) {
    const dispatch = useAppDispatch();
    const form = useForm<MenuItemDto>({
        mode: 'onChange',
        defaultValues: {
            ...editItem,
            group: editItem?.group ?? MenuItemGroup.DAILY,
            taxValue: editItem?.taxValue ?? TaxType.LOW,
            takeout: editItem?.takeout ?? true
        }
    });
    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [filteredRegistryItems, setFilteredRegistryItems] = useState<
        MenuItemDto[]
    >([]);

    const registryItems = useAppSelector(selectRegistryItems);

    const title = form.watch('title');

    const handleSubmitRegistryItem = useCallback(
        (registryItem: MenuItemDto) => {
            if (editItem) {
                updateRegistryItemRequest({
                    ...registryItem,
                    category:
                        registryItem.costCenter === MenuItemType.KITCHEN
                            ? registryItem.category
                            : null
                })
                    .then((response) => {
                        dispatch(updateRegistryItem(response));
                        CustomEvent.trackEvent(
                            'update_registry_item',
                            response.title
                        );
                        dispatch(setEditItem(undefined));
                    })
                    .catch((error) => console.log(error));
            } else {
                createRegistryItem({
                    ...registryItem,
                    fullPrice: +registryItem.fullPrice,
                    subsidizedPrice: +registryItem.subsidizedPrice,
                    nutritionalValues: {},
                    category:
                        registryItem.costCenter === MenuItemType.KITCHEN
                            ? registryItem.category
                            : null
                }).then((response) => {
                    if (Object.keys(response).includes('error')) {
                        setError(response.error);
                    } else {
                        dispatch(addRegistryItem(response));
                        CustomEvent.trackEvent(
                            'add_registry_item',
                            response.title
                        );
                        closeModal();
                    }
                });
            }
        },
        []
    );

    function filterRegistryItems() {
        setFilteredRegistryItems(
            registryItems.filter((registryItem) =>
                registryItem.title.toLowerCase().includes(title.toLowerCase())
            )
        );
    }

    const delayedFilter = debounce(filterRegistryItems, 500);

    useEffect(() => {
        if (title?.length > 2) {
            delayedFilter();
        } else {
            setFilteredRegistryItems([]);
        }
    }, [title]);

    return (
        <div className='registry-item-modal'>
            <div className='input-row'>
                <div className='input-group title'>
                    <InputField
                        label={t('title')}
                        value={form.getValues('title')}
                        form={form}
                        name='title'
                        required={true}
                    />
                </div>
            </div>
            <div className='existing-items'>
                {filteredRegistryItems.length > 0 &&
                    filteredRegistryItems.map((registryItem) => (
                        <MenuItemOption
                            key={registryItem.uuid}
                            data={{ menuItem: registryItem }}
                            label={registryItem.title}
                        />
                    ))}
            </div>
            <EditMenuItem
                form={form}
                onFormSubmit={handleSubmitRegistryItem}
                onCancel={closeModal}
                menuItem={editItem}
                taxOptions={taxOptions}
            />
            {error && <div className='error'>{error}</div>}
        </div>
    );
}
