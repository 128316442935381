import { useTranslation } from 'react-i18next';
import { switchProps } from '../../config/config';
import Switch from 'react-switch';
import { NotificationSettings } from '../../../../api-types/models/UserDto';
import { useCallback, useEffect, useState } from 'react';
import { updateNotificationSettings } from '../../../../api/userSettings';

interface IProps {
    notifications: NotificationSettings;
}

export function Notifications({ notifications }: IProps): JSX.Element {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [notificationsCopy, setNotificationCopy] =
        useState<NotificationSettings>(notifications);
    const { t } = useTranslation();

    const handleSavingNotificationsSettings = useCallback(() => {
        updateNotificationSettings(notificationsCopy).then(() =>
            setDisabled(true)
        );
    }, [notificationsCopy]);

    useEffect(() => {
        setDisabled(notificationsCopy === notifications);
    }, [notificationsCopy, notifications]);

    return (
        <div className='notifications-options box'>
            <div className='options-title'>{t('notifications')}</div>
            {Object.keys(notificationsCopy).map((notificationKey) => (
                <div className='options-text-switch' key={notificationKey}>
                    {t(notificationKey.toLowerCase())}
                    <Switch
                        checked={notificationsCopy[notificationKey] ?? false}
                        onChange={(value: boolean) =>
                            setNotificationCopy({
                                ...notificationsCopy,
                                [notificationKey]: value
                            })
                        }
                        {...switchProps}
                    />
                </div>
            ))}
            <button
                className='btn btn-blue'
                disabled={notifications === notificationsCopy || disabled}
                onClick={() => handleSavingNotificationsSettings()}
            >
                {t('save')}
            </button>
        </div>
    );
}
