import { UserDto } from 'api-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAppDispatch } from 'app/hooks';
import { addGuestThunk, removeGuestThunk } from 'app/userSlice';
import { Chip } from 'components/chip';

import './index.css';

interface IProps {
    userSettings: UserDto | undefined;
}

export function Guests({ userSettings }: IProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [newGuest, setNewGuest] = useState<string>('');

    const handleAddGuest = () => {
        dispatch(addGuestThunk(newGuest));
        setNewGuest('');
    };

    const handleRemoveGuest = (guest: string) => {
        dispatch(removeGuestThunk(guest));
    };

    return (
        <div className='box'>
            <div className='options-title'>{t('guests')}</div>
            <div className='guests-input'>
                <div className='input-wrapper'>
                    <label htmlFor='name'>{t('name')}</label>
                    <input
                        type='text'
                        id='name'
                        className='input-form'
                        value={newGuest}
                        onChange={(event) => setNewGuest(event.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                handleAddGuest();
                            }
                        }}
                    />
                </div>
                <button className='btn btn-blue' onClick={handleAddGuest}>
                    {t('add')}
                </button>
            </div>
            <div className='guests-list'>
                {userSettings?.guests?.map((guest) => (
                    <Chip
                        key={guest}
                        text={guest}
                        onClose={() => handleRemoveGuest(guest)}
                    />
                ))}
            </div>
        </div>
    );
}
