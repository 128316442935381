import './index.css';

interface ChipProps {
    text: string;
    onClose: () => void;
}

export function Chip({ text, onClose }: ChipProps) {
    return (
        <div className='chip'>
            <span>{text}</span>
            <button onClick={onClose}>✕</button>
        </div>
    );
}

export default Chip;
