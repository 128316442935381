import { MouseEvent, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { MenuItemDto } from '../../api-types';
import { useTranslation } from 'react-i18next';
import { modalStyles } from '../../config/modal';
import Modal from 'react-modal';
import { MenuItemPopup } from '../menuItemPopup';
import {
    removeMenuItem,
    selectEditItem,
    setEditItem
} from '../../app/menuSlice';
import { deleteMenuItem } from '../../api/menu';
import { DishItem } from '../dishItem';
import { isPast, isToday } from 'date-fns';
import { DeleteModal } from '../deleteModal';
import { MenuItemPrice } from '../menuItemPrice';
import { selectAuthUser } from '../../app/authSlice';
import { getUserGroups } from '../../utils/getUserGroups';
import { LoginType } from '../../api-types/models/LoginDto';
import { deleteRegistryItem } from 'api/registry';
import { updateRegistryItem } from 'app/registrySlice';
import { selectDate } from 'components/calendar/calendarSlice';
import { CustomEvent } from '@piwikpro/react-piwik-pro';

import './index.css';

interface IProps {
    menuItem: MenuItemDto;
    registryItem?: boolean;
}

export function MenuItem({ menuItem, registryItem = false }: Readonly<IProps>) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const editItem = useAppSelector(selectEditItem);
    const user = useAppSelector(selectAuthUser);
    const date =
        menuItem.id !== 'PERMANENT'
            ? new Date(menuItem.id)
            : useAppSelector(selectDate);

    const loginType = localStorage.getItem('login');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isDeleteRegistryItemModalOpen, setIsDeleteRegistryItemModalOpen] =
        useState<boolean>(false);
    const [error, setError] = useState<string>();

    const handleOpenModal = useCallback(
        (event: MouseEvent) => {
            editItem !== menuItem &&
                !['btn btn-gray', 'action-item'].includes(
                    event.target['className']
                ) &&
                setIsModalOpen(true);
        },
        [editItem, menuItem]
    );

    const handleDeleteMenuItem = useCallback((menuItem: MenuItemDto) => {
        deleteMenuItem(menuItem)
            .then(() => {
                dispatch(removeMenuItem(menuItem));
                setError(undefined);
                CustomEvent.trackEvent('delete_menu_item', menuItem.title);
            })
            .catch((error) => setError(error.response.data.message))
            .finally(() => setIsDeleteModalOpen(false));
    }, []);

    const handleDeleteRegistryItem = useCallback((menuItem: MenuItemDto) => {
        deleteRegistryItem(menuItem, !menuItem.active)
            .then((response) => {
                if (!response.error) {
                    dispatch(
                        updateRegistryItem({
                            ...menuItem,
                            active: !menuItem.active
                        })
                    );
                    CustomEvent.trackEvent(
                        menuItem.active
                            ? 'deactivate_menu_item'
                            : 'reactivate_menu_item',
                        menuItem.title
                    );
                } else {
                    setError(response.error);
                }
            })
            .finally(() => setIsDeleteRegistryItemModalOpen(false));
    }, []);

    const isTodayOrOlder = isPast(date) && !isToday(date);

    return (
        <>
            <div
                className={`menu-item  ${
                    menuItem.id === 'REGISTRY' && !registryItem
                        ? 'unpublished'
                        : ''
                } ${!menuItem.active ? 'unpublished' : ''}`}
                onClick={handleOpenModal}
            >
                <div className='menu-item-main-row'>
                    <DishItem menuItem={menuItem} />
                    {!isTodayOrOlder && loginType !== null && (
                        <div>
                            {user &&
                                getUserGroups(user).includes(
                                    LoginType.BRUNCH
                                ) && (
                                    <div className='actions'>
                                        {registryItem ? (
                                            <>
                                                <div
                                                    className='btn btn-gray'
                                                    role='edit-menu-btn'
                                                    onClick={() =>
                                                        dispatch(
                                                            setEditItem(
                                                                menuItem
                                                            )
                                                        )
                                                    }
                                                >
                                                    <img
                                                        className='action-item'
                                                        src={
                                                            '/images/ico_edit_blue.svg'
                                                        }
                                                        alt='edit'
                                                    />
                                                </div>
                                                <div
                                                    className='btn btn-gray'
                                                    onClick={() =>
                                                        menuItem.active
                                                            ? setIsDeleteRegistryItemModalOpen(
                                                                  true
                                                              )
                                                            : handleDeleteRegistryItem(
                                                                  menuItem
                                                              )
                                                    }
                                                >
                                                    <img
                                                        className='action-item'
                                                        src={
                                                            menuItem.active
                                                                ? '/images/ico_remove_blue.svg'
                                                                : '/images/ico_revert_blue.svg'
                                                        }
                                                        alt='delete'
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            menuItem.id !== 'REGISTRY' && (
                                                <div
                                                    className='btn btn-gray'
                                                    role='remove-menu-btn'
                                                    onClick={() =>
                                                        setIsDeleteModalOpen(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <img
                                                        className='action-item'
                                                        src='/images/ico_remove_blue.svg'
                                                        alt='remove'
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                        </div>
                    )}
                </div>

                <div className='menu-item-desc-row'>
                    <div className='menu-item-desc'>{menuItem.description}</div>
                    <MenuItemPrice menuItem={menuItem} />
                </div>

                {error !== undefined && (
                    <div className='error-toast'>
                        <img alt='error' src='/images/ico_error.svg' />
                        {t('error_delete_menu_item')}
                        <div
                            className='close-toast'
                            onClick={() => setError(undefined)}
                        >
                            <img
                                className='close-toast'
                                alt='close'
                                src='/images/ico_close.svg'
                            />
                        </div>
                    </div>
                )}

                {menuItem.id === 'REGISTRY' && !registryItem && (
                    <small>Not published</small>
                )}
            </div>

            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    style={modalStyles}
                    onRequestClose={() => setIsModalOpen(false)}
                    ariaHideApp={false}
                >
                    <MenuItemPopup
                        menuItem={menuItem}
                        setIsModalOpen={setIsModalOpen}
                    ></MenuItemPopup>
                </Modal>
            )}
            {isDeleteModalOpen && (
                <Modal
                    isOpen={isDeleteModalOpen}
                    style={modalStyles}
                    onRequestClose={() => setIsDeleteModalOpen(false)}
                    ariaHideApp={false}
                >
                    <DeleteModal
                        label='delete_menu_item'
                        variable={menuItem.title}
                        closeModal={() => setIsDeleteModalOpen(false)}
                        handleDelete={() => handleDeleteMenuItem(menuItem)}
                    />
                </Modal>
            )}
            {isDeleteRegistryItemModalOpen && (
                <Modal
                    isOpen={isDeleteRegistryItemModalOpen}
                    style={modalStyles}
                    onRequestClose={() =>
                        setIsDeleteRegistryItemModalOpen(false)
                    }
                    ariaHideApp={false}
                >
                    <DeleteModal
                        label='delete_registry_item'
                        variable={menuItem.title}
                        closeModal={() =>
                            setIsDeleteRegistryItemModalOpen(false)
                        }
                        handleDelete={() => handleDeleteRegistryItem(menuItem)}
                    />
                </Modal>
            )}
        </>
    );
}

export default MenuItem;
