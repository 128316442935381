import { API } from 'aws-amplify';
import { UserDto } from '../../api-types';
import { SportradarUser } from '../../interfaces/sportradarUser';
import { NotificationSettings } from '../../api-types/models/UserDto';
import { handleError } from '../../utils/handleError';

export function getUserSettings(): Promise<UserDto> {
    return API.get('brunch-v2', '/user', {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function updateUserSettings(
    userSettings: UserDto
): Promise<UserDto> {
    return API.put('brunch-v2', '/user', {
        body: userSettings
    }).catch((error) =>
        handleError(
            error?.response?.data !== ''
                ? error?.response?.data
                : error.response ?? error
        )
    );
}

export async function getUsers(): Promise<SportradarUser[]> {
    return API.get('brunch-v2', '/user/all', {}).catch((error) =>
        handleError(error?.response?.data ?? error)
    );
}

export async function updateUser(
    user: SportradarUser
): Promise<SportradarUser> {
    return API.put('brunch-v2', '/user/payment', {
        body: user
    }).catch((error) =>
        handleError(
            error?.response?.data !== ''
                ? error?.response?.data
                : error.response ?? error
        )
    );
}

export async function updateNotificationSettings(
    notificationSettings: NotificationSettings
) {
    const body = Object.keys(notificationSettings).map((key) => ({
        notificationType: key,
        value: notificationSettings[key]
    }));

    return API.put('brunch-v2', '/user/notifications', {
        body
    }).catch((error) =>
        handleError(
            error?.response?.data !== ''
                ? error?.response?.data
                : error.response ?? error
        )
    );
}

export async function updateFoodFilter(filter: string, value: boolean) {
    const body = { filter, value };
    return API.put('brunch-v2', '/user/filter', {
        body
    }).catch((error) =>
        handleError(
            error?.response?.data !== ''
                ? error?.response?.data
                : error.response ?? error
        )
    );
}

export async function updateAllergies(allergen: string, value: boolean) {
    const body = { allergen, value };
    return API.put('brunch-v2', '/user/allergies', {
        body
    }).catch((error) =>
        handleError(
            error?.response?.data !== ''
                ? error?.response?.data
                : error.response ?? error
        )
    );
}

export async function addGuest(guest: string) {
    return API.put('brunch-v2', `/user/guest/add/${guest}`, {}).catch((error) =>
        handleError(
            error?.response?.data !== ''
                ? error?.response?.data
                : error.response ?? error
        )
    );
}

export async function removeGuest(guest: string) {
    return API.put('brunch-v2', `/user/guest/remove/${guest}`, {}).catch(
        (error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
    );
}
