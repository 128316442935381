import './index.css';
import { useTranslation } from 'react-i18next';

export function Payment(): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className='payment-options box'>
            <div className='options-title'>{t('payment')}</div>
            <div className='options-content'>
                <div className='options-text'>{t('payment_notice')}</div>
                <div
                    className='btn btn-gray'
                    onClick={() => {
                        location.href =
                            'mailto:ljubljana.office@sportradar.com';
                    }}
                >
                    {t('send')}
                </div>
            </div>
        </div>
    );
}
