interface ResponseError {
    status: number;
    message: string;
}

export function handleError(error: ResponseError) {
    if (
        error &&
        Object.keys(error).includes('status') &&
        error.status === 401
    ) {
        localStorage.clear();
        location.replace('/login');
        return { error: { status: 401, message: 'Unauthorized' } };
    } else return { error };
}
