import './index.css';
import { useTranslation } from 'react-i18next';

export function Preferences(): JSX.Element {
    const { t } = useTranslation();
    return (
        <div className='user-preferences box'>
            <div className='options-title'>{t('user_preferences')}</div>
        </div>
    );
}
