import './index.css';
import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormHeader } from '../../components/formHeader';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

export function ResetPassword(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { isDirty, isValid }
    } = useForm({ mode: 'all' });
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    const [error, setError] = useState<string>();
    const [passwordShown, setPasswordShown] = useState<boolean>(false);
    const [repeatPasswordShown, setRepeatPasswordShown] =
        useState<boolean>(false);
    const [codeSent, setCodeSent] = useState<boolean>(false);

    const handleResetPassword = useCallback(async (data) => {
        try {
            if (data.password === data.repeatPassword) {
                await Auth.forgotPasswordSubmit(
                    data.email,
                    data.code,
                    data.password
                );
                navigate('/brunch');
            } else {
                setError('Passwords must match!');
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            setError(e.message);
        }
    }, []);

    const handleSendCode = useCallback(async () => {
        if (email) {
            Auth.forgotPassword(email).then(() => setCodeSent(true));
        }
    }, [email]);

    useEffect(() => {
        setValue('email', email);
    }, [email]);

    return (
        <div className='login-box reset-password'>
            <FormHeader />
            <div className='login-form'>
                <h1>{t('reset_password')}</h1>
                <form>
                    <div>
                        <label>{t('email')}</label>
                        <input
                            className={`input-form ${
                                error !== undefined ? 'error-form' : ''
                            }`}
                            type='email'
                            id='email'
                            disabled={email !== null}
                            {...register('email', { required: true })}
                        />
                    </div>
                    <div>
                        <label>{t('code')}</label>
                        <input
                            className={`input-form ${
                                error !== undefined ? 'error-form' : ''
                            }`}
                            type='text'
                            id='code'
                            {...register('code', { required: true })}
                        />
                    </div>
                    <div className='password-wrapper'>
                        <label>{t('password')}</label>
                        <input
                            className={`input-form ${
                                error !== undefined ? 'error-form' : ''
                            }`}
                            type={passwordShown ? 'text' : 'password'}
                            id='password'
                            {...register('password', { required: true })}
                        />
                        <div
                            className='toggle-password'
                            onClick={() => setPasswordShown(!passwordShown)}
                        >
                            <img
                                alt='hide-password'
                                src='/images/ico_hide_password.svg'
                            />
                        </div>
                    </div>
                    <div className='password-wrapper'>
                        <label>{t('repeat_password')}</label>
                        <input
                            className={`input-form ${
                                error !== undefined ? 'error-form' : ''
                            }`}
                            type={repeatPasswordShown ? 'text' : 'password'}
                            id='repeat-password'
                            {...register('repeatPassword', { required: true })}
                        />
                        <div
                            className='toggle-password'
                            onClick={() =>
                                setRepeatPasswordShown(!repeatPasswordShown)
                            }
                        >
                            <img
                                alt='hide-password'
                                src='/images/ico_hide_password.svg'
                            />
                        </div>
                    </div>
                    <div className='login-options'>
                        {codeSent ? (
                            <div className='forgot-password text-green'>
                                {t('code_sent')}
                            </div>
                        ) : (
                            <div
                                className='forgot-password'
                                onClick={handleSendCode}
                            >
                                {t('did_not_get_code')}
                            </div>
                        )}
                    </div>
                    {error !== undefined && (
                        <div className='error-toast'>
                            <img alt='error' src='/images/ico_error.svg' />
                            {error}
                        </div>
                    )}
                    <button
                        className='login-button'
                        onClick={handleSubmit(handleResetPassword)}
                        disabled={!isValid || !isDirty}
                    >
                        {t('set_new_password')}
                    </button>
                </form>
            </div>
        </div>
    );
}
