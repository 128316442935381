import './index.css';
import { Room } from '../room';

interface IProps {
    rooms: string[];
    setValue: (room: string) => void;
    selectedRoom?: string;
}

export function RoomSelector({ rooms, setValue, selectedRoom }: IProps) {
    return (
        <div className='room-selector'>
            {rooms.map((room) => (
                <Room
                    room={room}
                    key={room}
                    setValue={setValue}
                    active={selectedRoom === room}
                />
            ))}
        </div>
    );
}
