import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface CommonState {
    error?: Error;
}

const initialState: CommonState = {
    error: undefined
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setError(state, action) {
            return {
                ...state,
                error: action.payload
            };
        },
        clearError(state) {
            return {
                ...state,
                error: undefined
            };
        }
    }
});

export const selectError = (state: RootState): Error | undefined => {
    return state.common.error;
};

export const { setError, clearError } = commonSlice.actions;

export default commonSlice.reducer;
