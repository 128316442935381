import './index.css';
import { useCallback, useEffect, useState } from 'react';
import { RoomSelector } from '../roomSelector';
import { BookingSessionTable } from '../bookingSessionTable';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectRoom, setRoom } from '../../app/bookingSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BookingActivity } from 'api-types/models/Booking';
import { getBookingActivities } from 'api/booking';
import { AddBookingActivityModal } from 'components/addBookingSessionModal';

interface IProps {
    rooms: string[];
}

export function BookingSessions({ rooms }: Readonly<IProps>) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const selectedRoom = useAppSelector(selectRoom);

    const [activities, setActivities] = useState<BookingActivity[]>([]);
    const [isAddBookingSessionModalOpen, setIsAddBookingSessionModalOpen] =
        useState(false);
    const [selectedSession, setSelectedSession] = useState<BookingActivity>();

    const addSession = useCallback(
        (session: BookingActivity) => {
            setActivities([session, ...activities]);
            setIsAddBookingSessionModalOpen(false);
        },
        [activities]
    );

    const updateSession = useCallback(
        (session: BookingActivity) => {
            setActivities(
                activities.map((existedService) =>
                    existedService.id === session.id ? session : existedService
                )
            );
            setIsAddBookingSessionModalOpen(false);
        },
        [activities]
    );

    const deleteSession = useCallback(
        (session: BookingActivity) => {
            setActivities(
                activities.filter(
                    (existedService) => existedService.id !== session.id
                )
            );
        },
        [activities]
    );

    const openEditSessionModal = useCallback((session: BookingActivity) => {
        setSelectedSession(session);
        setIsAddBookingSessionModalOpen(true);
    }, []);

    useEffect(() => {
        if (selectedRoom) {
            getBookingActivities(selectedRoom).then((response) => {
                if (!response.error) {
                    setActivities(response);
                } else if (response.error.status === 401) {
                    navigate('/login');
                }
            });
        }
    }, [selectedRoom]);

    return (
        <div className='booking-service'>
            <div className='service-header'>
                <div className='title'>
                    <h2>{t('sessions_added')}</h2>
                    <button
                        className='btn btn-gray'
                        onClick={() => setIsAddBookingSessionModalOpen(true)}
                    >
                        + {t('add')}
                    </button>
                </div>
                <div className='actions'>
                    <RoomSelector
                        rooms={rooms}
                        selectedRoom={selectedRoom}
                        setValue={(room) => dispatch(setRoom(room))}
                    />
                </div>
            </div>

            <BookingSessionTable
                sessions={activities}
                openEditModal={openEditSessionModal}
                deleteSession={deleteSession}
            />
            {isAddBookingSessionModalOpen && (
                <AddBookingActivityModal
                    closeModal={() => {
                        setIsAddBookingSessionModalOpen(false);
                        setSelectedSession(undefined);
                    }}
                    addActivity={addSession}
                    activity={selectedSession}
                    rooms={rooms}
                    updateActivity={updateSession}
                />
            )}
        </div>
    );
}
