import './index.css';
import { useTranslation } from 'react-i18next';
import FoodFilterElement from '../foodFilterElement';
import { FoodFilter as FoodFilterI } from '../../../../api-types/models/UserDto';
import { useCallback } from 'react';
import { updateFoodFilter } from '../../../../api/userSettings';
import { useAppDispatch } from '../../../../app/hooks';
import { saveFoodFilter } from '../../../../app/userSlice';

interface IProps {
    foodFilter: FoodFilterI;
}

export function FoodFilter({ foodFilter }: IProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleUpdateFoodFilter = useCallback(
        (food: string) => {
            updateFoodFilter(food, !foodFilter[food]).then(() =>
                dispatch(
                    saveFoodFilter({
                        ...foodFilter,
                        [food]: !foodFilter[food]
                    })
                )
            );
        },
        [foodFilter]
    );

    return (
        <div className='food-filter-options box'>
            <div className='options-title'>{t('food_filter')}</div>
            <div className='options-text'>{t('food_filter_helper')}</div>
            <div className='food-filter'>
                {Object.keys(foodFilter).map((food) => {
                    return (
                        <div
                            key={food}
                            className='food-filter-element'
                            onClick={() => handleUpdateFoodFilter(food)}
                        >
                            <FoodFilterElement
                                foodName={food}
                                enabled={foodFilter[food]}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
