import { addDays, endOfWeek, format } from 'date-fns';

import './index.css';
import { WeekTypeSelector } from '../weekTypeSelector';

interface IProps {
    startOfWeekDate: Date;
    setStartOfWeekDate: (date: Date) => void;
}

export function WeekSelector({ startOfWeekDate, setStartOfWeekDate }: IProps) {
    return (
        <div className='week-selector-wrapper'>
            <div className='week-selector'>
                <img
                    src='/images/ico_previous.svg'
                    className='change-week'
                    onClick={() =>
                        setStartOfWeekDate(addDays(startOfWeekDate, -7))
                    }
                    alt='previous'
                />
                <h2>
                    {`${format(startOfWeekDate, 'MMMM do')} - ${
                        endOfWeek(startOfWeekDate, {
                            weekStartsOn: 1
                        }).getMonth() !== startOfWeekDate.getMonth()
                            ? format(
                                  endOfWeek(startOfWeekDate, {
                                      weekStartsOn: 1
                                  }),
                                  'MMMM do'
                              )
                            : format(
                                  endOfWeek(startOfWeekDate, {
                                      weekStartsOn: 1
                                  }),
                                  'do'
                              )
                    }`}
                </h2>
                <img
                    src='/images/ico_next.svg'
                    className='change-week'
                    onClick={() =>
                        setStartOfWeekDate(addDays(startOfWeekDate, 7))
                    }
                    alt='next'
                />
            </div>
            <WeekTypeSelector />
        </div>
    );
}
