import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getUsersThunk, selectUsers } from '../../app/userSlice';
import MenuItemsLoader from '../../components/loading/menuLoader';
import { SportradarUser } from '../../interfaces/sportradarUser';
import { UserItem } from './components/userItem';
import { CustomEvent } from '@piwikpro/react-piwik-pro';

import './index.css';

export function UsersPage(): JSX.Element {
    const dispatch = useAppDispatch();
    const users = useAppSelector(selectUsers);
    const { t } = useTranslation();

    const [usersCopy, setUsersCopy] = useState<SportradarUser[]>();
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        dispatch(getUsersThunk());

        CustomEvent.trackEvent('page_view', 'Users');
    }, []);

    useEffect(() => {
        setUsersCopy(users?.filter((user) => user.email.includes(searchTerm)));
    }, [users]);

    if (!users) {
        return <MenuItemsLoader />;
    }

    const handleSearchUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setUsersCopy(
            users.filter((user) =>
                user.email.includes(event.target.value.toLowerCase())
            )
        );
    };

    return (
        <div className='users'>
            <div className='users-title'>{t('users')}</div>
            <div className='search-users'>
                <input
                    className='input-form'
                    type='text'
                    value={searchTerm}
                    onChange={handleSearchUsers}
                />
            </div>
            <div className='users-header'>
                <div className='email'>{t('email')}</div>
                <div className='employee-code'>{t('employee_code')}</div>
                <div className='direct-payment'>{t('direct_payment')}</div>
                <div className='action'>{t('actions')}</div>
            </div>
            {usersCopy?.map((user) => (
                <UserItem user={user} key={user.email} />
            ))}
        </div>
    );
}
