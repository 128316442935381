import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AWSConfig, setAmplifyConfig } from '../config/aws';
import { RootState } from './store';

export interface ConfigState {
    config?: AWSConfig;
}

const initialState: ConfigState = {
    config: undefined
};

export const setConfigThunk = createAsyncThunk<
    AWSConfig,
    { idToken?: string; accessToken?: string } | undefined
>('config/setConfig', async (token) => setAmplifyConfig(token));

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setConfigThunk.fulfilled, (state, action) => {
            state.config = action.payload;
        });
    }
});

export const selectConfig = (state: RootState): AWSConfig | undefined => {
    return state.config.config;
};

export default configSlice.reducer;
