import { Dictionary, groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { OrderDto } from '../../api-types';
import { OrderStatus } from '../../api-types/models/OrderDto';
import { getWsToken } from '../../api/auth';
import { format } from 'date-fns';

import './index.css';

const getNewToken = async () => {
    return getWsToken().then((res) => {
        return res.data.token;
    });
};
export function StatusPage(): JSX.Element {
    const [token, setToken] = useState<string>();
    const [orders, setOrders] = useState<Dictionary<OrderDto[]>>({});
    const [status, setStatus] = useState<string>();
    const [time, setTime] = useState<Date>(new Date());

    const snd = new Audio('/sounds/bell.mp3');

    const connectToWebSocket = (token: string) => {
        setStatus('Reconnecting');

        const webSocket = new WebSocket(
            `wss://${
                process.env.REACT_APP_WEBSOCKET_DOMAIN ?? window.location.host
            }/api/ws/v1/kitchen?authToken=${token}`
        );

        // Get list of all orders for today when connection is created
        webSocket.onopen = () =>
            webSocket.send(JSON.stringify({ event: 'list_orders_request' }));

        // Reconnect if connection falls
        webSocket.onclose = () =>
            getNewToken().then((token) => connectToWebSocket(token));

        // Keep connection alive by pinging server with ping requests
        setInterval(() => {
            try {
                const state = webSocket.readyState;
                switch (state) {
                    case 0:
                        setStatus('Connecting');
                        break;
                    case 1:
                        setStatus('Open');
                        break;
                    case 2:
                        setStatus('Closing');
                        break;
                    default:
                        setStatus('Closed');
                        break;
                }

                if (state == 1) {
                    webSocket.send(JSON.stringify({ event: 'ping' }));
                } else {
                    webSocket.close();
                }
            } catch (e) {
                webSocket.close();
            }
        }, 10000);

        webSocket.onmessage = (e) => {
            const message = JSON.parse(e.data);
            // handle only messages for orders
            if (message.type === 'ORDERS') {
                const filteredOrders = groupBy(
                    message.data
                        .filter((order: OrderDto) => {
                            return [
                                OrderStatus.SERVED,
                                OrderStatus.IN_PROGRESS,
                                OrderStatus.SUBMITTED
                            ].includes(order.status);
                        })
                        .reverse(),
                    (order: OrderDto) => order.status
                );

                // play sound if order was closed
                if (message.orderClosed == true) {
                    snd.play().catch((error) => alert(error));
                }

                // set new orders
                setOrders(filteredOrders);
            }
        };

        // Try reconnecting if error
        webSocket.onerror = () => {
            console.log('Error');
            webSocket.close();
        };

        // Close connection on unmount
        return () => {
            webSocket.close();
        };
    };

    useEffect(() => {
        getNewToken().then((token: string) => {
            setToken(token);
        });
    }, []);

    useEffect(() => {
        if (token !== undefined) {
            connectToWebSocket(token);
        }
    }, [token]);

    // Live countdown
    useEffect(() => {
        const interval = setInterval(() => setTime(new Date()), 1000);

        return () => clearInterval(interval);
    }, [time]);

    const ordersInProgress = orders[OrderStatus.IN_PROGRESS] ?? [];
    const orderedOrders = orders[OrderStatus.SUBMITTED] ?? [];
    const completedOrders = orders[OrderStatus.SERVED] ?? [];

    return (
        <div className='status-page' id='status'>
            <div className='progress'>
                <h3 className='title'>Cooking ({ordersInProgress.length})</h3>
                <hr />
                {ordersInProgress.length === 0 && (
                    <strong className='no-orders'>There are no orders.</strong>
                )}
                <div className='items ordered'>
                    {ordersInProgress.map((order: OrderDto) => (
                        <div className='order-item ordered' key={order.id}>
                            <img
                                className='food-icon'
                                src={`/images/ico_${order.menuItem.icon.toLowerCase()}_color.svg`}
                                alt='tag'
                            />
                            <strong className='order-title'>
                                {order.displayName
                                    ?.split(' ')
                                    .map((word) => `${word.charAt(0)}. `)}
                            </strong>
                        </div>
                    ))}
                </div>
                <h3 className='title ordered'>
                    Ordered ({orderedOrders.length})
                </h3>
                <hr />
                {orderedOrders.length === 0 && (
                    <strong className='no-orders'>There are no orders.</strong>
                )}
                <div className='items ordered'>
                    {orderedOrders.map((order: OrderDto) => (
                        <div className='order-item' key={order.id}>
                            <img
                                className='food-icon'
                                src={`/images/ico_${order.menuItem.icon.toLowerCase()}_color.svg`}
                                alt='tag'
                            />
                            <strong className='order-title'>
                                {order.displayName
                                    ?.split(' ')
                                    .map((word) => `${word.charAt(0)}. `)}
                            </strong>
                        </div>
                    ))}
                </div>
            </div>
            <div className='completed'>
                <h3 className='title'>Served ({completedOrders.length})</h3>
                <hr />
                {completedOrders.length === 0 && (
                    <strong className='no-orders'>There are no orders.</strong>
                )}
                {completedOrders.map((order: OrderDto) => (
                    <div className='order-item' key={order.id}>
                        <img
                            className='food-icon'
                            src={`/images/ico_${order.menuItem.icon.toLowerCase()}_color.svg`}
                            alt='tag'
                        />
                        <strong className='order-title'>
                            {order.displayName}
                        </strong>
                    </div>
                ))}
            </div>
            <div className='status'>{status}</div>
            <div className='time'>{format(time, 'd. M. yyyy, H:mm:ss')}</div>
        </div>
    );
}
