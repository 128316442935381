import './index.css';
import { useAppDispatch } from '../../app/hooks';
import { clearError } from '../../app/commonSlice';
import { useEffect } from 'react';

interface IProps {
    message: string;
}

export function Toast({ message }: IProps) {
    const dispatch = useAppDispatch();

    const handleClearError = () => {
        dispatch(clearError());
    };

    // Clear error after 5 seconds
    useEffect(() => {
        const timeout = setTimeout(handleClearError, 5000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className='toast'>
            <div>{message}</div>
            <img
                src='/images/ico_close.svg'
                alt='close'
                className='hover'
                onClick={handleClearError}
            />
        </div>
    );
}
