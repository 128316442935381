export interface Business {
    name: string;
    customSlot: boolean;
}

export interface BookingActivity {
    id: string;
    room: string;
    name: string;
    capacity: number;
    disableOther: boolean;
    duration: number;
    defaultDuration: number;
    defaultCapacity: number;
    defaultLead: string;
}

export interface BookingType {
    type: string;
    capacity: number;
}

export interface BookingSlotBase {
    sessionId: string;
    room: string;
    lead: string;
    date: string;
    duration: number;
    from: number;
    startDateTime: number;
    capacity: number;
}

export interface BookingSlot extends BookingSlotBase {
    slotId: string;
    activityName: string;
    activityId: string;
    attendees: string[];
    id: string;
    user: string;
    guests: { [key: string]: string[] };
}

export interface BookingSlotRequest extends BookingSlotBase {
    repeatWeekly: boolean;
    repeatOnDays: number[];
    repeatDaily: boolean;
    lastSlotStartTime: number;
    breakBetweenSlotsInMinutes: number;
    repeat?: boolean;
    activityId: string;
    name: string;
}

export enum WeekType {
    WORKING = 'working',
    FULL = 'full'
}

export enum BookingTab {
    CALENDAR = 'calendar',
    SESSIONS = 'sessions'
}

export interface CustomBookingForm {
    date: Date;
    room: string;
    durationInHours: number;
}

export interface BookingSlotInfo {
    attendees: string[];
    overlappingSlots: BookingSlot[];
}

export enum UpdateSlotAction {
    GUEST = 'guest',
    ATTENDEE = 'attendee'
}
