import { Amplify, Auth } from 'aws-amplify';
import { ClientDetails } from '../api-types';
import { loginRequest } from '../authConfig';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';

export interface AuthConfig {
    mandatorySignIn: boolean;
    region?: string;
    userPoolId?: string;
    userPoolWebClientId?: string;
    oauth: {
        domain?: string;
        scope: string[];
        redirectSignIn?: string;
        redirectSignOut?: string;
        responseType: string;
    };
}

export interface AWSConfig {
    Auth: {
        users: AuthConfig;
    };
    API: {
        endpoints: {
            name: string;
            endpoint: string;
            region?: string;
            custom_header: () => Promise<{
                Authorization: string;
            }>;
        }[];
    };
}

export async function getAzureADUserToken(
    instance: IPublicClientApplication,
    accounts: AccountInfo[]
) {
    if (accounts.length != 0) {
        return instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            })
            .then((response) => ({
                idToken: response.idToken,
                accessToken: response.accessToken
            }))
            .catch((e) => {
                console.log(e);
                localStorage.clear();
                return {
                    idToken: undefined,
                    accessToken: undefined
                };
            });
    }
}

export async function getCognitoUserToken() {
    return Auth.currentAuthenticatedUser().then(
        (user) => user.signInUserSession.idToken.jwtToken
    );
}

export async function setAmplifyConfig(
    token: { accessToken?: string; idToken?: string } | undefined
): Promise<AWSConfig> {
    return axios
        .get(`${process.env.REACT_APP_REDIRECT_URL ?? ''}/api/v2/config/web`)
        .then((response: { data: ClientDetails }) => {
            const users = response.data;
            const config: AWSConfig = {
                Auth: {
                    users: {
                        mandatorySignIn: true,
                        region: users.region,
                        userPoolId: users.userPoolId,
                        userPoolWebClientId: users.clientId,
                        oauth: {
                            domain: users.webDomain?.replace(
                                /^https?:\/\//,
                                ''
                            ),
                            scope: [
                                'email',
                                'openid',
                                'aws.cognito.signin.user.admin'
                            ],
                            redirectSignIn: `${window.location.origin}/login`,
                            redirectSignOut: `${window.location.origin}/logout`,
                            responseType: 'code'
                        }
                    }
                },
                API: {
                    endpoints: [
                        {
                            name: 'brunch',
                            endpoint: `${
                                process.env.REACT_APP_REDIRECT_URL ?? ''
                            }/api`,
                            region: users.region,
                            custom_header: async (): Promise<{
                                Authorization: string;
                                accessToken?: string;
                            }> => {
                                return {
                                    Authorization: `Bearer ${
                                        token?.idToken ??
                                        (await getCognitoUserToken())
                                    }`,
                                    ['accessToken']: token?.accessToken
                                };
                            }
                        },
                        {
                            name: 'brunch-v2',
                            endpoint: `${
                                process.env.REACT_APP_REDIRECT_URL ?? ''
                            }/api/v2`,
                            region: users.region,
                            custom_header: async (): Promise<{
                                Authorization: string;
                                AccessToken?: string;
                            }> => {
                                return {
                                    Authorization: `Bearer ${
                                        token?.idToken ??
                                        (await getCognitoUserToken())
                                    }`,
                                    ['AccessToken']: token?.accessToken
                                };
                            }
                        }
                    ]
                }
            };

            Amplify.configure({
                Auth: config.Auth.users,
                API: config.API
            });

            return config;
        });
}
