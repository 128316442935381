import { format, getDay, lastDayOfMonth, subDays } from 'date-fns';

export function getLastDayOfMonth(date: Date, dayIndex: number) {
    // Get the last day of the month
    const lastDay = lastDayOfMonth(date);

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = getDay(lastDay);

    // Calculate the number of days to subtract to get the target weekday
    const daysToSubtract = (dayOfWeek + 7 - dayIndex) % 7;

    // Use subDays to subtract the calculated days from the last day of the month
    const lastWeekday = subDays(lastDay, daysToSubtract);

    return format(lastWeekday, 'PPPP');
}
