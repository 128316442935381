import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { SidebarState } from '../components/calendar/calendarSlice';
import { AuthState } from './authSlice';
import type { RootState, AppDispatch } from './store';
import { UserSettingState } from './userSlice';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): Dispatch<AnyAction> &
    ThunkDispatch<
        {
            auth: AuthState;
            sidebar: SidebarState;
            userSettings: UserSettingState;
        },
        null,
        AnyAction
    > => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
