import './index.css';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectAuthUser } from '../../app/authSlice';
import { BookingAdmin } from '../../components/booking';
import { BookingUser } from '../../components/bookingUser';
import { isOneOfTheFitnessGroups } from 'utils/isOneOfTheFitnessGroups';

export function BookingPage() {
    const [isCoach, setIsCoach] = useState<boolean>();

    const user = useAppSelector(selectAuthUser);

    useEffect(() => {
        if (user) {
            setIsCoach(isOneOfTheFitnessGroups(user));
        } else {
            setIsCoach(false);
        }
    }, [user]);

    // If isCoach is not calculated yet, return empty fragment to avoid logging wrong page view
    if (isCoach === undefined) return <></>;

    return isCoach ? <BookingAdmin /> : <BookingUser />;
}
