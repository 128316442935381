import { findSlotsForTimeFrame } from 'utils/findSlotsForTimeFrame';
import { BookingSlot } from 'api-types/models/Booking';
import { BookingSlotContent } from '../bookingSlotContent';

interface IProps {
    hour: number;
    startOfWeekDate: Date;
    index: number;
    slotsPerWeek: BookingSlot[];
    setSelectedSlot: (slot: BookingSlot) => void;
}

export function BookingTableCell({
    hour,
    startOfWeekDate,
    index,
    slotsPerWeek,
    setSelectedSlot
}: Readonly<IProps>) {
    const slots = findSlotsForTimeFrame(
        startOfWeekDate,
        index,
        hour,
        slotsPerWeek
    );

    return (
        <td className={slots.length > 0 ? 'active' : 'empty'}>
            <div className='wrapper'>
                {slots.map((slot) => (
                    <BookingSlotContent
                        key={slot.slotId}
                        slot={slot}
                        setSelectedSlot={setSelectedSlot}
                        slotStartHour={hour}
                    />
                ))}
            </div>
        </td>
    );
}
