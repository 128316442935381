import {
    BookingActivity,
    BookingSlot,
    BookingSlotRequest
} from 'api-types/models/Booking';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { RepeatDays } from '../repeatDays';
import { InputField } from 'components/inputField';
import { Switch } from 'components/switch';

import './index.css';
import { BookingAttendees } from 'components/bookingAttendees';

interface IProps {
    slot?: BookingSlot;
    date?: Date;
    setDate: (date?: Date) => void;
    form: UseFormReturn<BookingSlotRequest, unknown>;
    selectedRoom?: string;
    rooms: string[];
    activities: BookingActivity[];
    error?: string;
    handleAddSlot: (slot: BookingSlotRequest) => void;
}

export function BookingSlotForm({
    slot,
    date,
    setDate,
    form,
    selectedRoom,
    rooms,
    activities,
    error,
    handleAddSlot
}: Readonly<IProps>) {
    const { t } = useTranslation();
    const {
        setValue,
        handleSubmit,
        watch,
        getValues,
        formState: { errors }
    } = form;

    const repeatWeekly = watch('repeatWeekly');
    const repeatDaily = watch('repeatDaily');
    const days = watch('repeatOnDays');
    const activityId = watch('activityId');
    const duration = watch('duration');
    const lastSlotStartTime = watch('lastSlotStartTime');

    useEffect(() => {
        if (selectedRoom) {
            setValue('room', selectedRoom);
        }
    }, [selectedRoom]);

    useEffect(() => {
        if (!duration) {
            const activity = activities.find(
                (activity) => activity.id === activityId
            );

            setValue('duration', activity?.defaultDuration as number);
            setValue('capacity', activity?.defaultCapacity as number);
            setValue('lead', activity?.defaultLead as string);
        }
    }, [activityId]);

    return (
        <form className='add-slot' onSubmit={handleSubmit(handleAddSlot)}>
            <div>
                <InputField<BookingSlotRequest>
                    form={form}
                    value={getValues('room') ?? selectedRoom}
                    name='room'
                    label={t('room')}
                    select={true}
                    options={rooms.map((room) => ({
                        value: room,
                        label: room
                    }))}
                    disabled={true}
                    required={true}
                />
                <InputField<BookingSlotRequest>
                    form={form}
                    value={getValues('activityId')}
                    name='activityId'
                    label={t('session')}
                    select={true}
                    options={[
                        {
                            value: undefined,
                            label: t('select_session')
                        },
                        ...activities
                            .filter(
                                (activity) => activity.room === selectedRoom
                            )
                            .map((activity) => ({
                                value: activity.id,
                                label: activity.name
                            }))
                    ]}
                    disabled={slot !== undefined}
                    required={true}
                />
                <div className='input-group'>
                    <label>{t('start_time')}*</label>
                    <DatePicker
                        className='input-form css-13cymwt-control'
                        selected={
                            date ??
                            (slot !== undefined
                                ? new Date(slot.startDateTime)
                                : new Date())
                        }
                        onChange={(selectedDate) =>
                            setDate(selectedDate ?? undefined)
                        }
                        showTimeInput
                        dateFormat={'dd/MM/yyyy HH:mm'}
                        timeInputLabel='Time:'
                        disabled={slot !== undefined}
                    />
                </div>
                <InputField<BookingSlotRequest>
                    form={form}
                    value={getValues('duration')}
                    name='duration'
                    label={t('duration')}
                    number={true}
                    required={true}
                    step={1}
                />
                <InputField<BookingSlotRequest>
                    form={form}
                    value={getValues('capacity')}
                    name='capacity'
                    label={t('capacity')}
                    number={true}
                    required={true}
                    step={1}
                />
                <InputField<BookingSlotRequest>
                    form={form}
                    value={getValues('lead')}
                    name='lead'
                    label={t('coach')}
                />
                {!slot && (
                    <>
                        <div className='switch-label repeat hint'>
                            <Switch
                                checked={repeatWeekly ?? false}
                                onChange={(checked) =>
                                    setValue('repeatWeekly', checked)
                                }
                                label='repeat'
                            />
                            <small>{t('repeat_hint')}</small>
                        </div>

                        {repeatWeekly && (
                            <RepeatDays
                                days={days}
                                setValue={setValue}
                                date={date}
                            />
                        )}
                        <hr className='divider' />

                        <div className='switch-label repeat hint'>
                            <Switch
                                checked={repeatDaily ?? false}
                                onChange={(checked) =>
                                    setValue('repeatDaily', checked)
                                }
                                label='repeat_in_a_day'
                            />
                            <small>{t('repeat_in_a_day_hint')}</small>
                        </div>

                        {repeatDaily && (
                            <>
                                <div className='input-group'>
                                    <label>{t('start_time_last_slot')}*</label>
                                    <DatePicker
                                        className='input-form css-13cymwt-control'
                                        selected={
                                            lastSlotStartTime || date
                                                ? new Date(
                                                      lastSlotStartTime ?? date
                                                  )
                                                : new Date()
                                        }
                                        onChange={(selectedDate) => {
                                            setValue(
                                                'lastSlotStartTime',
                                                selectedDate?.getTime() ?? 0
                                            );
                                        }}
                                        showTimeSelectOnly
                                        showTimeInput
                                        dateFormat={'HH:mm'}
                                        timeInputLabel='Time:'
                                        disabled={slot !== undefined}
                                    />
                                </div>
                                <InputField<BookingSlotRequest>
                                    form={form}
                                    value={getValues(
                                        'breakBetweenSlotsInMinutes'
                                    )}
                                    name='breakBetweenSlotsInMinutes'
                                    label={t('break_between_slots')}
                                    number={true}
                                />
                            </>
                        )}
                    </>
                )}
                {slot && slot.attendees.length > 0 && (
                    <BookingAttendees attendees={slot.attendees} />
                )}
            </div>
            {error && <p className='error'>{error}</p>}
            {Object.keys(errors).map((error) => (
                <p className='error' key={error}>
                    {error}
                </p>
            ))}
        </form>
    );
}
