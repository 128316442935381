import './index.css';
import { ChangeEvent, useState } from 'react';
import debounce from 'lodash.debounce';

interface IProps {
    setSearch: (search: string) => void;
}

export function Search({ setSearch }: IProps) {
    const [searchVisible, setSearchVisible] = useState(false);

    const delayedSearch = debounce((query: string) => {
        setSearch(query.toLowerCase());
    }, 1000); // Adjust the debounce delay as needed (e.g., 500ms)

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        delayedSearch(query);
    };

    return !searchVisible ? (
        <div
            className='search-placeholder'
            onClick={() => setSearchVisible(true)}
        >
            <img src='/images/ico_search.svg' alt='search' />
        </div>
    ) : (
        <div className='search-bar'>
            <input className='input-form search' onChange={handleInputChange} />
            <img
                src='/images/ico_close.svg'
                alt='close'
                onClick={() => {
                    setSearchVisible(false);
                    delayedSearch('');
                }}
            />
        </div>
    );
}
