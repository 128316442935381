import { useNavigate } from 'react-router-dom';

interface IProps {
    route: string;
    pathname: string;
}

export function SideMenuItem({ route, pathname }: IProps) {
    const navigate = useNavigate();

    return (
        <div
            onClick={() => navigate(route)}
            className={`sidemenu-item ${route === pathname ? 'active' : ''}`}
        >
            <img
                src={`/images/ico_${
                    route !== '/' ? route.replace('/', '') : 'menu'
                }.svg`}
                alt={route}
            />
        </div>
    );
}
