import { API } from 'aws-amplify';
import { MenuItemDto } from '../../api-types';
import { handleError } from '../../utils/handleError';

export async function getRegistryItems() {
    return API.get('brunch-v2', '/menu/registry/all', {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function createRegistryItem(registryItem: MenuItemDto) {
    return API.post('brunch-v2', '/menu/registry', {
        body: registryItem
    })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function updateRegistryItemRequest(registryItem: MenuItemDto) {
    return API.put('brunch-v2', `/menu/registry/${registryItem.uuid}`, {
        body: registryItem
    })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function deleteRegistryItem(
    registryItem: MenuItemDto,
    activate: boolean
) {
    return API.patch(
        'brunch-v2',
        `/menu/registry/${registryItem.uuid}/${
            activate ? 'activate' : 'deactivate'
        }`,
        {}
    )
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}
