import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import logging from './config/logging';
import { protectedRoutes, routes } from './config/routes';
import MenuItemsLoader from './components/loading/menuLoader';
import { selectConfig, setConfigThunk } from './app/configSlice';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { ProtectedRoute } from './components/protectedRoutes';
import { getUserSettings } from './api/userSettings';
import { UserDto } from './api-types';
import { StatusPage } from './pages/status';
import { useMsal } from '@azure/msal-react';
import { setUserSettings } from './app/userSlice';
import { getAzureADUserToken } from './config/aws';
import { LoginType } from './api-types/models/LoginDto';
import { resetUserData, selectAuthUser } from './app/authSlice';
import { ObserverPage } from './pages/observer';
import { getUserGroups } from './utils/getUserGroups';
import { Toast } from './components/toast';
import { selectError } from './app/commonSlice';
import i18n from 'i18n';

import './App.css';

const App: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const config = useAppSelector(selectConfig);
    const user = useAppSelector(selectAuthUser);
    const error = useAppSelector(selectError);

    const { instance, accounts } = useMsal();
    const loginType = localStorage.getItem('login');
    const language = localStorage.getItem('lang');

    useEffect(() => {
        if (!['/status', '/observer'].includes(location.pathname)) {
            logging.info('Loading application.');

            if (loginType === LoginType.SPORTRADAR && accounts.length) {
                getAzureADUserToken(instance, accounts).then((token) => {
                    if (token) {
                        const { idToken, accessToken } = token;

                        dispatch(setConfigThunk({ idToken, accessToken }));
                    }
                });
            } else {
                dispatch(setConfigThunk(undefined));
            }
        }
    }, [instance, accounts]);

    useEffect(() => {
        const isUser =
            user &&
            !getUserGroups(user).includes(LoginType.BRUNCH) &&
            !getUserGroups(user).includes(LoginType.COACH);
        if ((config && isUser) || loginType === LoginType.SPORTRADAR) {
            getUserSettings().then((response: UserDto) => {
                if (!response['error']) {
                    dispatch(setUserSettings(response));
                } else {
                    if (response['error'] === 401) {
                        if (
                            localStorage.getItem('login') ===
                            LoginType.SPORTRADAR
                        ) {
                            localStorage.clear();
                            instance
                                .logoutPopup({
                                    postLogoutRedirectUri: '/'
                                })
                                .then(() => navigate('/'));
                        } else {
                            localStorage.clear();
                            navigate('/');
                            dispatch(resetUserData());
                        }
                    }
                }
            });
        }
    }, [config, user]);

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language]);

    if (location.pathname === '/status') {
        return <StatusPage />;
    }

    if (location.pathname === '/observer') {
        return <ObserverPage />;
    }

    if (!config) {
        return <MenuItemsLoader />;
    }

    return (
        <div className='kitchen-wrapper'>
            <Routes>
                {routes.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            element={<route.component name={route.name} />}
                        />
                    );
                })}
                {protectedRoutes.map((route) => {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={<ProtectedRoute route={route} />}
                        />
                    );
                })}
            </Routes>
            {error && <Toast message={error.message} />}
        </div>
    );
};

export default App;
