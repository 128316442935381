import { MenuItemDto } from './MenuItemDto';

export type OrderDto = {
    date: string;
    id: string;
    userEmail: string;
    price: number;
    servedPosition: string;
    tableNumber: number;
    placedAt: number;
    submittedAt: number;
    inProgressAt: number;
    servedAt: number;
    canceledAt: number;
    displayName: string;
    comment: string;
    status: OrderStatus;
    userType: string;
    discountedFrom: number;
    menuItem: MenuItemDto;
    tag: string;
    servingLine: string;
    orderItems: RecentOrderDto[];
};

export enum OrderStatus {
    PLACED = 'PLACED',
    SUBMITTED = 'SUBMITTED',
    IN_PROGRESS = 'IN_PROGRESS',
    CANCELED = 'CANCELED',
    SERVED = 'COMPLETE'
}

export type RecentOrderDto = {
    orderItemId: number;
    menuItem: MenuItemDto;
    servingLine: string;
    takeout: boolean;
    servedAt: number;
    displayName: string;
};
