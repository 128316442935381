import { BookingActivity } from 'api-types/models/Booking';
import { deleteBookingActivity } from 'api/booking';
import { setError } from 'app/commonSlice';
import { useAppDispatch } from 'app/hooks';
import { useTranslation } from 'react-i18next';

interface IProps {
    sessions: BookingActivity[];
    openEditModal: (service: BookingActivity) => void;
    deleteSession: (service: BookingActivity) => void;
}

export function BookingSessionTable({
    sessions,
    openEditModal,
    deleteSession
}: Readonly<IProps>) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const handleDeleteSession = (session: BookingActivity) => {
        deleteBookingActivity(session).then((response) => {
            if (!response.error) {
                deleteSession(session);
            } else {
                dispatch(setError(response.error));
            }
        });
    };

    return (
        <table>
            <thead>
                <tr>
                    <th>{t('name')}</th>
                    <th>{t('capacity')}</th>
                    <th>{t('default_duration')}</th>
                    <th>{t('default_lead')}</th>
                    <th>{t('disable_other')}</th>
                    <th>{t('actions')}</th>
                </tr>
            </thead>
            <tbody>
                {sessions.map((session) => (
                    <tr key={session.id}>
                        <td>{session.name}</td>
                        <td>{session.defaultCapacity}</td>
                        <td>{session.defaultDuration}</td>
                        <td>{session.defaultLead}</td>
                        <td>
                            <input
                                type='checkbox'
                                checked={session.disableOther}
                                disabled={true}
                            />
                        </td>
                        <td>
                            <div className='actions'>
                                <button
                                    className='btn'
                                    onClick={() => openEditModal(session)}
                                >
                                    <img
                                        src='/images/ico_edit_blue.svg'
                                        alt='edit'
                                    />
                                </button>
                                <button
                                    className='btn'
                                    onClick={() => handleDeleteSession(session)}
                                >
                                    <img
                                        src='/images/ico_remove_blue.svg'
                                        alt='remove'
                                    />
                                </button>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
