import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

export function renewSSOTokens(
    instance: IPublicClientApplication,
    account: AccountInfo,
    loginRequest: {
        scopes: string[];
    }
) {
    instance
        .acquireTokenSilent({ account, ...loginRequest })
        .then((response) => {
            const msalTokenKey = Object.keys(localStorage).find((key) =>
                key.startsWith('msal.token.keys')
            );
            if (msalTokenKey && localStorage.getItem(msalTokenKey)) {
                const tokenObj = JSON.parse(
                    localStorage.getItem(msalTokenKey) as string
                );
                const newTokenObj = {
                    idToken: [response.idToken],
                    accessToken: [response.accessToken],
                    refreshToken: tokenObj.refreshToken
                };
                localStorage.setItem(msalTokenKey, JSON.stringify(newTokenObj));
                window.location.reload();
            }
        })
        .catch((e) => {
            console.log(e);
        });
}
