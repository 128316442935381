import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../app/hooks';
import { updateUserThunk } from '../../../../app/userSlice';
import { SportradarUser } from '../../../../interfaces/sportradarUser';

interface IProps {
    user: SportradarUser;
}

export function UserItem({ user }: IProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [editMode, setEditMode] = useState<boolean>(false);
    const [employeeCode, setEmployeeCode] = useState<string>(user.employeeCode);
    const [directPayment, setDirectPayment] = useState<boolean>(
        user.directPayment
    );

    const handleUpdateUser = useCallback(() => {
        const updatedUser: SportradarUser = {
            email: user.email,
            directPayment,
            employeeCode
        };

        dispatch(updateUserThunk(updatedUser));
        setEditMode(false);
    }, [directPayment, employeeCode, user]);

    const handleCancelUpdateUser = useCallback(() => {
        setEditMode(false);
        setEmployeeCode(user.employeeCode);
        setDirectPayment(user.directPayment);
    }, []);

    return (
        <div className='user-item' key={user.email}>
            <div className='email'>{user.email}</div>
            <div className='employee-code'>
                {editMode ? (
                    <input
                        className='input-form'
                        type='text'
                        value={employeeCode}
                        onChange={(e) => setEmployeeCode(e.target.value)}
                        onKeyDown={(e) =>
                            e.key === 'Enter' ? handleUpdateUser() : undefined
                        }
                    />
                ) : (
                    <div className='value'>{user.employeeCode}</div>
                )}
            </div>
            <div className='direct-payment'>
                <input
                    className='checkbox'
                    type='checkbox'
                    checked={directPayment}
                    disabled={!editMode}
                    onChange={(e) => setDirectPayment(e.target.checked)}
                />
            </div>
            <div className='action'>
                {editMode ? (
                    <>
                        <div
                            className='btn btn-blue'
                            onClick={handleUpdateUser}
                        >
                            {t('save')}
                        </div>
                        <div
                            className='btn btn-gray'
                            onClick={() => handleCancelUpdateUser()}
                        >
                            {t('cancel')}
                        </div>
                    </>
                ) : (
                    <div
                        className='btn btn-gray'
                        onClick={() => setEditMode(true)}
                    >
                        {t('edit')}
                    </div>
                )}
            </div>
        </div>
    );
}
