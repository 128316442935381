import './index.css';
import { getColorByAttendance } from '../../utils/getColorByAttendance';
import ReactTooltip from 'react-tooltip';

interface IProps {
    attendees: string[];
    capacity: number;
    guests: { [key: string]: string[] };
}

export function Attendance({ attendees, capacity, guests }: IProps) {
    const joinedGuests =
        ([] as string[]).concat(...Object.values(guests)) ?? [];
    const joinedAttendees = attendees.concat(joinedGuests);

    return (
        <div className='attendance'>
            {Array.from({ length: capacity }).map((_, index: number) => (
                <div
                    data-tip={joinedAttendees[index]}
                    key={`attendance-${index}`}
                    className={`${
                        index < joinedAttendees.length
                            ? getColorByAttendance(
                                  joinedAttendees.length / capacity
                              )
                            : ''
                    } attendee`}
                >
                    <ReactTooltip key={`tooltip-${joinedAttendees[index]}`} />
                </div>
            ))}
        </div>
    );
}
