import { useTranslation } from 'react-i18next';
import { MenuItemDto } from '../../api-types';
import MenuItem from '../menuItem';
import { capitalizeFirstLetter } from '../../utils/capitalizeCase';

interface IProps {
    menuItems: MenuItemDto[];
    category: string;
}

export function MenuCategory({ menuItems, category }: IProps): JSX.Element {
    const { t } = useTranslation();

    if (menuItems.length === 0) {
        return <></>;
    }
    return (
        <>
            <div className='menu-title'>
                {capitalizeFirstLetter(t(category.toLowerCase()))}
            </div>
            <div className={'menu-dishes'}>
                {menuItems.map((menuItem) => (
                    <MenuItem key={menuItem.uuid} menuItem={menuItem} />
                ))}
            </div>
        </>
    );
}
