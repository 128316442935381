import './index.css';
import { useForm } from 'react-hook-form';
import { BookingActivity } from '../../api-types/models/Booking';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { addBookingActivity, updateBookingActivity } from '../../api/booking';
import { Modal } from '../modal';
import { InputField } from '../inputField';
import { useAppSelector } from '../../app/hooks';
import { selectRoom } from '../../app/bookingSlice';
import { Switch } from '../switch';

interface IProps {
    closeModal: () => void;
    addActivity: (Activity: BookingActivity) => void;
    rooms: string[];
    updateActivity: (Activity: BookingActivity) => void;
    activity?: BookingActivity;
}

export function AddBookingActivityModal({
    closeModal,
    addActivity,
    activity,
    rooms,
    updateActivity
}: Readonly<IProps>) {
    const form = useForm<BookingActivity>({
        mode: 'all',
        defaultValues: activity
    });
    const { t } = useTranslation();

    const { handleSubmit, setValue, getValues, watch } = form;

    const selectedRoom = useAppSelector(selectRoom);

    // This needs to be added for Switch to work
    watch('disableOther');

    const handleAddBookingActivity = useCallback(
        (data: BookingActivity) => {
            if (activity) {
                updateBookingActivity(data).then((response) => {
                    if (!response.error) {
                        updateActivity(response);
                    }
                });
            } else {
                addBookingActivity(data).then((response) => {
                    if (!response.error) {
                        addActivity(response);
                    }
                });
            }
        },
        [activity]
    );

    const handleCloseModal = () => {
        form.reset();
        closeModal();
    };

    return (
        <Modal
            title={activity ? 'update_session' : 'add_session'}
            actionButtons={
                <>
                    <button className='btn btn-gray' onClick={handleCloseModal}>
                        {t('cancel')}
                    </button>
                    <button
                        className='btn btn-blue'
                        onClick={handleSubmit(handleAddBookingActivity)}
                    >
                        {t('save')}
                    </button>
                </>
            }
            closeModal={handleCloseModal}
            content={
                <div className='add-business'>
                    <form
                        className='add-business-form'
                        onSubmit={handleSubmit(handleAddBookingActivity)}
                    >
                        <InputField<BookingActivity>
                            form={form}
                            value={getValues('name')}
                            name='name'
                            label={t('name')}
                        />
                        <InputField<BookingActivity>
                            form={form}
                            value={getValues('room') ?? selectedRoom}
                            name='room'
                            label={t('room')}
                            select={true}
                            options={rooms.map((room) => ({
                                value: room,
                                label: room
                            }))}
                        />
                        <InputField<BookingActivity>
                            form={form}
                            value={getValues('defaultCapacity')}
                            name='defaultCapacity'
                            label={t('capacity')}
                            number={true}
                        />
                        <InputField<BookingActivity>
                            form={form}
                            value={getValues('defaultDuration')}
                            name='defaultDuration'
                            label={t('duration')}
                            number={true}
                        />
                        <InputField<BookingActivity>
                            form={form}
                            value={getValues('defaultLead')}
                            name='defaultLead'
                            label={t('default_lead')}
                        />
                        <Switch
                            checked={getValues('disableOther') ?? false}
                            onChange={(checked) =>
                                setValue('disableOther', checked)
                            }
                            label='disable_other'
                        />
                    </form>
                </div>
            }
        />
    );
}
