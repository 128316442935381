import { enUS, sl } from 'date-fns/locale';

export function getLocale() {
    const language = localStorage.getItem('lang');

    if (language === 'sl') {
        return sl;
    } else {
        return enUS;
    }
}
