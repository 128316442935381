import './index.css';
import { getMonthsArray } from '../../../../utils/monthUtil';
import React from 'react';
import { format } from 'date-fns';
import i18n from '../../../../i18n';
import { enGB, sl } from 'date-fns/locale';

interface IProps {
    currentMonthIndex: number;
    activeMonthIndex?: number;
    setActiveMonthIndex(monthIndex: number): void;
}

export function Months({
    currentMonthIndex,
    activeMonthIndex,
    setActiveMonthIndex
}: IProps): JSX.Element {
    return (
        <div className='months'>
            {getMonthsArray(currentMonthIndex).map((monthIndex: number) => (
                <React.Fragment key={monthIndex}>
                    <div
                        key={'month' + monthIndex}
                        className={`month-item btn-round ${
                            activeMonthIndex !== undefined &&
                            activeMonthIndex === monthIndex &&
                            'btn-round-active'
                        }`}
                        onClick={() => setActiveMonthIndex(monthIndex)}
                    >
                        {format(
                            new Date(new Date().getFullYear(), monthIndex, 1),
                            'LLLL',
                            { locale: i18n.language === 'en' ? enGB : sl }
                        )}
                    </div>
                    <div
                        key={'year' + monthIndex}
                        className='year-separator'
                        hidden={monthIndex !== 11}
                    >
                        {new Date().getFullYear()}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
}
