import { useEffect, useState } from 'react';
import { RecentOrderDto } from '../../api-types/models/OrderDto';
import { getWsToken } from '../../api/auth';
import { FoodIcon } from '../../components/foodIcon';
import { StatsItem } from './StatsItem';

import './index.css';
import { OrderStatisticsDto } from '../../api-types/models/OrderStatisticsDto';
import { isEmptyStatistics } from '../../utils/isEmptyStatistics';

const getNewToken = async () => {
    return getWsToken().then((res) => {
        return res.data.token;
    });
};

export function ObserverPage(): JSX.Element {
    const [token, setToken] = useState<string>();
    const [orders, setOrders] = useState<RecentOrderDto[]>([]);
    const [time, setTime] = useState<Date>(new Date());
    const [statistics, setStatistics] = useState<OrderStatisticsDto>();
    const [isKitchenOpen, setIsKitchenOpen] = useState<boolean>(false);

    const param = new URLSearchParams(location.search);
    const theme = param.get('theme') ?? 'light';

    const connectToWebSocket = (token: string) => {
        let newOrders = [] as RecentOrderDto[];

        const webSocket = new WebSocket(
            `wss://${
                process.env.REACT_APP_WEBSOCKET_DOMAIN ?? window.location.host
            }/api/ws/v2/kitchen?authToken=${token}`
        );

        // Get list of all orders in the last 5 minutes when connection is created
        webSocket.onopen = () => {
            webSocket.send(JSON.stringify({ event: 'get_recent_orders' }));
            webSocket.send(JSON.stringify({ event: 'get_opening_times' }));
        };

        // Reconnect if connection falls
        webSocket.onclose = () =>
            getNewToken().then((token) => connectToWebSocket(token));

        // Keep connection alive by pinging server with ping requests
        setInterval(() => {
            try {
                const state = webSocket.readyState;

                if (state == 1) {
                    webSocket.send(JSON.stringify({ event: 'ping' }));
                } else if (state !== 2 && state !== 3) {
                    webSocket.close();
                }
            } catch (e) {
                if (webSocket.readyState !== 2 && webSocket.readyState !== 3) {
                    webSocket.close();
                }
            }
        }, 10000);

        // Every minute clean order items that are older than 1 minute & check is Kitchen is open
        setInterval(() => {
            webSocket.send(JSON.stringify({ event: 'get_recent_orders' }));
            webSocket.send(JSON.stringify({ event: 'get_opening_times' }));
        }, 60000);

        webSocket.onmessage = (e) => {
            try {
                const message = JSON.parse(e.data);

                // handle only messages for orders
                switch (message.type) {
                    case 'RECENT_COMPLETED_ORDER':
                        newOrders = [
                            {
                                ...message.data.orderItem,
                                displayName: message.data.displayName
                            },
                            ...newOrders
                        ];
                        setOrders(newOrders);
                        break;
                    case 'RECENT_ORDERS':
                        newOrders = message.data.map(
                            (order: {
                                displayName: string;
                                orderItem: RecentOrderDto;
                            }) => ({
                                ...order.orderItem,
                                displayName: order.displayName
                            })
                        );
                        setOrders(newOrders);
                        break;
                    case 'STATISTICS':
                        setStatistics(message.data);
                        break;
                    case 'OPENING_TIMES':
                        setIsKitchenOpen(
                            message.data.lunch || message.data.breakfast
                        );
                        break;
                    default:
                        break;
                }
            } catch {
                console.log(e.data);
            }
        };

        // Try reconnecting if error
        webSocket.onerror = () => {
            console.log('Error');
            webSocket.close();
        };

        // Close connection on unmount
        return () => {
            webSocket.close();
        };
    };

    useEffect(() => {
        getNewToken().then((token: string) => {
            setToken(token);
        });
    }, []);

    useEffect(() => {
        if (token !== undefined) {
            connectToWebSocket(token);
        }
    }, [token]);

    // Live countdown
    useEffect(() => {
        const interval = setInterval(() => setTime(new Date()), 30000);

        return () => clearInterval(interval);
    }, [time]);

    return (
        <div className={`observer-page ${theme}`} id='observer'>
            {!isKitchenOpen ? (
                <div className={`no-orders observer ${theme}`}>
                    <img src='/images/kitchen_closed.png' alt='closed' />
                    <div className='dish'>
                        <h4>BAR:</h4>
                        <h3>7:00 - 22:00</h3>
                    </div>
                    <div className='dish'>
                        <h4>BREAKFAST:</h4>
                        <h3>7:00 - 10:30</h3>
                    </div>
                    <div className='dish'>
                        <h4>LUNCH:</h4>
                        <h3>11:00 - 14:00</h3>
                    </div>
                </div>
            ) : orders.length ? (
                orders.map((order: RecentOrderDto) => (
                    <div className='observer-item' key={order.orderItemId}>
                        <div className='graphics'>
                            <FoodIcon
                                foodType={order.menuItem.icon}
                                color='white'
                            />
                            {order.servingLine}
                        </div>
                        <strong className='order-title'>
                            {order.displayName}
                        </strong>
                    </div>
                ))
            ) : (
                <div className='empty-orders'>
                    <img src='/images/cooking.png' alt='cooking' />
                    <h3>The kitchen is ready, and there's no one in line.</h3>
                </div>
            )}
            {isKitchenOpen && statistics && !isEmptyStatistics(statistics) && (
                <div className='stats'>
                    <StatsItem title='served' value={statistics.completed} />
                </div>
            )}
        </div>
    );
}
