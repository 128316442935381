import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { WeekType } from '../api-types/models/Booking';

export interface BookingState {
    room: string;
    weekType: WeekType;
}

const initialState: BookingState = {
    room: 'GYM',
    weekType: WeekType.WORKING
};

export const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        setRoom(state, action) {
            return {
                ...state,
                room: action.payload
            };
        },
        setWeekType(state, action) {
            return {
                ...state,
                weekType: action.payload
            };
        }
    }
});

export const selectRoom = (state: RootState): string => {
    return state.booking.room;
};

export const selectWeekType = (state: RootState): WeekType => {
    return state.booking.weekType;
};

export const { setRoom, setWeekType } = bookingSlice.actions;

export default bookingSlice.reducer;
