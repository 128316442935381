import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
    isBlocked: boolean;
}

export function Prompt({ isBlocked }: IProps) {
    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Cancel the event to prevent the user from leaving
            event.preventDefault();
            // Chrome requires the returnValue property to be set
            event.returnValue = '';
        };

        isBlocked &&
            window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // Clean up the event listener when the component is unmounted
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [navigate]);

    return <></>;
}
