import { BookingSlot, UpdateSlotAction } from 'api-types/models/Booking';
import { useAppSelector } from 'app/hooks';
import { selectUserSettings } from 'app/userSlice';
import { Modal } from 'components/modal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateBooking } from 'api/booking';

import './index.css';

interface IProps {
    slot: BookingSlot;
    updateSlot: (slot: BookingSlot, action: UpdateSlotAction) => void;
}

export function AddGuestToBooking({ slot, updateSlot }: Readonly<IProps>) {
    const { t } = useTranslation();

    const userSettings = useAppSelector(selectUserSettings);

    const [isAddGuestModalOpen, setIsAddGuestModalOpen] =
        useState<boolean>(false);
    const [guests, setGuests] = useState<string[]>([]);
    const [error, setError] = useState<string>();

    const handleCloseModal = () => setIsAddGuestModalOpen(false);

    const handleAddOrRemoveGuest = (guest: string) => {
        setGuests(
            guests.includes(guest)
                ? guests.filter((g) => g !== guest)
                : guests.concat(guest)
        );
    };

    const handleUpdateSlotWithGuests = () => {
        updateBooking(slot.slotId, guests).then((response) => {
            if (!response.error) {
                updateSlot(response, UpdateSlotAction.GUEST);
                setIsAddGuestModalOpen(false);
            } else {
                setError(response.error.message);
            }
        });
    };

    useEffect(() => {
        if (userSettings) {
            setGuests(slot.guests[userSettings.email] || []);
        }
    }, [userSettings]);

    return (
        <>
            <button
                className='btn btn-gray'
                onClick={() => setIsAddGuestModalOpen(true)}
            >
                + {t('add_guest')}
            </button>
            {isAddGuestModalOpen && (
                <Modal
                    title='add_guest'
                    actionButtons={
                        <>
                            <button
                                className='btn btn-gray'
                                onClick={handleCloseModal}
                            >
                                {t('cancel')}
                            </button>
                            <button
                                className='btn btn-blue'
                                disabled={
                                    !guests.length &&
                                    userSettings &&
                                    !slot.guests[userSettings.email].length
                                }
                                onClick={handleUpdateSlotWithGuests}
                            >
                                {t('submit')}
                            </button>
                        </>
                    }
                    closeModal={handleCloseModal}
                    content={
                        <div className='add-guest-modal'>
                            <p>{t('add_guest_hint')}</p>
                            <div className='guests'>
                                {userSettings?.guests?.map((guest) => (
                                    <div
                                        className={`guest-chip ${
                                            guests.includes(guest)
                                                ? 'active'
                                                : ''
                                        }`}
                                        key={guest}
                                        onClick={() =>
                                            handleAddOrRemoveGuest(guest)
                                        }
                                    >
                                        {guest}
                                    </div>
                                ))}
                            </div>
                            <div className='error'>
                                <small>{error}</small>
                            </div>
                        </div>
                    }
                ></Modal>
            )}
        </>
    );
}
