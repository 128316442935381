import { LoginType } from '../api-types/models/LoginDto';
import { isOneOfTheFitnessGroups } from './isOneOfTheFitnessGroups';
import { getUserGroups } from './getUserGroups';
import { CognitoUser } from 'interfaces/cognitoUser';

const brunchRoutes = ['/', '/registry', '/statistics'];
const bookingRoutes = ['/booking'];
const sportradarRoutes = ['/', '/booking', '/profile'];

export function getRoutesBasedOnUserGroups(
    user: CognitoUser | null | undefined
) {
    const userGroups = getUserGroups(user);
    let routes = [] as string[];

    if (userGroups.includes(LoginType.BRUNCH)) {
        routes = routes.concat(brunchRoutes);
    }

    if (isOneOfTheFitnessGroups(user)) {
        routes = routes.concat(bookingRoutes);
    }

    if (!user || userGroups.includes(LoginType.TENANTS)) {
        routes = routes.concat(sportradarRoutes);
    }

    // Remove duplicates from the array
    return routes.filter(
        (value, index, array) => array.indexOf(value) === index
    );
}
