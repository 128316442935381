import times from 'lodash/times';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { OrderDto } from '../../../../api-types';
import { getOrdersPrice } from '../../../../utils/calculateOrdersPrice';
import { FoodIcon } from '../../../../components/foodIcon';
import { BookingSlot } from '../../../../api-types/models/Booking';

import './index.css';

interface IProps<T> {
    weekIndex: number;
    numberOfWeeks: number;
    startDayIndex: number;
    startDateIndex: number;
    endDate: number;
    elementsForThisMonth?: T[];
    booking?: boolean;
}

export function Week<T>({
    weekIndex,
    numberOfWeeks,
    startDateIndex,
    endDate,
    elementsForThisMonth,
    booking = false
}: IProps<T>): JSX.Element {
    const { t } = useTranslation();

    if (
        elementsForThisMonth === undefined ||
        elementsForThisMonth.length === 0
    ) {
        return <></>;
    }

    const elementsForThisWeek = elementsForThisMonth?.filter(
        (element: unknown) => {
            const date = booking
                ? (element as BookingSlot).from
                : (element as OrderDto).date;
            return (
                new Date(date).getDate() >= startDateIndex + 2 &&
                new Date(date).getDate() <= startDateIndex + 7
            );
        }
    );

    const elementsForToday = (dayIndex: number): T[] =>
        elementsForThisWeek.filter(
            (element: unknown) =>
                new Date(
                    booking
                        ? (element as BookingSlot).from
                        : (element as OrderDto).date
                ).getDate() === dayIndex
        );

    return (
        <div
            className={`box week-box ${numberOfWeeks === 4 ? 'four' : 'five'}`}
        >
            <div className='week-options'>
                <div className='week-title'>
                    {t('week')} {weekIndex + 1}
                </div>
                {!booking && (
                    <div className='week-total'>
                        {getOrdersPrice(
                            elementsForThisWeek as unknown as OrderDto[]
                        )}
                        {' €'}
                    </div>
                )}
            </div>
            {times(5).map((index: number) => (
                <div
                    key={startDateIndex + index}
                    className={`${
                        startDateIndex + index + 1 < 0 ||
                        endDate < startDateIndex + index + 2
                            ? 'hidden'
                            : 'day-options'
                    }`}
                >
                    <div className='date'>{startDateIndex + index + 2}</div>
                    <div className='day-dishes'>
                        {elementsForToday(startDateIndex + index + 2).map(
                            (element: unknown) =>
                                booking ? (
                                    <React.Fragment
                                        key={`${
                                            (element as BookingSlot).room
                                        }-${(element as BookingSlot).from}`}
                                    >
                                        <div
                                            className='booking-history'
                                            data-tip={
                                                (element as BookingSlot)
                                                    .activityName
                                            }
                                        >
                                            <img
                                                src={`/images/booking/${(
                                                    element as BookingSlot
                                                ).room.toLowerCase()}.svg`}
                                                alt={
                                                    (element as BookingSlot)
                                                        .room
                                                }
                                            />
                                        </div>
                                        <ReactTooltip
                                            key={`tooltip-${
                                                (element as BookingSlot)
                                                    .activityName
                                            }`}
                                        />
                                    </React.Fragment>
                                ) : (
                                    (element as OrderDto).orderItems?.map(
                                        (elementItem) => (
                                            <React.Fragment
                                                key={elementItem.orderItemId}
                                            >
                                                <div
                                                    data-tip={
                                                        elementItem.menuItem
                                                            .title
                                                    }
                                                >
                                                    <FoodIcon
                                                        foodType={
                                                            elementItem.menuItem
                                                                .icon
                                                        }
                                                        color={elementItem.menuItem.icon.toLowerCase()}
                                                    />
                                                </div>
                                                <ReactTooltip
                                                    key={`tooltip-${elementItem.orderItemId}`}
                                                />
                                            </React.Fragment>
                                        )
                                    )
                                )
                        )}
                    </div>
                    {!booking && (
                        <div className='day-price'>
                            {getOrdersPrice(
                                elementsForToday(
                                    startDateIndex + index + 2
                                ) as unknown[] as OrderDto[]
                            )}
                            {' €'}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
