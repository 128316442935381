import { format } from 'date-fns';
import { useAppSelector } from '../../app/hooks';
import { selectDate } from '../../components/calendar/calendarSlice';
import { useEffect, useState } from 'react';
import { Statistics } from '../../api-types/models/Statistics';
import { useTranslation } from 'react-i18next';
import { FilterSelect } from '../../components/filterSelect';
import {
    MenuItemCategory,
    MenuItemGroup
} from '../../api-types/models/MenuItemDto';
import { Search } from '../../components/search';
import { getCategories, getGroups } from '../../api/menu';
import { Category } from '../../api-types/models/Category';
import { DateSelector } from '../../components/dateSelector';
import { getAllOrdersForDate } from '../../api/analytics';
import { StatisticsTable } from '../../components/statisticsTable';
import { joinOrdersStatistics } from '../../utils/joinOrdersStatistics';

import './index.css';
import { CustomEvent } from '@piwikpro/react-piwik-pro';

export function StatisticsPage() {
    const selectedDate = useAppSelector(selectDate);
    const { t } = useTranslation();

    const [statisticsForToday, setStatisticsForToday] = useState<Statistics[]>(
        []
    );
    const [allGroups, setAllGroups] = useState<string[]>([]);
    const [allCategories, setAllCategories] = useState<string[]>([]);
    const [activeGroup, setActiveGroup] = useState<MenuItemGroup | null>(null);
    const [activeCategory, setActiveCategory] =
        useState<MenuItemCategory | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        getGroups().then((response) => {
            if (!Object.keys(response).includes('error')) {
                const groups = (response as Category[]).map(
                    (group) => group.name
                );

                setAllGroups(groups);
            }
        });
        getCategories().then((response) => {
            if (!Object.keys(response).includes('error')) {
                const categories = (response as Category[]).map(
                    (category) => category.name
                );

                setAllCategories(categories);
            }
        });

        CustomEvent.trackEvent('page_view', 'Statistics');
    }, []);

    useEffect(() => {
        getAllOrdersForDate(format(selectedDate, 'yyyy-MM-dd')).then(
            (response) => {
                if (!Object.keys(response).includes('error')) {
                    setStatisticsForToday(response);
                }
            }
        );
    }, [selectedDate]);

    const filteredStatistics = statisticsForToday.filter(
        (item) =>
            (activeGroup ? item.menuItem.group === activeGroup : true) &&
            (activeCategory
                ? item.menuItem.category === activeCategory
                : true) &&
            item.menuItem.title.toLowerCase().includes(searchTerm)
    );

    // TODO: remove joining statistics once BE will implement proper calculations
    const statisticsArray = joinOrdersStatistics(filteredStatistics);

    return (
        <div className='statistics-page'>
            <div className='content'>
                <div className='filters'>
                    <div className='filters-group'>
                        <FilterSelect<MenuItemGroup>
                            label={t('groups')}
                            options={[
                                { label: t('all_groups') ?? '', value: null },
                                ...allGroups
                                    .filter(
                                        (group) =>
                                            group !== MenuItemGroup.USUAL_ITEMS
                                    )
                                    .map((type: string) => ({
                                        label: type,
                                        value: type
                                    }))
                            ]}
                            handleOnChange={setActiveGroup}
                            placeholder={t('all_groups')}
                        />
                        <FilterSelect
                            options={[
                                {
                                    label: t('all_categories') ?? '',
                                    value: null
                                },
                                ...allCategories.map((type: string) => ({
                                    label: type,
                                    value: type
                                }))
                            ]}
                            handleOnChange={setActiveCategory}
                            placeholder={t('all_categories')}
                            label={t('categories')}
                        />
                        <Search setSearch={setSearchTerm} />
                    </div>
                    <DateSelector />
                </div>
                <StatisticsTable statisticsArray={statisticsArray} />
            </div>
        </div>
    );
}
