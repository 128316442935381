import { Navigate } from 'react-router-dom';
import SideBar from '../sidebar';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { LoginType } from 'api-types/models/LoginDto';
import { useAppSelector } from 'app/hooks';
import { selectAuthUser } from 'app/authSlice';
import useAmplifyAuth from 'hooks/useAmplifyAuth';
import IRoute from 'interfaces/route';
import { getRoutesBasedOnUserGroups } from 'utils/getRoutesBasedOnUserGroups';
import { selectUserSettings } from 'app/userSlice';
import MenuItemsLoader from 'components/loading/menuLoader';
import { loginRequest } from 'authConfig';
import { renewSSOTokens } from 'utils/renewSSOTokens';

interface IProps {
    route: IRoute;
}

export function ProtectedRoute({ route }: Readonly<IProps>): JSX.Element {
    const loginType = localStorage.getItem('login');
    const { instance, accounts } = useMsal();

    useAmplifyAuth();

    const user = useAppSelector(selectAuthUser);
    const isAuthenticated = useIsAuthenticated();
    const userSettings = useAppSelector(selectUserSettings);

    if (
        (loginType === LoginType.SPORTRADAR && !isAuthenticated) ||
        (loginType === LoginType.BRUNCH && user === null) ||
        loginType === null ||
        (loginType !== LoginType.BRUNCH && route.brunchOnly === true)
    ) {
        return <Navigate to='/login' />;
    }

    // Check if token is expired and renew it
    if (
        accounts.length &&
        accounts[0].idTokenClaims?.exp &&
        accounts[0].idTokenClaims?.exp < Date.now() / 1000
    ) {
        renewSSOTokens(instance, accounts[0], loginRequest);
    }

    if (loginType !== LoginType.SPORTRADAR && !user) {
        return <MenuItemsLoader />;
    }

    const routes = getRoutesBasedOnUserGroups(user);

    if (
        !routes.includes(route.path) &&
        !(route.path === '/users' && userSettings?.officeManagement)
    ) {
        return <Navigate to={routes[0]} />;
    }

    return (
        <div className='app-content'>
            {route.sidebar !== false && <SideBar />}
            <route.component name={route.name} {...route.props} />
        </div>
    );
}
