import Modal from 'react-modal';
import { modalStyles } from '../../config/modal';
import { useTranslation } from 'react-i18next';

interface IProps {
    onConfirm: () => void;
    onCancel: () => void;
    content: JSX.Element;
}

export function ConfirmDeleteDialog({ onConfirm, onCancel, content }: IProps) {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={true}
            style={modalStyles}
            onRequestClose={onCancel}
            ariaHideApp={false}
        >
            <div className='delete-modal'>
                <div className='dialog-content'>{content}</div>
                <div className='actions'>
                    <button className='btn btn-gray' onClick={onCancel}>
                        {t('cancel')}
                    </button>
                    <button className='btn btn-gray' onClick={onConfirm}>
                        {t('confirm')}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
