import Select from 'react-select';

interface IProps<T> {
    label?: string;
    options: { label: string; value: string | null }[];
    handleOnChange: (value: T | null) => void;
    placeholder?: string;
}

export function FilterSelect<T>({
    options,
    label,
    handleOnChange,
    placeholder
}: IProps<T>) {
    return (
        <div className='input-group'>
            <label>{label}</label>
            <Select
                options={options}
                placeholder={placeholder}
                onChange={(newValue) =>
                    handleOnChange(
                        newValue ? (newValue.value as unknown as T) : null
                    )
                }
            ></Select>
        </div>
    );
}
