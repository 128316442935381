import { API } from 'aws-amplify';
import { handleError } from '../../utils/handleError';

export async function getAllOrdersForDate(date: string) {
    return API.get('brunch', `/analytics/orders/count/${date}`, {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}
