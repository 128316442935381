import { useTranslation } from 'react-i18next';
// import { allergens } from '../../enum';
import { UseFormReturn } from 'react-hook-form';
import { MenuItemDto } from '../../api-types';
import { useCallback, useEffect, useState } from 'react';
import { Category } from '../../api-types/models/Category';
import { getAllergens } from '../../api/menu';

import './index.css';

interface IProps {
    selectedAllergens: string[];
    form: UseFormReturn<MenuItemDto, unknown>;
    closeModal: () => void;
}

export function AllergeneModal({
    selectedAllergens,
    form,
    closeModal
}: IProps) {
    const { t } = useTranslation();

    const { setValue } = form;

    const [updatedAllergens, setUpdatedAllergens] = useState(selectedAllergens);
    const [allergens, setAllergens] = useState<Category[]>([]);

    const handleChange = useCallback(
        (value: boolean, allergen: string) => {
            setUpdatedAllergens(
                value
                    ? updatedAllergens.concat(allergen)
                    : updatedAllergens.filter((single) => single !== allergen)
            );
        },
        [updatedAllergens]
    );

    const handleSubmit = useCallback(() => {
        setValue('allergens', updatedAllergens);
        closeModal();
    }, [updatedAllergens]);

    useEffect(() => {
        getAllergens().then((response) => {
            if (!Object.keys(response).includes('error')) {
                setAllergens(response as Category[]);
            }
        });
    }, []);

    return (
        <div className='allergens-modal'>
            <h3>{t('select_allergens')}</h3>
            {allergens.map((allergen, index) => (
                <div className='allergene' key={allergen.index}>
                    <input
                        type='checkbox'
                        defaultChecked={selectedAllergens.includes(
                            allergen.name
                        )}
                        onChange={(event) =>
                            handleChange(event.target.checked, allergen.name)
                        }
                    />
                    {/* Fish already have different translation */}
                    {`${index} - ${
                        allergen.name !== 'FISH'
                            ? t(allergen.name.toLowerCase())
                            : t('fishAl')
                    }`}
                </div>
            ))}
            <button
                className='btn btn-blue save-allergens'
                onClick={handleSubmit}
            >
                {t('save_allergens')}
            </button>
        </div>
    );
}
