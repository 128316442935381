import './index.css';
import { useTranslation } from 'react-i18next';
import { UserDto } from 'api-types';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { LoginType } from 'api-types/models/LoginDto';
import { useAppDispatch } from 'app/hooks';
import { resetUserSettings } from 'app/userSlice';
import useAmplifyAuth from 'hooks/useAmplifyAuth';

interface IProps {
    user: UserDto;
}

export function Welcome({ user }: IProps): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const dispatch = useAppDispatch();
    const { handleSignout } = useAmplifyAuth();

    const handleLogout = useCallback(() => {
        if (localStorage.getItem('login') === LoginType.SPORTRADAR) {
            dispatch(resetUserSettings());
            instance
                .logoutPopup({
                    postLogoutRedirectUri: '/'
                })
                .then(() => navigate('/'));
        } else {
            handleSignout();
        }
    }, []);

    return (
        <div className='user-info box'>
            {t('hello')} {user.displayName}
            <div className='user-info-options'>
                {user.employeeCode}
                <div className='logout' onClick={handleLogout}>
                    {t('logout')}
                </div>
            </div>
        </div>
    );
}
