import { Statistics } from '../api-types/models/Statistics';
import { MenuItemDto } from '../api-types';

export function joinOrdersStatistics(filteredStatistics: Statistics[]) {
    const orderMap = new Map<
        string,
        { menuItem: MenuItemDto; count: number }
    >();

    filteredStatistics.map((order) => {
        const { uuid } = order.menuItem;

        if (orderMap.has(uuid)) {
            orderMap.set(uuid, {
                menuItem: order.menuItem,
                count:
                    (orderMap.get(uuid)?.count ?? 0) +
                    (order.numberOfOrders ?? order.count ?? 0)
            });
        } else {
            orderMap.set(uuid, {
                menuItem: order.menuItem,
                count: order.numberOfOrders ?? order.count ?? 0
            });
        }
    });

    return Array.from(orderMap.values()).sort((a, b) =>
        a.count < b.count ? 1 : -1
    );
}
