import { useTranslation } from 'react-i18next';
import {
    MenuItemCategory,
    MenuItemGroup,
    MenuItemType
} from '../../api-types/models/MenuItemDto';
import './index.css';
import { Switch } from '../switch';
import { FilterSelect } from '../filterSelect';
import { Search } from '../search';

interface IProps {
    setActiveCategory: (filter: MenuItemCategory | null) => void;
    handleAddOrRemoveActiveType: (filter: MenuItemType) => void;
    setActiveGroup: (filter: MenuItemGroup | null) => void;
    allGroups: string[];
    allCategories: string[];
    searchItems: (search: string) => void;
    activeTypes: MenuItemType[];
    showAll: boolean;
    setShowAll: (showAll: boolean) => void;
}

export function RegistryFilters({
    setActiveCategory,
    handleAddOrRemoveActiveType,
    setActiveGroup,
    searchItems,
    activeTypes,
    allGroups,
    allCategories,
    showAll,
    setShowAll
}: IProps) {
    const { t } = useTranslation();

    return (
        <div className='filters'>
            <div className='filters-group'>
                <FilterSelect<MenuItemGroup>
                    label={t('groups')}
                    options={[
                        { label: t('all_groups') ?? '', value: null },
                        ...allGroups
                            .filter(
                                (group) => group !== MenuItemGroup.USUAL_ITEMS
                            )
                            .map((type: string) => ({
                                label: type,
                                value: type
                            }))
                    ]}
                    handleOnChange={setActiveGroup}
                    placeholder={t('all_groups')}
                />
                <FilterSelect
                    options={[
                        { label: t('all_categories') ?? '', value: null },
                        ...allCategories.map((type: string) => ({
                            label: type,
                            value: type
                        }))
                    ]}
                    handleOnChange={setActiveCategory}
                    placeholder={t('all_categories')}
                    label={t('categories')}
                />
                <Search setSearch={searchItems} />
                <div className='show-all'>
                    <input
                        type='checkbox'
                        id='showAll'
                        name='showAll'
                        checked={showAll}
                        onChange={() => setShowAll(!showAll)}
                    />
                    <label>{t('show_inactive')}</label>
                </div>
            </div>

            <div className='menu-type-container input-group'>
                <label>{t('cost_center')}</label>
                <div className='filters-group'>
                    {Object.keys(MenuItemType).map((type) => (
                        <div className='menu-type-filter' key={type}>
                            <label>{type}</label>
                            <Switch
                                checked={activeTypes.includes(
                                    type as MenuItemType
                                )}
                                onChange={() =>
                                    handleAddOrRemoveActiveType(
                                        type as MenuItemType
                                    )
                                }
                                label={''}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
