import './index.css';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectWeekType, setWeekType } from '../../app/bookingSlice';
import { capitalizeFirstLetter } from '../../utils/capitalizeCase';
import { WeekType } from '../../api-types/models/Booking';
import { useTranslation } from 'react-i18next';

export function WeekTypeSelector() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const weekType = useAppSelector(selectWeekType);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const changeWeekType = (selectedWeekType: string) => {
        dispatch(setWeekType(selectedWeekType));
        setIsDropdownOpen(false);
    };

    return (
        <div className='week-type-selector'>
            {!isDropdownOpen ? (
                <div className='input' onClick={() => setIsDropdownOpen(true)}>
                    <div className='flex'>
                        <img
                            src='/images/booking/calendar.svg'
                            alt='calendar'
                        />
                        <p>{capitalizeFirstLetter(weekType)} week</p>
                    </div>
                    <img src='/images/booking/down.svg' alt='down' />
                </div>
            ) : (
                <div className='dropdown'>
                    {Object.values(WeekType).map((option) => (
                        <div
                            key={option}
                            className='input'
                            onClick={() => changeWeekType(option)}
                        >
                            <p>{`${capitalizeFirstLetter(option)} ${t(
                                'week'
                            )}`}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
