import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { isWeekend, nextMonday } from 'date-fns';

//move to interfaces
export interface SidebarState {
    selectedDate: Date;
}

const setInitialSelectedDate = (): Date => {
    const dateFromUrl = location.pathname.replace('/', '');
    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    const d = dateRegex.test(dateFromUrl) ? new Date(dateFromUrl) : new Date();
    return isWeekend(d) ? nextMonday(d) : d;
};

const initialState: SidebarState = {
    selectedDate: setInitialSelectedDate()
};

export const calendarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setDate: (state, action: PayloadAction<Date>) => {
            state.selectedDate = action.payload;
        }
    }
});

export const selectDate = (state: RootState): Date => {
    return state.sidebar.selectedDate;
};

export const { setDate } = calendarSlice.actions;
export default calendarSlice.reducer;
