import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { CognitoUser } from '../interfaces/cognitoUser';

export interface AuthState {
    isLoading: boolean;
    isError: boolean;
    user?: CognitoUser | null;
}

const initialState: AuthState = {
    isLoading: true,
    isError: false,
    user: undefined
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        fetchUserDataInit: (state) => {
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        },
        fetchUserDataSuccess: (state, action) => {
            return {
                ...state,
                isLoading: false,
                isError: false,
                user: action.payload
            };
        },
        fetchUserDataFailure: (state) => {
            return { ...state, isLoading: false, isError: true };
        },
        resetUserData: (state) => {
            return { ...state, user: null };
        }
    }
});

export const selectAuthUser = (
    state: RootState
): CognitoUser | null | undefined => state.auth.user;

export const {
    fetchUserDataInit,
    fetchUserDataSuccess,
    fetchUserDataFailure,
    resetUserData
} = authSlice.actions;
export default authSlice.reducer;
