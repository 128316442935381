import {
    createAsyncThunk,
    createSlice,
    SerializedError
} from '@reduxjs/toolkit';
import {
    addGuest,
    getUsers,
    getUserSettings,
    removeGuest,
    updateUser,
    updateUserSettings
} from '../api/userSettings';
import { RootState } from './store';
import { UserDto } from '../api-types';
import { CognitoUser } from '../interfaces/cognitoUser';
import { SportradarUser } from '../interfaces/sportradarUser';

export interface UserSettingState {
    userSettings?: UserDto;
    loading: boolean;
    error: boolean | SerializedError;
    isGuest: boolean;
    activeUser?: string;
    temporaryUser?: CognitoUser;
    users?: SportradarUser[];
    canUpdate: boolean;
}
const initialState: UserSettingState = {
    userSettings: undefined,
    loading: true,
    error: false,
    isGuest: false,
    activeUser: undefined,
    temporaryUser: undefined,
    users: undefined,
    canUpdate: false
};

export const getUserSettingsThunk = createAsyncThunk(
    'userSettings/getUserSettings',
    async () => getUserSettings()
);

export const updateUserSettingsThunk = createAsyncThunk(
    'userSettings/updateUserSettings',
    async (userSettings: UserDto) => updateUserSettings(userSettings)
);

export const getUsersThunk = createAsyncThunk(
    'userSettings/getUsers',
    async () => getUsers()
);

export const updateUserThunk = createAsyncThunk(
    'userSettings/updateUser',
    async (user: SportradarUser) => updateUser(user)
);

export const addGuestThunk = createAsyncThunk(
    'userSettings/addGuest',
    async (guest: string) => addGuest(guest)
);

export const removeGuestThunk = createAsyncThunk(
    'userSettings/removeGuest',
    async (guest: string) => removeGuest(guest)
);

export const userSettingsSlice = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {
        changeIsGuest(state, action) {
            return {
                ...state,
                isGuest: action.payload
            };
        },
        setActiveUser(state, action) {
            return {
                ...state,
                activeUser: action.payload
            };
        },
        setTemporaryUser(state, action) {
            return {
                ...state,
                temporaryUser: action.payload
            };
        },
        setCanUpdate(state, action) {
            return {
                ...state,
                canUpdate: action.payload
            };
        },
        setUserSettings(state, action) {
            return {
                ...state,
                userSettings: action.payload
            };
        },
        saveFoodFilter(state, action) {
            return {
                ...state,
                userSettings: {
                    ...(state.userSettings as UserDto),
                    foodFilter: action.payload
                }
            };
        },
        saveAllergies(state, action) {
            return {
                ...state,
                userSettings: {
                    ...(state.userSettings as UserDto),
                    allergies: action.payload
                }
            };
        },
        resetUserSettings(state) {
            return {
                ...state,
                userSettings: undefined
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserSettingsThunk.fulfilled, (state, action) => {
            state.userSettings = action.payload;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(getUserSettingsThunk.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(getUserSettingsThunk.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
        builder.addCase(updateUserSettingsThunk.fulfilled, (state, action) => {
            state.userSettings = action.payload;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(updateUserSettingsThunk.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(updateUserSettingsThunk.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
        builder.addCase(getUsersThunk.fulfilled, (state, action) => {
            state.users = action.payload;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(getUsersThunk.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(getUsersThunk.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
        builder.addCase(updateUserThunk.fulfilled, (state, action) => {
            const index = state.users?.findIndex(
                (user) => user.email === action.payload.email
            );
            if (state.users && index !== undefined && index !== -1) {
                state.users[index] = action.payload;
            }
            state.loading = false;
            state.error = false;
        });
        builder.addCase(updateUserThunk.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(updateUserThunk.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
        builder.addCase(addGuestThunk.fulfilled, (state, action) => {
            state.userSettings = action.payload;
        });
        builder.addCase(removeGuestThunk.fulfilled, (state, action) => {
            state.userSettings = action.payload;
        });
    }
});

export const selectUserSettings = (state: RootState): UserDto | undefined => {
    return state.userSettings.userSettings;
};

export const selectIsGuest = (state: RootState): boolean => {
    return state.userSettings.isGuest;
};

export const selectActiveUser = (state: RootState) => {
    return state.userSettings.activeUser;
};

export const selectTemporaryUser = (state: RootState) => {
    return state.userSettings.temporaryUser;
};

export const selectUsers = (state: RootState) => {
    return state.userSettings.users;
};

export const selectCanUpdate = (state: RootState) => {
    return state.userSettings.canUpdate;
};

export const {
    changeIsGuest,
    setActiveUser,
    setTemporaryUser,
    setCanUpdate,
    setUserSettings,
    saveFoodFilter,
    saveAllergies,
    resetUserSettings
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
