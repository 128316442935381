import './index.css';
import { useTranslation } from 'react-i18next';
import { MenuItemDto } from '../../api-types';
import { MenuItemPrice } from '../menuItemPrice';
import { FoodIcon } from '../foodIcon';

interface IProps {
    menuItem: MenuItemDto;
    setIsModalOpen(value: boolean): void;
}

export function MenuItemPopup({
    menuItem,
    setIsModalOpen
}: IProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className='menu-description'>
            <div className='menu-item-main'>
                <FoodIcon
                    foodType={menuItem.category ?? menuItem.group}
                    color={(menuItem.category ?? menuItem.group).toLowerCase()}
                />
                <div className='menu-item-name'>{menuItem.title}</div>
            </div>
            <div className='menu-item-desc'>{menuItem.description}</div>
            <MenuItemPrice menuItem={menuItem} />
            {menuItem.ingredients.length > 0 && (
                <div className='menu-item-desc'>
                    {t('ingredients')}:
                    <strong> {menuItem.ingredients.join(', ')}</strong>
                </div>
            )}
            {menuItem.variants.length > 0 && (
                <div className='menu-item-desc'>
                    {t('variants')}:
                    <strong> {menuItem.variants.join(', ')}</strong>
                </div>
            )}
            {menuItem.allergens.length > 0 && (
                <>
                    <hr className='first' />
                    <div className='allergens'>
                        {t('allergens_title')}
                        <div className='items'>
                            {menuItem.allergens.map((allergen: string) => (
                                <div
                                    className='allergen'
                                    key={'allergen_' + allergen}
                                >
                                    {t(allergen.toLowerCase())}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
            <div className='close' onClick={() => setIsModalOpen(false)}>
                <img alt='close' src='/images/ico_close.svg' />
            </div>
        </div>
    );
}
