import { ReactComponent as Unknown } from '../../assets/food/ico_unknown.svg';
import { ReactComponent as Meat } from '../../assets/food/ico_meat.svg';
import { ReactComponent as Burger } from '../../assets/food/ico_burger.svg';
import { ReactComponent as Soup } from '../../assets/food/ico_soup.svg';
import { ReactComponent as Salad } from '../../assets/food/ico_salad.svg';
import { ReactComponent as Lowcarb } from '../../assets/food/ico_low_carb.svg';
import { ReactComponent as Breakfast } from '../../assets/food/ico_breakfast.svg';
import { ReactComponent as Fish } from '../../assets/food/ico_fish.svg';
import { ReactComponent as Vegi } from '../../assets/food/ico_vege.svg';
import { ReactComponent as IceCream } from '../../assets/food/ico_ice_cream.svg';
import { ReactComponent as Beer } from '../../assets/food/ico_beer.svg';
import { ReactComponent as Coffe } from '../../assets/food/ico_coffee.svg';
import { ReactComponent as Deserts } from '../../assets/food/ico_deserts.svg';
import { ReactComponent as SoftDrinks } from '../../assets/food/ico_soft_drinks.svg';
import { ReactComponent as Spirits } from '../../assets/food/ico_spirits.svg';
import { ReactComponent as Smoothie } from '../../assets/food/ico_smoothie.svg';
import { ReactComponent as Sandwich } from '../../assets/food/ico_sandwich.svg';
import { ReactComponent as HotDrinks } from '../../assets/food/ico_hot_drinks.svg';
import { ReactComponent as Wine } from '../../assets/food/ico_wine.svg';

import './index.css';

interface IProps {
    foodType: string;
    color?: string;
}

export function FoodIcon({ foodType, color }: IProps) {
    const getIcon = (foodType: string) => {
        switch (foodType.toLowerCase()) {
            case 'meat':
                return <Meat />;
            case 'burger':
                return <Burger />;
            case 'soup':
                return <Soup />;
            case 'salad':
                return <Salad />;
            case 'lowcarb':
                return <Lowcarb />;
            case 'breakfast':
                return <Breakfast />;
            case 'fish':
                return <Fish />;
            case 'vegi':
                return <Vegi />;
            case 'ice_cream':
                return <IceCream />;
            case 'beer':
                return <Beer />;
            case 'coffee':
                return <Coffe />;
            case 'dessert':
                return <Deserts />;
            case 'soft':
                return <SoftDrinks />;
            case 'spirits':
                return <Spirits />;
            case 'smoothies':
                return <Smoothie />;
            case 'sandwich':
                return <Sandwich />;
            case 'hot_beverages':
                return <HotDrinks />;
            case 'wine':
                return <Wine />;
            default:
                return <Unknown />;
        }
    };

    return (
        <div className={`food-icon ${color ?? ''}`}>{getIcon(foodType)}</div>
    );
}
