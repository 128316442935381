import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { switchProps } from '../../config/config';
import { AllergenFilter } from '../../../../api-types/models/UserDto';
import { useCallback } from 'react';
import { updateAllergies } from '../../../../api/userSettings';
import { useAppDispatch } from '../../../../app/hooks';
import { saveAllergies } from '../../../../app/userSlice';

interface IProps {
    allergies: AllergenFilter;
}

export function Allergies({ allergies }: IProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleUpdateAllergies = useCallback(
        (allergie: string, value: boolean) => {
            updateAllergies(allergie, value).then(() =>
                dispatch(
                    saveAllergies({
                        ...allergies,
                        [allergie]: value
                    })
                )
            );
        },
        [allergies]
    );

    return (
        <div className='alergies-options box'>
            <div className='options-title'>{t('allergies')}</div>
            <div className='options-mutable options-text'>
                {t('allergies_text')}
            </div>
            {allergies &&
                Object.keys(allergies).map((allergen) => {
                    return (
                        <div className='options-text-switch' key={allergen}>
                            {/* Fish already have different translation */}
                            {allergen !== 'FISH'
                                ? t(allergen.toLowerCase())
                                : t('fishAl')}
                            <Switch
                                checked={allergies[allergen] === true}
                                onChange={(value: boolean) =>
                                    handleUpdateAllergies(allergen, value)
                                }
                                {...switchProps}
                            />
                        </div>
                    );
                })}
        </div>
    );
}
