export type LoginDto = {
    token?: string;
    representative?: boolean;
};

export enum LoginType {
    SPORTRADAR = 'Sportradar',
    BRUNCH = 'Brunch',
    COACH = 'Coaches',
    MASSAGE = 'Massage',
    PHYSIO = 'Physio',
    TENANTS = 'Tenants'
}

export interface ManualLoginDto {
    email: string;
    password: string;
}
