import { BookingSlot } from '../api-types/models/Booking';
import { addMinutes, getMinutes } from 'date-fns';

export function calculateTopMargin(
    slot: BookingSlot,
    cellHeight: number
): number {
    return (getMinutes(slot.from) * cellHeight) / 60;
}

export function calculateBottomMargin(
    slot: BookingSlot,
    cellHeight: number
): number {
    if (getMinutes(addMinutes(slot.from, slot.duration)) === 0) {
        return 0;
    }

    const differenceToFullHour =
        60 - getMinutes(addMinutes(slot.from, slot.duration));

    return (Math.abs(differenceToFullHour) * cellHeight) / 60;
}
