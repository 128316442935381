import { API } from 'aws-amplify';
import {
    OrderDto,
    MenuItemDto,
    OrderRequest,
    OrderCommentRequestBody
} from '../../api-types';
import formatDate from '../../utils/formatDate';

export function getOrders(date: Date): Promise<OrderDto[]> {
    return API.get('brunch-v2', `/order/user/${formatDate(date)}`, {});
}

export function getOrdersForThisMonth(
    month: number,
    year: number
): Promise<OrderDto[]> {
    return API.get('brunch-v2', `/order/user/${month + 1}/${year}`, {});
}

export function createOrder(
    date: string,
    menuItem: MenuItemDto
): Promise<OrderDto[]> {
    const requestBody: OrderRequest = {
        date: date,
        menuItemUuid: menuItem.uuid,
        menuItemId: menuItem.id,
        comment: ''
    };
    return API.post('brunch-v2', `/order/place`, {
        body: requestBody
    });
}

export function cancelOrder(
    orderItem: OrderDto,
    date: string
): Promise<OrderDto[]> {
    return API.put('brunch-v2', `/order/${date}/${orderItem.id}/cancel`, {});
}

export function commentOrder(
    comment: string,
    orderItem: OrderDto
): Promise<OrderDto[]> {
    const requestBody: OrderCommentRequestBody = {
        date: orderItem.date,
        key: orderItem.id,
        comment
    };
    return API.put('brunch', '/orders/comment', {
        body: requestBody
    });
}

export function getOrdersToRate(): Promise<OrderDto[]> {
    return API.get('brunch', '/orders/user/reviews', {});
}
