import { Path, UseFormReturn } from 'react-hook-form';

interface IProps<T> {
    value: string | number;
    label: string;
    form: UseFormReturn<T, unknown>;
    name: keyof T;
    required?: boolean;
    number?: boolean;
    select?: boolean;
    options?: { value?: string; label: string }[];
    disabled?: boolean;
    step?: number;
}

export function InputField<T>({
    value,
    label,
    form,
    name,
    required = false,
    number = false,
    select = false,
    options,
    disabled = false,
    step = 0.1
}: IProps<T>) {
    const { register } = form;

    return (
        <div className='input-group'>
            <label>
                {label}
                {required ? '*' : ''}
            </label>
            {select ? (
                <select
                    className='input-form'
                    defaultValue={value}
                    disabled={disabled}
                    {...register(name as unknown as Path<T>, { required })}
                >
                    {options?.map((option) => (
                        <option key={option.label} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            ) : (
                <input
                    className='input-form'
                    defaultValue={value}
                    type={number ? 'number' : 'text'}
                    step={number ? step : 'any'}
                    min={number ? 0 : undefined}
                    disabled={disabled}
                    {...register(name as unknown as Path<T>, { required })}
                />
            )}
        </div>
    );
}
