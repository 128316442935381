import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { modalStyles } from '../../config/modal';
import { AllergeneModal } from '../AllergeneModal';
import { UseFormReturn } from 'react-hook-form';
import { MenuItemDto } from '../../api-types';

import './index.css';

interface IProps {
    form: UseFormReturn<MenuItemDto, unknown>;
}
export function EditAllergens({ form }: IProps) {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { getValues, setValue } = form;

    const allergens = getValues('allergens') ?? [];

    useEffect(() => {
        setValue('allergens', allergens);
    }, []);

    return (
        <>
            <div className='input-row'>
                <div className='input-group'>
                    <label>{t('allergens_title')}</label>
                    <div className='row'>
                        <div className='input-form allergens-wrapper'>
                            {allergens
                                .map((allergen) => t(allergen.toLowerCase()))
                                .join(', ')}
                        </div>
                        <button
                            className='btn btn-white add-allergen'
                            type='button'
                            onClick={() => setIsModalOpen(true)}
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    style={modalStyles}
                    onRequestClose={() => setIsModalOpen(false)}
                    ariaHideApp={false}
                >
                    <AllergeneModal
                        selectedAllergens={allergens}
                        form={form}
                        closeModal={() => setIsModalOpen(false)}
                    />
                </Modal>
            )}
        </>
    );
}
