import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import calendarReducer from '../components/calendar/calendarSlice';
import userSettingsReducer from './userSlice';
import configReducer from './configSlice';
import menuReducer from './menuSlice';
import registryReducer from './registrySlice';
import bookingReducer from './bookingSlice';
import commonReducer from './commonSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        sidebar: calendarReducer,
        userSettings: userSettingsReducer,
        config: configReducer,
        menu: menuReducer,
        registry: registryReducer,
        booking: bookingReducer,
        common: commonReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false })
    // preloadedState: {
    //   login: {
    //     authenticated: !!localStorage.getItem("user"),
    //     user: localStorage.getItem("user")
    //   }
    // }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
