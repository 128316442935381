interface IProps {
    title: string;
    value: number;
}

export function StatsItem({ title, value }: IProps) {
    return (
        <div className='stats-column'>
            <img src={`/images/ico_${title}.svg`} alt='stats-image' />
            {title.toUpperCase()}
            <h4>{value}</h4>
        </div>
    );
}
