import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { MenuItemDto } from '../api-types';
import { RootState } from './store';
import { MenuItemType } from '../api-types/models/MenuItemDto';

export interface MenuState {
    menuItems?: MenuItemDto[];
    loading: boolean;
    error: boolean | SerializedError;
    menuType: MenuItemType;
    editItem?: MenuItemDto;
    preparedMenuItems: { [key: string]: MenuItemDto[] };
    preparedConstantMenuItems: MenuItemDto[];
}

const initialState: MenuState = {
    menuItems: undefined,
    error: false,
    loading: false,
    menuType: MenuItemType.KITCHEN,
    preparedMenuItems: {},
    preparedConstantMenuItems: []
};
export const menuItemsSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        saveMenuItems(state, action) {
            return {
                ...state,
                menuItems: action.payload
            };
        },
        removeMenuItem(state, action) {
            return {
                ...state,
                menuItems: state.menuItems?.filter(
                    (menuItem) => menuItem.uuid !== action.payload.uuid
                )
            };
        },
        changeMenuType(state, action) {
            return {
                ...state,
                menuType: action.payload
            };
        },
        setError(state, action) {
            return {
                ...state,
                error: action.payload
            };
        },
        updateMenuItem(state, action) {
            return {
                ...state,
                menuItems: state.menuItems?.map((menuItem) =>
                    menuItem.uuid !== action.payload.uuid
                        ? menuItem
                        : action.payload
                ),
                error: false
            };
        },
        setEditItem(state, action) {
            return {
                ...state,
                editItem: action.payload
            };
        },
        addPreparedMenuItem(state, action) {
            const { menuItem } = action.payload;

            return {
                ...state,
                preparedMenuItems: {
                    ...state.preparedMenuItems,
                    [action.payload.date]: Object.keys(
                        state.preparedMenuItems
                    ).includes(action.payload.date)
                        ? [
                              ...state.preparedMenuItems[action.payload.date],
                              menuItem
                          ]
                        : [menuItem]
                }
            };
        },
        addPreparedConstantMenuItems(state, action) {
            const { menuItems } = action.payload;

            return {
                ...state,
                preparedConstantMenuItems: [
                    ...state.preparedConstantMenuItems,
                    ...menuItems
                ]
            };
        },
        removePreparedMenuItems(state) {
            return {
                ...state,
                preparedMenuItems: {}
            };
        },
        removePreparedConstantMenuItems(state) {
            return {
                ...state,
                preparedConstantMenuItems: []
            };
        },
        addMenuItemsForToday(state, action) {
            return {
                ...state,
                menuItems: state.menuItems?.concat(action.payload)
            };
        }
    }
});

export const selectMenuItems = (
    state: RootState
): MenuItemDto[] | undefined => {
    return state.menu.menuItems;
};

export const selectMenuType = (state: RootState): MenuItemType =>
    state.menu.menuType;

export const selectError = (state: RootState): SerializedError | boolean =>
    state.menu.error;

export const selectEditItem = (state: RootState): MenuItemDto | undefined =>
    state.menu.editItem;

export const selectPreparedMenuItems = (
    state: RootState
): { [key: string]: MenuItemDto[] } => state.menu.preparedMenuItems;

export const selectPreparedConstantMenuItems = (state: RootState) =>
    state.menu.preparedConstantMenuItems;

export const {
    removeMenuItem,
    saveMenuItems,
    changeMenuType,
    setError,
    updateMenuItem,
    setEditItem,
    addPreparedMenuItem,
    removePreparedMenuItems,
    addMenuItemsForToday,
    addPreparedConstantMenuItems,
    removePreparedConstantMenuItems
} = menuItemsSlice.actions;

export default menuItemsSlice.reducer;
