import { useTranslation } from 'react-i18next';

import './index.css';

interface IProps {
    label: string;
    variable: string;
    closeModal: () => void;
    handleDelete: () => void;
}

export function DeleteModal({
    label,
    variable,
    closeModal,
    handleDelete
}: IProps) {
    const { t } = useTranslation();

    return (
        <div className='delete-modal'>
            <b>
                {t(label)} {variable && `- ${variable}`}
            </b>
            <hr />
            <div className='actions'>
                <button className='btn btn-gray' onClick={closeModal}>
                    {t('cancel')}
                </button>
                <button className='btn btn-blue' onClick={handleDelete}>
                    {t('confirm')}
                </button>
            </div>
        </div>
    );
}
