import './index.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectDate, setDate } from '../calendar/calendarSlice';
import { addDays, format } from 'date-fns';
import { getLocale } from 'utils/getLocale';

export function DateSelector() {
    const dispatch = useAppDispatch();

    const selectedDate = useAppSelector(selectDate);

    const handleChangeDate = (date: Date) => dispatch(setDate(date));

    return (
        <div className='date-selector'>
            <img
                src='/images/ico_previous.svg'
                alt='prev'
                onClick={() =>
                    handleChangeDate(
                        addDays(
                            selectedDate,
                            selectedDate.getDay() === 1 ? -3 : -1
                        )
                    )
                }
            />
            <h3>{format(selectedDate, 'MMMM do', { locale: getLocale() })}</h3>
            <img
                src='/images/ico_next.svg'
                alt='next'
                onClick={() =>
                    handleChangeDate(
                        addDays(
                            selectedDate,
                            selectedDate.getDay() === 5 ? 3 : 1
                        )
                    )
                }
            />
        </div>
    );
}
