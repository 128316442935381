import { capitalizeFirstLetter } from '../../utils/capitalizeCase';

interface IProps {
    room: string;
    setValue: (room: string) => void;
    active: boolean;
}

export function Room({ room, setValue, active }: IProps) {
    return (
        <div
            className={`room ${active && 'active'}`}
            onClick={() => setValue(room)}
        >
            <img src={`/images/booking/${room.toLowerCase()}.svg`} alt={room} />
            <p>{capitalizeFirstLetter(room.toLowerCase())}</p>
        </div>
    );
}
