import './index.css';
import { BookingSlot, UpdateSlotAction } from '../../api-types/models/Booking';
import { bookSlot, removeSlot as deleteSlot } from '../../api/booking';
import { useState } from 'react';
import { Modal } from '../modal';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import { setError } from '../../app/commonSlice';
import { CustomEvent } from '@piwikpro/react-piwik-pro';

interface IProps {
    slot: BookingSlot;
    booked: boolean;
    disabled: boolean;
    updateSlot: (slot: BookingSlot, action: UpdateSlotAction) => void;
    removeSlot: (slot: BookingSlot) => void;
}

export function BookSlotButton({
    slot,
    booked,
    disabled,
    updateSlot,
    removeSlot
}: Readonly<IProps>) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [isConfirmRemoveSlotModalOpen, setIsConfirmRemoveSlotModalOpen] =
        useState(false);
    const [buttonHover, setButtonHover] = useState(false);

    const handleBookSlot = () => {
        bookSlot(slot).then((response) => {
            if (!response['error']) {
                updateSlot(response, UpdateSlotAction.ATTENDEE);
                CustomEvent.trackEvent('create_booking', slot.activityName);
            } else {
                dispatch(setError(response.error));
            }
        });
    };

    const handleRemoveSlot = () => {
        const removeSlotAfter = (response: object) => {
            if (!response['error']) {
                removeSlot(slot);
                setIsConfirmRemoveSlotModalOpen(false);
                CustomEvent.trackEvent('remove_booking', slot.activityName);
            } else {
                dispatch(setError(response['error']));
            }
        };

        deleteSlot(slot).then(removeSlotAfter);
    };

    const getButtonText = () => {
        if (booked && buttonHover) {
            return t('remove');
        }

        if (booked) {
            return t('joined');
        }

        return t('join');
    };

    return (
        <>
            <button
                className={`btn ${booked ? 'btn-green' : 'btn-blue'} ${
                    booked && buttonHover ? 'btn-danger' : ''
                }`}
                disabled={disabled}
                onMouseEnter={() => setButtonHover(true)}
                onMouseLeave={() => setButtonHover(false)}
                onClick={
                    booked
                        ? () => setIsConfirmRemoveSlotModalOpen(true)
                        : handleBookSlot
                }
            >
                {getButtonText()}
            </button>
            {isConfirmRemoveSlotModalOpen && (
                <Modal
                    title='Confirm slot removal'
                    actionButtons={
                        <>
                            <button
                                className='btn btn-blue'
                                onClick={handleRemoveSlot}
                            >
                                {t('confirm')}
                            </button>
                            <button
                                className='btn btn-grey'
                                onClick={() =>
                                    setIsConfirmRemoveSlotModalOpen(false)
                                }
                            >
                                {t('cancel')}
                            </button>
                        </>
                    }
                    closeModal={() => setIsConfirmRemoveSlotModalOpen(false)}
                    content={<p>{t('confirm_removal_slot')}</p>}
                    className='slot-removal'
                />
            )}
        </>
    );
}
