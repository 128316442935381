export const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        background: 'white',
        boxShadow: '0px 4px 16px 0px #000000E5'
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.5)'
    }
};

export const extendedModalStyles = {
    ...modalStyles,
    content: {
        ...modalStyles.content,
        minWidth: '480px',
        maxWidth: '50vw',
        minHeight: '20vh',
        maxHeight: '80vh',
        borderRadius: '6px',
        position: 'relative' as const,
        display: 'flex',
        flexDirection: 'column' as const
    },
    overlay: {
        ...modalStyles.overlay
    }
};
