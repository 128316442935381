import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { getUserInitials } from 'utils/getUserInitials';

import './index.css';

interface IProps {
    attendees: string[];
}

export function BookingAttendees({ attendees }: Readonly<IProps>) {
    const { t } = useTranslation();

    return (
        <div className='input-group attendees'>
            <label>{t('attendees')} </label>
            <div className='attendees-wrapper'>
                {attendees.map((attendee) => (
                    <div key={attendee}>
                        <div className='attendee' data-tip={attendee}>
                            {getUserInitials(attendee)}
                        </div>
                        <ReactTooltip key={`tooltip-${attendee}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}
