import './index.css';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    addMenuItemsForToday,
    removePreparedConstantMenuItems,
    removePreparedMenuItems,
    selectPreparedConstantMenuItems,
    selectPreparedMenuItems
} from '../../app/menuSlice';
import { publishMenuItems } from '../../api/menu';
import { selectDate } from '../calendar/calendarSlice';
import { MenuItemDto } from '../../api-types';
import { format } from 'date-fns';
import { modalStyles } from '../../config/modal';
import Modal from 'react-modal';
import { SummaryForPreparedMenuItems } from '../summaryForPreparedMenuItems';

interface IProps {
    cancelEditMode: () => void;
}

export function EditModeWarning({ cancelEditMode }: IProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] =
        useState(false);

    const preparedMenuItems = useAppSelector(selectPreparedMenuItems);
    const preparedConstantMenuItems = useAppSelector(
        selectPreparedConstantMenuItems
    );
    const selectedDate = useAppSelector(selectDate);

    const handleCancelEditMode = useCallback(() => {
        const confirmation = window.confirm(t('confirm_exit'));
        if (confirmation) {
            cancelEditMode();
        }
    }, []);

    const handleSubmitPreparedMenuItems = useCallback(() => {
        Object.keys(preparedMenuItems).map((date) => {
            publishMenuItems(
                preparedMenuItems[date].map((item) => item.uuid),
                date
            ).then((response) => {
                if (Object.keys(response).includes('error')) {
                    console.log(response['error']);
                } else {
                    dispatch(removePreparedMenuItems());
                    dispatch(
                        addMenuItemsForToday(
                            (response as MenuItemDto[]).filter(
                                (item) =>
                                    item.id ===
                                    format(selectedDate, 'yyyy-MM-dd')
                            )
                        )
                    );
                }
            });
        });
        if (preparedConstantMenuItems.length > 0) {
            publishMenuItems(
                preparedConstantMenuItems.map((menuItem) => menuItem.uuid)
            ).then((response) => {
                if (Object.keys(response).includes('error')) {
                    console.log(response['error']);
                } else {
                    dispatch(removePreparedConstantMenuItems());
                    dispatch(addMenuItemsForToday(response));
                }
            });
        }
    }, [preparedMenuItems, selectedDate, preparedConstantMenuItems]);

    return (
        <div className='edit-mode-warning'>
            {t('edit_mode_warning')}
            <div className='actions'>
                <button
                    className='btn btn-white'
                    onClick={handleCancelEditMode}
                >
                    {t('cancel')}
                </button>
                <button
                    className='btn btn-blue'
                    onClick={() => setIsConfirmSubmitModalOpen(true)}
                >
                    {t('confirm')}
                </button>
            </div>
            {isConfirmSubmitModalOpen && (
                <Modal
                    isOpen={isConfirmSubmitModalOpen}
                    style={modalStyles}
                    onRequestClose={() => setIsConfirmSubmitModalOpen(false)}
                    ariaHideApp={false}
                >
                    <SummaryForPreparedMenuItems
                        handleSubmitPreparedMenuItems={
                            handleSubmitPreparedMenuItems
                        }
                        closeModal={() => setIsConfirmSubmitModalOpen(false)}
                    />
                </Modal>
            )}
        </div>
    );
}
