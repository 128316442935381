import './index.css';
import { endOfMonth, getWeeksInMonth } from 'date-fns';
import { startOfMonth } from 'date-fns/esm';
import times from 'lodash/times';
import { useTranslation } from 'react-i18next';
import { Week } from '../week';

interface IProps<T> {
    activeMonthIndex?: number;
    activeYear: number;
    elementsForThisMonth?: T[];
    booking?: boolean;
}

export function Weeks<T>({
    activeMonthIndex,
    activeYear,
    elementsForThisMonth,
    booking = false
}: IProps<T>): JSX.Element {
    const { t } = useTranslation();

    if (activeMonthIndex === undefined) {
        return <></>;
    }

    const activeMonthDate = new Date(activeYear, activeMonthIndex, 1);

    const startDayOfTheMonth = startOfMonth(activeMonthDate).getDay();
    const endDateOfTheMonth = endOfMonth(activeMonthDate).getDate();

    // If month starts with Saturday on Sunday decrease number of weeks
    const numberOfWeeksInMonth =
        startDayOfTheMonth <= 5
            ? getWeeksInMonth(activeMonthDate)
            : getWeeksInMonth(activeMonthDate) - 1;

    if (
        elementsForThisMonth === undefined ||
        elementsForThisMonth.length === 0
    ) {
        return (
            <div className='no-orders'>
                {t(booking ? 'no_activities_month' : 'no_orders_month')}
            </div>
        );
    }
    return (
        <>
            {times(numberOfWeeksInMonth, (index: number) => {
                return (
                    <Week<T>
                        key={'week' + index}
                        weekIndex={index}
                        numberOfWeeks={numberOfWeeksInMonth}
                        startDayIndex={startDayOfTheMonth}
                        startDateIndex={
                            startDayOfTheMonth < 6
                                ? index * 7 - startDayOfTheMonth
                                : (index + 1) * 7 - startDayOfTheMonth
                        }
                        endDate={endDateOfTheMonth}
                        elementsForThisMonth={elementsForThisMonth}
                        booking={booking}
                    />
                );
            })}
        </>
    );
}
