import './index.css';
import { WeekSelector } from '../weekSelector';
import { BookingTable } from '../bookingTable';
import { AddBookingSlotModal } from '../addBookingSlotModal';
import { useEffect, useState } from 'react';
import { BookingActivity, BookingSlot } from '../../api-types/models/Booking';
import { format, startOfWeek } from 'date-fns';
import { RoomSelector } from '../roomSelector';
import { getBookingActivities, getSlotsForWeek } from '../../api/booking';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectRoom, setRoom } from '../../app/bookingSlice';

interface IProps {
    rooms: string[];
}

export function BookingCalendar({ rooms }: IProps) {
    const dispatch = useAppDispatch();
    const selectedRoom = useAppSelector(selectRoom);

    const [selectedDate, setSelectedDate] = useState<Date>();
    const [startOfWeekDate, setStartOfWeekDate] = useState(
        startOfWeek(new Date(), { weekStartsOn: 1 })
    );
    const [slotsPerWeek, setSlotsPerWeek] = useState<BookingSlot[]>([]);
    const [activities, setActivities] = useState<BookingActivity[]>([]);
    const [selectedSlot, setSelectedSlot] = useState<BookingSlot>();

    const addOrUpdateSlot = (
        slot: BookingSlot | BookingSlot[],
        update: boolean
    ) => {
        if (update && !Array.isArray(slot)) {
            setSlotsPerWeek(
                slotsPerWeek.map((oldSlot) =>
                    oldSlot.slotId === slot.slotId ? slot : oldSlot
                )
            );
        } else {
            setSlotsPerWeek(slotsPerWeek.concat(slot));
        }
        setSelectedDate(undefined);
        setSelectedSlot(undefined);
    };

    const deleteSlot = (slot: BookingSlot) => {
        setSlotsPerWeek(
            slotsPerWeek.filter((oldSlot) => oldSlot.slotId !== slot.slotId)
        );
        setSelectedDate(undefined);
        setSelectedSlot(undefined);
    };
    useEffect(() => {
        getSlotsForWeek(
            format(startOfWeekDate ?? new Date(), 'yyyy-MM-dd')
        ).then((response) => {
            if (!response.error) {
                setSlotsPerWeek(response);
            }
        });
    }, [startOfWeekDate]);

    useEffect(() => {
        if (selectedRoom) {
            getBookingActivities(selectedRoom).then((response) => {
                if (!response.error) {
                    setActivities(response);
                }
            });
        }
    }, [selectedRoom]);

    return (
        <>
            <div className='booking-calendar-header'>
                <WeekSelector
                    startOfWeekDate={startOfWeekDate}
                    setStartOfWeekDate={setStartOfWeekDate}
                />
                <RoomSelector
                    rooms={rooms}
                    selectedRoom={selectedRoom}
                    setValue={(room) => dispatch(setRoom(room))}
                />
            </div>
            <BookingTable
                startOfWeekDate={startOfWeekDate}
                setSelectedDate={setSelectedDate}
                slotsPerWeek={slotsPerWeek.filter(
                    (slot) => slot.room === selectedRoom
                )}
                setSelectedSlot={(slot) => {
                    setSelectedSlot(slot);
                    setSelectedDate(new Date(slot.from));
                }}
            />
            {(selectedDate || selectedSlot) && (
                <AddBookingSlotModal
                    date={selectedDate}
                    setDate={setSelectedDate}
                    closeModal={() => {
                        setSelectedDate(undefined);
                        setSelectedSlot(undefined);
                    }}
                    rooms={rooms}
                    selectedRoom={selectedRoom}
                    activities={activities}
                    addOrUpdateSlot={addOrUpdateSlot}
                    slot={selectedSlot}
                    deleteSlot={deleteSlot}
                />
            )}
        </>
    );
}
