import { MenuItemDto } from '../../api-types';

interface IProps {
    menuItem: MenuItemDto;
}

export function MenuItemPrice({ menuItem }: IProps) {
    return (
        <div className='menu-item-rating-price'>
            {menuItem.subsidizedPrice &&
            menuItem.subsidizedPrice !== menuItem.fullPrice ? (
                <div className='menu-item-price'>
                    {menuItem.subsidizedPrice.toFixed(2)} €
                </div>
            ) : (
                <></>
            )}
            <div
                className={`menu-item-price ${
                    menuItem.subsidizedPrice &&
                    menuItem.subsidizedPrice !== menuItem.fullPrice
                        ? 'line-through'
                        : ''
                }`}
            >
                {menuItem.fullPrice.toFixed(2)} €
            </div>
        </div>
    );
}
