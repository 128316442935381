import { API } from 'aws-amplify';
import {
    BookingActivity,
    BookingSlot,
    BookingSlotRequest
} from '../../api-types/models/Booking';
import { handleError } from '../../utils/handleError';

export async function getBookingActivities(room: string) {
    return API.get('brunch', `/activities/${room.toUpperCase()}/list`, {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function addBookingActivity(Activity: BookingActivity) {
    return API.post('brunch', '/activities', { body: Activity })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function addBookingSlot(slot: BookingSlotRequest) {
    return API.post('brunch', '/activities/slot', { body: slot })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function updateBookingSlot(slot: BookingSlotRequest, id: string) {
    return API.put('brunch', `/activities/slot/${id}`, { body: slot })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function getBookingRooms() {
    return API.get('brunch', '/activities/rooms', {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function updateBookingActivity(activity: BookingActivity) {
    return API.put('brunch', `/activities`, { body: activity })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function deleteBookingActivity(activity: BookingActivity) {
    return API.del(
        'brunch',
        `/activities/room/${activity.room}/id/${activity.id}`,
        {}
    )
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function getSlotsForWeek(date: string) {
    return API.get('brunch', `/activities/calendar/${date}/week`, {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function deleteSlot(slot: BookingSlot) {
    return API.del('brunch', `/activities/slot/${slot.slotId}`, {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function getSlotsByDate(date: string): Promise<BookingSlot[]> {
    return API.get('brunch', `/activities/calendar/${date}`, {})
        .then((response) =>
            response.sort((a: BookingSlot, b: BookingSlot) =>
                a.startDateTime < b.startDateTime ? -1 : 1
            )
        )
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function bookSlot(slot: BookingSlot) {
    return API.post('brunch', `/activities/booking`, {
        body: {
            slotId: slot.slotId
        }
    })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function removeSlot(slot: BookingSlot) {
    return API.del('brunch', `/activities/booking/${slot.slotId}`, {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function getSlotInfo(
    startDateTime: number,
    room: string,
    durationInMinutes: number
) {
    return API.post('brunch', '/booking/slot/custom/info', {
        body: {
            startDateTime,
            room,
            durationInMinutes
        }
    })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function getBookingsForActiveMonth(date: string) {
    return API.get('brunch', `/activities/booking/${date}/month`, {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function updateBooking(slotId: string, guests: string[]) {
    return API.put('brunch', `/activities/booking/${slotId}`, { body: guests })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}
