import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { MenuItemDto } from '../../api-types';
import { deleteMenuItemPhoto, uploadMenuItemPhoto } from '../../api/menu';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectError, setError } from '../../app/menuSlice';
import { updateRegistryItem } from '../../app/registrySlice';

import './index.css';
import { UseFormReturn } from 'react-hook-form';

interface IProps {
    form: UseFormReturn<MenuItemDto, unknown>;
    menuItem: MenuItemDto;
}

export function EditPhoto({ form, menuItem }: IProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { getValues, setValue } = form;

    const error = useAppSelector(selectError);

    const handleUploadFile = useCallback(
        (file: FileList | null) => {
            if (file !== null) {
                const formData = new FormData();
                formData.append('image', file[0]);
                uploadMenuItemPhoto(menuItem, formData)
                    .then((response) => handleResponse(response))
                    .catch((error) => console.log(error));
            }
        },
        [menuItem]
    );

    const handleDeleteImage = useCallback(() => {
        deleteMenuItemPhoto(menuItem).then((response) => {
            handleResponse(response);
        });
    }, [menuItem]);

    const handleResponse = useCallback(
        (response: MenuItemDto | { error: string }) => {
            if (Object.keys(response).includes('error')) {
                dispatch(setError(response['error']));
                setValue('image', null);
            } else {
                dispatch(setError(false));
                dispatch(updateRegistryItem(response));
                setValue('image', (response as MenuItemDto).image);
            }
        },
        []
    );

    return (
        <div className='photo'>
            <label>{t('photo')}</label>
            <div className='wrapper'>
                <img
                    src={getValues('image') ?? '/images/placeholder.png'}
                    alt='menu-item-photo'
                />
                <input
                    type='file'
                    hidden
                    id='photo'
                    onChange={(event) => handleUploadFile(event.target.files)}
                    value=''
                    accept='image/x-png,image/jpeg'
                />
                <div className='actions'>
                    <button
                        className='btn btn-white'
                        type='button'
                        onClick={() =>
                            document.getElementById('photo')?.click()
                        }
                    >
                        {t('upload_photo')}
                    </button>
                    <button
                        className='btn btn-white btn-danger'
                        type='button'
                        onClick={handleDeleteImage}
                    >
                        {t('remove_photo')}
                    </button>
                </div>
            </div>
            {error !== false && (
                <label className='error'>{(error as Error).message}</label>
            )}
        </div>
    );
}
