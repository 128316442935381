import { useEffect, useState } from 'react';
import { getMenu } from '../../api/menu';
import { MenuItemDto } from '../../api-types';
import { groupBy } from 'lodash';
import {
    MenuItemGroup,
    MenuItemType
} from '../../api-types/models/MenuItemDto';

import './index.css';
import { useTranslation } from 'react-i18next';
import MenuItem from '../../components/menuItem';

export function BarPage() {
    const { t } = useTranslation();

    const [menu, setMenu] = useState<{ [key: string]: MenuItemDto[] }>({});
    const [currentIndex, setCurrentIndex] = useState(0);

    const fetchMenuForToday = () => {
        getMenu(new Date()).then((response) =>
            setMenu(
                groupBy(
                    response.filter(
                        (element) =>
                            element.costCenter === MenuItemType.BAR &&
                            element.group !== MenuItemGroup.USUAL_ITEMS
                    ),
                    'group'
                )
            )
        );
    };

    useEffect(() => {
        fetchMenuForToday();
    }, []);

    useEffect(() => {
        // Periodically fetch menu
        const interval = setInterval(() => {
            //interval time should be in miliseconds
            fetchMenuForToday();
        }, +(process.env.REACT_APP_MENU_FETCH_INTERVAL_IN_MINUTES ?? 5) * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex(
                (prevIndex) => (prevIndex + 1) % Object.keys(menu).length
            );
        }, +(process.env.REACT_APP_BAR_OFFER_CHANGE_TIME_IN_SECONDS ?? 10) * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [menu]);

    const currentGroup = Object.keys(menu)[currentIndex];

    return (
        <div className='bar-offer'>
            <h2>{t(currentGroup?.toLowerCase())}</h2>
            {menu[currentGroup]?.map((item) => (
                <div className='bar-item' key={item.uuid}>
                    <MenuItem menuItem={item} />
                </div>
            ))}
        </div>
    );
}
