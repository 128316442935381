import { BookingSlotRequest, WeekType } from 'api-types/models/Booking';
import { Switch } from 'components/switch';
import { getDaysArray } from 'utils/getDaysArray';
import { getLastDayOfMonth } from 'utils/getLastDayOfMonth';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProps {
    setValue: UseFormSetValue<BookingSlotRequest>;
    days: number[];
    date?: Date;
}
export function RepeatDays({ setValue, days, date }: Readonly<IProps>) {
    const { t } = useTranslation();

    const handleChangeDay = (index: number, checked: boolean) => {
        if (checked) {
            setValue('repeatOnDays', (days ?? []).concat(index + 1));
        } else {
            setValue(
                'repeatOnDays',
                days?.filter((day) => day !== index + 1)
            );
        }
    };

    return (
        <div className='repeat-days'>
            {getDaysArray(WeekType.FULL).map((day, index) => (
                <div className='switch-label' key={`${day}-${index}`}>
                    <Switch
                        key={`repeat-${day}`}
                        checked={
                            days?.length ? days.includes(index + 1) : false
                        }
                        onChange={(checked) => handleChangeDay(index, checked)}
                        label={day.toLowerCase()}
                    />
                    {date && (
                        <small>
                            {t('repeat_until', {
                                date: getLastDayOfMonth(date, index + 1)
                            })}
                        </small>
                    )}
                </div>
            ))}
        </div>
    );
}
