import './index.css';
import ReactModal from 'react-modal';
import { extendedModalStyles } from '../../config/modal';
import { useTranslation } from 'react-i18next';

interface IProps {
    title: string;
    actionButtons: JSX.Element;
    closeModal: () => void;
    content: JSX.Element;
    className?: string;
}

export function Modal({
    title,
    actionButtons,
    closeModal,
    content,
    className
}: Readonly<IProps>) {
    const { t } = useTranslation();

    return (
        <ReactModal
            isOpen={true}
            style={extendedModalStyles}
            onRequestClose={closeModal}
            ariaHideApp={false}
            className={`${className} modal`}
        >
            <div className='modal-header'>
                <h3>{t(title)}</h3>
                <img
                    src='/images/ico_close.svg'
                    alt='close'
                    onClick={closeModal}
                    width={16}
                />
            </div>
            <div className='modal-content'>{content}</div>
            <div className='modal-actions'>{actionButtons}</div>
        </ReactModal>
    );
}
