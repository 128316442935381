import { FoodIcon } from '../foodIcon';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { MenuItemDto } from '../../api-types';

import './index.css';

interface IProps {
    innerProps?: DetailedHTMLProps<
        HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >;
    label: string;
    data: { menuItem: MenuItemDto };
}

export function MenuItemOption({ innerProps, label, data }: IProps) {
    return (
        <div {...innerProps} className='item-select'>
            <FoodIcon foodType={data.menuItem.icon} />
            <p>
                <span className='bold'>{label}</span>
                {` (${data.menuItem.fullPrice}€ - ${data.menuItem.subsidizedPrice}€)`}
            </p>
        </div>
    );
}
