/* eslint-disable */
import { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useAppDispatch } from '../app/hooks';
import { HubCapsule, HubPayload } from '@aws-amplify/core';

import {
    fetchUserDataFailure,
    fetchUserDataInit,
    fetchUserDataSuccess,
    resetUserData
} from '../app/authSlice';
import { getUserSettingsThunk, resetUserSettings } from '../app/userSlice';
import { LoginType } from '../api-types/models/LoginDto';
import { useIsAuthenticated } from '@azure/msal-react';

const useAmplifyAuth = () => {
    const [triggerFetch, setTriggerFetch] = useState(false);
    const dispatch = useAppDispatch();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        let isMounted = true;

        const fetchUserData = async () => {
            if (isMounted) {
                dispatch(fetchUserDataInit());
                try {
                    const data = await Auth.currentAuthenticatedUser();

                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    if (data) {
                        dispatch(fetchUserDataSuccess(data));

                        const userGroups =
                            data.signInUserSession.accessToken.payload[
                                'cognito:groups'
                            ];

                        if (
                            !userGroups.includes(LoginType.BRUNCH) &&
                            !userGroups.includes(LoginType.COACH)
                        ) {
                            dispatch(getUserSettingsThunk());
                        }
                    }
                } catch (error) {
                    if (isAuthenticated) {
                        dispatch(getUserSettingsThunk());
                    } else {
                        dispatch(resetUserData());
                        dispatch(fetchUserDataFailure());
                    }
                }
            }
        };

        const HubListener = () => {
            Hub.listen('auth', (data: HubCapsule) => {
                const { payload } = data;
                onAuthEvent(payload);
            });
        };

        const onAuthEvent = (payload: HubPayload) => {
            switch (payload.event) {
                case 'signIn':
                    if (isMounted) {
                        setTriggerFetch(true);
                        console.log('signed in');
                    }
                    break;
                default:
                    return;
            }
        };

        HubListener();
        fetchUserData().catch((error) => {
            console.log('Error fetching user data ', error);
        });

        return () => {
            Hub.remove('auth', () => {
                //do nothing
            });
            isMounted = false;
        };
    }, [dispatch, triggerFetch, isAuthenticated]);

    const handleSignout = async () => {
        try {
            console.log('signed out');
            await Auth.signOut();
            setTriggerFetch(false);
            dispatch(resetUserData());
            dispatch(resetUserSettings());
        } catch (error) {
            console.error('Error signing out user ', error);
        }
    };

    return { handleSignout };
};

export default useAmplifyAuth;
