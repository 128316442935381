import { useTranslation } from 'react-i18next';
import { MenuItemDto } from '../../api-types';
import {
    MenuItemCategory,
    MenuItemGroup,
    MenuItemType
} from '../../api-types/models/MenuItemDto';
import { InputField } from '../inputField';
import { EditAllergens } from '../editAllergens';
import { EditPhoto } from '../editPhoto';
import { UseFormReturn } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Category } from '../../api-types/models/Category';
import { getCategories, getGroups } from '../../api/menu';
import { Tax } from '../../api-types/models/Tax';

import './index.css';

interface IProps {
    menuItem?: MenuItemDto;
    form: UseFormReturn<MenuItemDto, unknown>;
    onFormSubmit: (menuItem: MenuItemDto) => void;
    onCancel: () => void;
    taxOptions: Tax;
}

export function EditMenuItem({
    menuItem,
    form,
    onFormSubmit,
    onCancel,
    taxOptions
}: IProps) {
    const { t } = useTranslation();

    const [groups, setGroups] = useState<Category[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);

    const {
        handleSubmit,
        register,
        getValues,
        watch,
        formState: { isValid, errors },
        setValue
    } = form;

    const costCenter = watch('costCenter');
    const group = watch('group');
    const category = watch('category');
    const subsidizedPrice = +watch('subsidizedPrice');
    const fullPrice = +watch('fullPrice');

    const ingredients = getValues('ingredients');
    const variants = getValues('variants');

    useEffect(() => {
        setValue('ingredients', ingredients ?? []);
        setValue('variants', variants ?? []);
    }, [ingredients, variants]);

    useEffect(() => {
        getGroups().then((response) => {
            if (!Object.keys(response).includes('error')) {
                setGroups(response as Category[]);
            }
        });
        getCategories().then((response) => {
            if (!Object.keys(response).includes('error')) {
                setCategories(response as Category[]);
            }
        });
    }, []);

    useEffect(() => {
        if (!group && groups.length) {
            // Sandwich is default group as the USUAL_ITEMS is filtered
            setValue('group', groups[1].name as MenuItemGroup);
        }
    }, [groups, group]);

    useEffect(() => {
        if (!category && categories.length) {
            setValue('category', categories[0].name as MenuItemCategory);
        }
    }, [categories, category]);

    return (
        <form className='edit-menu-item' onSubmit={handleSubmit(onFormSubmit)}>
            <div className='input-row'>
                <div className='input-group'>
                    <label>{t('cost_center')}*</label>
                    <select
                        className='input-form'
                        defaultValue={getValues('costCenter')}
                        {...register('costCenter')}
                    >
                        {Object.values(MenuItemType).map((type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='input-group'>
                    <label>{t('group')}*</label>
                    <select
                        className='input-form'
                        value={group}
                        {...register('group')}
                    >
                        {groups
                            .filter(
                                (group) =>
                                    ![
                                        MenuItemGroup.USUAL_ITEMS,
                                        MenuItemGroup.UNKNOWN
                                    ].includes(group.name as MenuItemGroup)
                            )
                            .map((group) => (
                                <option
                                    key={`group-${group.index}`}
                                    value={group.name}
                                >
                                    {group.name.replace('_', ' ')}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
            <div className='input-row'>
                {costCenter !== MenuItemType.BAR && (
                    <div className='input-group category'>
                        <label>{t('category')}*</label>
                        <select
                            className='input-form'
                            value={category ?? MenuItemCategory.BURGER}
                            {...register('category')}
                        >
                            {categories.map((category) => (
                                <option
                                    key={`category-${category.index}`}
                                    value={category.name}
                                >
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <InputField
                    value={getValues('fullPrice')}
                    label={t('price')}
                    form={form}
                    name='fullPrice'
                    required={true}
                    number={true}
                />
                <InputField
                    value={getValues('subsidizedPrice')}
                    label={t('discounted_price')}
                    form={form}
                    name='subsidizedPrice'
                    required={true}
                    number={true}
                />
            </div>
            {subsidizedPrice && fullPrice && subsidizedPrice > fullPrice ? (
                <small className='wrong-price'>
                    {t('wrong_subsidized_price')}
                </small>
            ) : null}
            {subsidizedPrice &&
            fullPrice &&
            (subsidizedPrice < 0 || fullPrice < 0) ? (
                <small className='wrong-price'>{t('negative_price')}</small>
            ) : null}
            <div className='input-row'>
                <div className='input-group description'>
                    <label>{t('description')}*</label>
                    <textarea
                        className='input-form'
                        defaultValue={getValues('description')}
                        rows={5}
                        {...register('description', { required: true })}
                    />
                </div>
            </div>
            <EditAllergens form={form} />
            {menuItem && <EditPhoto form={form} menuItem={menuItem} />}
            <div className='input-row'>
                <div className='input-group'>
                    <label>{t('ingredients')}</label>
                    <small className='helper'>{t('ingredients_helper')}</small>
                    <input
                        className='input-form'
                        defaultValue={(getValues('ingredients') ?? []).join(
                            ','
                        )}
                        onChange={(event) =>
                            setValue(
                                'ingredients',
                                event.target.value.split(',')
                            )
                        }
                    />
                </div>
            </div>
            <div className='input-row'>
                <div className='input-group'>
                    <label>{t('variants')}</label>
                    <small className='helper'>{t('variants_helper')}</small>
                    <input
                        className='input-form'
                        defaultValue={(getValues('variants') ?? []).join(',')}
                        onChange={(event) =>
                            setValue('variants', event.target.value.split(','))
                        }
                    />
                </div>
            </div>
            {costCenter === MenuItemType.BAR && (
                <div className='input-row'>
                    <div className='checkbox'>
                        <label>{t('alcohol')}</label>
                        <input type='checkbox' {...register('alcohol')} />
                    </div>
                </div>
            )}
            <div className='input-row'>
                <div className='input-group'>
                    <label>{t('tax')}*</label>
                    <select
                        className='input-form'
                        defaultValue={getValues('taxValue')}
                        {...register('taxValue', { required: true })}
                    >
                        <option value={undefined}>
                            {t('select_tax_option_placeholder')}
                        </option>
                        {Object.keys(taxOptions).map((taxOption) => (
                            <option
                                key={`taxOption-${taxOption}`}
                                value={taxOption}
                            >
                                {taxOptions[taxOption]} %
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='input-row'>
                <div className='checkbox'>
                    <label>{t('takeout')}</label>
                    <input type='checkbox' {...register('takeout')} />
                </div>
            </div>
            <div className='input-row'>
                <div className='checkbox'>
                    <label>{t('breakfast')}</label>
                    <input type='checkbox' {...register('breakfast')} />
                </div>
            </div>
            <div className='input-row'>
                <div className='checkbox'>
                    <label>{t('self_served')}</label>
                    <input type='checkbox' {...register('selfService')} />
                </div>
            </div>
            <hr />
            <div className='actions-row'>
                <button
                    className='btn btn-gray'
                    type='button'
                    onClick={onCancel}
                >
                    {t('cancel')}
                </button>
                <button
                    className='btn btn-blue'
                    type='submit'
                    disabled={
                        !isValid ||
                        subsidizedPrice > fullPrice ||
                        subsidizedPrice < 0 ||
                        fullPrice < 0
                    }
                >
                    {t('save')}
                </button>
            </div>
            {!isEmpty(errors) && (
                <div className='error'>
                    <small>
                        {t('required_fields')}
                        {Object.keys(errors)
                            .map((field) => t(field))
                            .join(', ')}
                    </small>
                </div>
            )}
        </form>
    );
}
