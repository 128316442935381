import { LoginType } from 'api-types/models/LoginDto';
import { getUserGroups } from './getUserGroups';
import { CognitoUser } from 'interfaces/cognitoUser';

export function isOneOfTheFitnessGroups(
    user: CognitoUser | null | undefined
): boolean {
    return [LoginType.COACH, LoginType.MASSAGE, LoginType.PHYSIO].some(
        (loginType) => getUserGroups(user).includes(loginType)
    );
}
