import React from 'react';
import ContentLoader from 'react-content-loader';

const MenuItemsLoader: React.FunctionComponent = (props) => {
    return (
        <ContentLoader
            speed={2}
            width={'100%'}
            height={'100%'}
            backgroundColor='#d9d9d9'
            foregroundColor='#ededed'
            {...props}
        >
            <rect x='0' y='0' rx='4' ry='4' width='100%' height='96' />
            <rect x='0' y='112' rx='4' ry='4' width='100%' height='96' />
            <rect x='0' y='224' rx='4' ry='4' width='100%' height='96' />
        </ContentLoader>
    );
};

export default MenuItemsLoader;
