import { BookingSlot } from '../../api-types/models/Booking';
import { addMinutes, getHours } from 'date-fns';
import { useEffect, useState } from 'react';
import {
    calculateBottomMargin,
    calculateTopMargin
} from '../../utils/calculateSlotHeight';
import ReactTooltip from 'react-tooltip';

import './index.css';

interface IProps {
    slot: BookingSlot;
    setSelectedSlot: (slot: BookingSlot) => void;
    slotStartHour: number;
}

export function BookingSlotContent({
    slot,
    setSelectedSlot,
    slotStartHour
}: Readonly<IProps>) {
    const [cellHeight, setCellHeight] = useState(0);

    const startDateTime = new Date(slot.date + ' ' + slot.from).getTime();

    const trailing =
        getHours(addMinutes(startDateTime, slot.duration)) !==
        slotStartHour + 1;
    const ending = new Date(startDateTime).getHours() !== slotStartHour;

    // Set top and bottom margin calculated by slot start datetime minutes
    const marginTop = calculateTopMargin(slot, cellHeight);
    const marginBottom = calculateBottomMargin(slot, cellHeight);

    useEffect(() => {
        // Get height of the cell for calculation of the slot height
        setCellHeight(
            document.getElementById(slot.slotId)?.parentElement?.clientHeight ??
                0
        );
    }, [slot]);

    const tooltip =
        slot.attendees.length === 1
            ? slot.attendees
            : `${slot.attendees[0]} and ${slot.attendees.length - 1} more`;

    return (
        <div
            id={slot.slotId}
            className={`booking-content active ${
                ending ? 'following' : undefined
            } ${trailing ? 'trailing' : undefined}`}
            onClick={() => setSelectedSlot(slot)}
            style={{ marginTop, marginBottom }}
            data-tip={slot.attendees.length > 0 ? tooltip : undefined}
        >
            <div className='icon'>
                <img
                    src={`/images/booking/${slot.room.toLowerCase()}.svg`}
                    alt={slot.room}
                />
            </div>
            {(!ending || (ending && trailing)) && (
                <div className='slot-info'>
                    <p>{`${slot.activityName} ${slot.lead ?? ''}`}</p>
                    <b className='text-elipsis'>
                        {slot.room === 'MASSAGE'
                            ? `${
                                  slot.attendees.length
                                      ? slot.attendees[0]
                                      : 'Free'
                              }`
                            : `${slot.attendees.length} / ${slot.capacity} persons`}
                    </b>
                </div>
            )}
            {slot.attendees.length > 0 && (
                <ReactTooltip key={`tooltip-${tooltip}`} />
            )}
        </div>
    );
}
