import { useTranslation } from 'react-i18next';
import './index.css';

interface IProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    tabs: string[];
}

export function NavigationTabs({
    activeTab,
    setActiveTab,
    tabs
}: Readonly<IProps>) {
    const { t } = useTranslation();

    return (
        <div className='tabs'>
            {tabs.map((tab) => (
                <button
                    role='tab'
                    key={`${tab}`}
                    className={`${activeTab === tab ? 'active' : ''} tab`}
                    onClick={() => setActiveTab(tab)}
                >
                    <img
                        src={`/images/ico_${tab.toLowerCase()}.svg`}
                        alt={tab}
                        width={16}
                    />
                    {t(tab.toLowerCase()).toUpperCase()}
                </button>
            ))}
        </div>
    );
}
