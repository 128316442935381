import { Statistics } from '../../api-types/models/Statistics';
import { FoodIcon } from '../foodIcon';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../utils/capitalizeCase';
import ReactPaginate from 'react-paginate';
import { useState } from 'react';

import './index.css';

interface IProps {
    statisticsArray: Statistics[];
}

export function StatisticsTable({ statisticsArray }: IProps) {
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [ordersPerPage, setOrdersPerPage] = useState<number>(15);

    const numberOfALlOrders = statisticsArray.reduce(
        (sum, currentObject) => sum + (currentObject?.count ?? 0),
        0
    );

    return statisticsArray.length > 0 ? (
        <div className='statistics-wrapper'>
            <table className='table statistics-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>{t('group')}</th>
                        <th>{t('title')}</th>
                        <th>{t('count')}</th>
                    </tr>
                </thead>
                <tbody>
                    {statisticsArray
                        .slice(
                            currentPage * ordersPerPage,
                            currentPage * ordersPerPage + ordersPerPage
                        )
                        .map((statistics) => (
                            <tr
                                key={statistics.menuItem.uuid}
                                className='interest-statistics'
                            >
                                <td className='statistics-icon'>
                                    <FoodIcon
                                        foodType={
                                            statistics.menuItem.category ??
                                            statistics.menuItem.group
                                        }
                                    />
                                </td>
                                <td>{statistics.menuItem.group}</td>
                                <td>
                                    {capitalizeFirstLetter(
                                        statistics.menuItem.title
                                    )}
                                </td>
                                <td>{statistics.count}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <div className='pagination'>
                <div className='orders-per-page'>
                    <label>{t('orders_per_page')}: </label>
                    <input
                        className='input-form'
                        type='number'
                        value={ordersPerPage}
                        onChange={(event) =>
                            setOrdersPerPage(+event.target.value ?? 1)
                        }
                        min={1}
                    />
                </div>
                {statisticsArray.length > ordersPerPage && (
                    <ReactPaginate
                        breakLabel='...'
                        nextLabel='>'
                        onPageChange={(pageNumber: { selected: number }) =>
                            setCurrentPage(pageNumber.selected)
                        }
                        pageRangeDisplayed={5}
                        pageCount={Math.ceil(
                            statisticsArray.length / ordersPerPage
                        )}
                        previousLabel='<'
                        renderOnZeroPageCount={null}
                        activeClassName='active'
                    />
                )}
                <div className='text-right'>
                    <b>{t('all_orders')}: </b>
                    <span>{numberOfALlOrders}</span>
                </div>
            </div>
        </div>
    ) : (
        <div className='no-content'>{t('no_statistics')}</div>
    );
}
