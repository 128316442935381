export type MenuItemDto = {
    id: string;
    title: string;
    uuid: string;
    image: string | null;
    icon: MenuItemIcon;
    description: string;
    fullPrice: number;
    subsidizedPrice: number;
    allergens: Array<string>;
    nutritionalValues: Record<string, number>;
    costCenter: MenuItemType;
    category: MenuItemCategory | null;
    stock: number;
    group: MenuItemGroup;
    ingredients: string[];
    alcohol: boolean;
    takeout: boolean;
    variants: string[];
    breakfast: boolean;
    taxValue: TaxType;
    selfService: boolean;
    active?: boolean;
};

export enum MenuItemType {
    KITCHEN = 'KITCHEN',
    BAR = 'BAR'
}

export enum MenuItemGroup {
    BREAKFAST = 'BREAKFAST',
    WEEKLY_BREAKFAST = 'WEEKLY_BREAKFAST',
    DAILY = 'DAILY',
    VEGI = 'VEGI',
    MEAT = 'MEAT',
    SALAD = 'SALAD',
    ICE_CREAM = 'ICE_CREAM',
    BURGER = 'BURGER',
    HOT = 'HOT',
    COFFEE = 'COFFEE',
    SOFT = 'SOFT',
    BEER = 'BEER',
    SPIRITS = 'SPIRITS',
    SANDWICH = 'SANDWICH',
    HOT_BEVERAGES = 'HOT_BEVERAGES',
    USUAL_ITEMS = 'USUAL_ITEMS',
    UNKNOWN = 'UNKNOWN'
}

export enum MenuItemCategory {
    SOUP = 'SOUP',
    VEGI = 'VEGI',
    MEAT = 'MEAT',
    BURGER = 'BURGER',
    FISH = 'FISH',
    LOWCARB = 'LOWCARB',
    SALAD = 'SALAD',
    ICE_CREAM = 'ICE_CREAM'
}

export enum MenuItemIcon {
    COFFEE = 'COFFEE',
    HOT_BEVERAGES = 'HOT_BEVERAGES',
    SOFT = 'SOFT',
    BEER = 'BEER',
    SPIRITS = 'SPIRITS',
    VEGI = 'VEGI',
    MEAT = 'MEAT',
    FISH = 'FISH',
    LOWCARB = 'LOWCARB',
    SALAD = 'SALAD',
    ICE_CREAM = 'ICE_CREAM',
    BURGER = 'BURGER',
    BREAKFAST = 'BREAKFAST'
}

export enum MenuItemPriceType {
    MAIN = 'MAIN',
    DESSERT = 'DESSERT',
    SMALLSALAD = 'SMALLSALAD',
    PACKAGE = 'PACKAGE'
}

export enum MenuType {
    MENU = 'menu',
    REGISTRY = 'registry'
}

export enum TaxType {
    STANDARD = 'STANDARD',
    LOW = 'LOW'
}
