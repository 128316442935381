import './index.css';
import { Auth } from 'aws-amplify';
import { useCallback, useState } from 'react';
import { selectConfig } from '../../app/configSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setTemporaryUser } from '../../app/userSlice';
import { CognitoUser } from '../../interfaces/cognitoUser';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { selectAuthUser } from '../../app/authSlice';
import { FormHeader } from '../../components/formHeader';
import useAmplifyAuth from '../../hooks/useAmplifyAuth';
import IPage from '../../interfaces/page';
import { Navigate, useNavigate } from 'react-router-dom';
import { LoginType, ManualLoginDto } from '../../api-types/models/LoginDto';

export default function ManualLogin(
    props: IPage & { loadConfig?: boolean }
): JSX.Element {
    props.loadConfig !== false && useAmplifyAuth();
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { isDirty, isValid }
    } = useForm<ManualLoginDto>({ mode: 'all' });

    const [passwordShown, setPasswordShown] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const config = useAppSelector(selectConfig);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(selectAuthUser);

    const togglePassword = useCallback(() => {
        setPasswordShown(!passwordShown);
    }, [passwordShown]);

    const handleGuestLogin = async (data: ManualLoginDto) => {
        try {
            if (config) {
                localStorage.setItem('login', LoginType.BRUNCH);
                const brunchUser: CognitoUser = await Auth.signIn(
                    data.email,
                    data.password
                );

                if (brunchUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    dispatch(setTemporaryUser(brunchUser));
                    navigate(
                        `/set-password?email=${encodeURIComponent(data.email)}`
                    );
                }
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            setError(e.message);
        }
    };

    if (user) {
        return <Navigate to='/' />;
    }

    return (
        <div className='login-box'>
            <FormHeader />
            <div className='login-form'>
                <h1>{t('login_title')}</h1>
                <form>
                    <div>
                        <label>{t('email')}</label>
                        <input
                            className={`input-form ${
                                error !== undefined ? 'error-form' : ''
                            }`}
                            type='email'
                            id='email'
                            {...register('email', { required: true })}
                        />
                    </div>
                    <div className='password-wrapper'>
                        <label>{t('password')}</label>
                        <input
                            className={`input-form ${
                                error !== undefined ? 'error-form' : ''
                            }`}
                            type={passwordShown ? 'text' : 'password'}
                            id='password'
                            {...register('password', { required: true })}
                        />
                        <div
                            className='toggle-password'
                            onClick={togglePassword}
                        >
                            <img
                                alt='hide-password'
                                src='/images/ico_hide_password.svg'
                            />
                        </div>
                    </div>

                    {error !== undefined && (
                        <div className='error-toast'>
                            <img alt='error' src='/images/ico_error.svg' />
                            {error}
                        </div>
                    )}
                    <button
                        className='login-button'
                        role='login-button'
                        onClick={handleSubmit(handleGuestLogin)}
                        disabled={!isDirty || !isValid}
                    >
                        {t('login_title')}
                    </button>
                </form>
            </div>
            <div className='login-options'>
                <div
                    className='forgot-password'
                    onClick={() => navigate('/forgot-password')}
                >
                    {t('forgot_password')}
                </div>
            </div>
        </div>
    );
}
