import './index.css';
import { DateSelector } from '../dateSelector';
import { useAppSelector } from '../../app/hooks';
import { selectDate } from '../calendar/calendarSlice';
import { useEffect, useState } from 'react';
import { getSlotsByDate } from '../../api/booking';
import formatDate from '../../utils/formatDate';
import {
    BookingSlot as IBookingSlot,
    UpdateSlotAction
} from '../../api-types/models/Booking';
import { BookingSlot } from '../bookingSlot';
import { selectUserSettings } from '../../app/userSlice';
import MenuItemsLoader from '../loading/menuLoader';
import { useTranslation } from 'react-i18next';
import { CustomEvent } from '@piwikpro/react-piwik-pro';

export function BookingUser() {
    const { t } = useTranslation();

    const [email, setEmail] = useState<string>();
    const user = useAppSelector(selectUserSettings);

    useEffect(() => {
        if (user) {
            setEmail(user.email);
        }
    }, [user]);

    const [slots, setSlots] = useState<IBookingSlot[]>();
    const selectedDate = useAppSelector(selectDate);

    const updateSlot = (
        slot: IBookingSlot,
        action: UpdateSlotAction | null
    ) => {
        setSlots(
            slots?.map((oldSlot) => {
                console.log(
                    oldSlot.slotId === slot.slotId
                        ? {
                              ...slot,
                              guests: {
                                  ...oldSlot.guests,
                                  [email ?? '']:
                                      slot.guests as unknown as string[]
                              }
                          }
                        : oldSlot
                );
                if (action === UpdateSlotAction.GUEST) {
                    return oldSlot.slotId === slot.slotId
                        ? {
                              ...oldSlot,
                              guests: {
                                  ...oldSlot.guests,
                                  [email ?? '']:
                                      slot.guests as unknown as string[]
                              }
                          }
                        : oldSlot;
                } else
                    return oldSlot.slotId === slot.slotId
                        ? {
                              ...oldSlot,
                              attendees: oldSlot.attendees.includes(email ?? '')
                                  ? oldSlot.attendees.filter(
                                        (oldAttendee) => oldAttendee !== email
                                    )
                                  : oldSlot.attendees.concat(email ?? '')
                          }
                        : oldSlot;
            })
        );
    };

    const removeSlot = (slot: IBookingSlot) => {
        setSlots(
            slot.slotId === 'CUSTOM'
                ? slots?.filter((oldSlot) => oldSlot.slotId !== slot.slotId)
                : slots?.map((oldSlot) =>
                      oldSlot.slotId === slot.slotId
                          ? {
                                ...slot,
                                attendees: slot.attendees.filter(
                                    (oldAttendee) => oldAttendee !== email
                                )
                            }
                          : oldSlot
                  )
        );
    };

    useEffect(() => {
        getSlotsByDate(formatDate(selectedDate)).then((response) => {
            if (!response['error']) {
                setSlots(response);
            }
        });
    }, [selectedDate]);

    useEffect(() => {
        CustomEvent.trackEvent('page_view', 'Booking (user)');
    }, []);

    if (!slots) {
        return <MenuItemsLoader />;
    }

    return (
        <div className='booking-user'>
            <div className='head'>
                <DateSelector />
            </div>
            <div className='table'>
                {slots.length ? (
                    slots.map((slot, index) => (
                        <BookingSlot
                            key={`${slot.slotId}-${index}`}
                            slot={slot}
                            updateSlot={updateSlot}
                            removeSlot={removeSlot}
                            booked={
                                email !== undefined &&
                                slot.attendees?.includes(email)
                            }
                        />
                    ))
                ) : (
                    <div className='no-slots'>
                        <img src='/images/no_activity.png' alt='no-activity' />
                        <h3>{t('no_activities')}</h3>
                    </div>
                )}
            </div>
        </div>
    );
}
