import { format } from 'date-fns';
import { enGB, sl } from 'date-fns/esm/locale';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getOrdersForThisMonth } from '../../../../api/orders';
import { OrderDto } from '../../../../api-types';
import i18n from '../../../../i18n';
import { getOrdersPrice } from '../../../../utils/calculateOrdersPrice';
import { Months } from '../months';
import { Weeks } from '../weeks';

import './index.css';
import { useAppDispatch } from '../../../../app/hooks';
import { setError } from '../../../../app/menuSlice';
import { BookingSlot } from '../../../../api-types/models/Booking';
import { getBookingsForActiveMonth } from '../../../../api/booking';
import { MenuItemType } from '../../../../api-types/models/MenuItemDto';

export function Calendar(): JSX.Element {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [activeMonthIndex, setActiveMonthIndex] = useState<number>();
    const [ordersForThisMonth, setOrdersForThisMonth] = useState<OrderDto[]>();
    const [bookingsForThisMonth, setBookingsForThisMonth] =
        useState<BookingSlot[]>();

    const currentMonthIndex = new Date().getMonth();

    useEffect(() => {
        setActiveMonthIndex(currentMonthIndex);
    }, []);

    const activeYear =
        activeMonthIndex !== undefined && currentMonthIndex < activeMonthIndex
            ? new Date().getFullYear() - 1
            : new Date().getFullYear();

    useEffect(() => {
        if (activeMonthIndex !== undefined) {
            getOrdersForThisMonth(activeMonthIndex, activeYear)
                .then((response: OrderDto[]) => setOrdersForThisMonth(response))
                .catch((e) => {
                    dispatch(setError(e));
                });
            getBookingsForActiveMonth(
                `${activeYear}-${
                    activeMonthIndex < 8
                        ? '0' + (activeMonthIndex + 1)
                        : activeMonthIndex + 1
                }-01`
            ).then((response: BookingSlot[]) => {
                if (response['error']) {
                    dispatch(setError(response['error']));
                } else {
                    setBookingsForThisMonth(response);
                }
            });
        }
    }, [activeMonthIndex]);

    if (activeMonthIndex === undefined) {
        return <></>;
    }

    return (
        <div className='calendar-container'>
            <div className='calendar-options'>
                <Months
                    activeMonthIndex={activeMonthIndex}
                    setActiveMonthIndex={setActiveMonthIndex}
                    currentMonthIndex={currentMonthIndex}
                />
                <div className='options-title price'>
                    {t('total')}{' '}
                    {format(
                        new Date(new Date().getFullYear(), activeMonthIndex, 1),
                        'LLLL',
                        { locale: i18n.language === 'en' ? enGB : sl }
                    )}{' '}
                    {ordersForThisMonth && getOrdersPrice(ordersForThisMonth)}
                    {' €'}
                </div>
            </div>

            <div className='calendar-wrapper'>
                <h3>{t('kitchen')}</h3>
                <div className='calendar-options'>
                    <Weeks<OrderDto>
                        activeMonthIndex={activeMonthIndex}
                        activeYear={activeYear}
                        elementsForThisMonth={ordersForThisMonth?.filter(
                            (order) =>
                                order.orderItems.some(
                                    (item) =>
                                        item.menuItem.costCenter ===
                                        MenuItemType.KITCHEN
                                )
                        )}
                    />
                </div>
                <h3>{t('bar')}</h3>
                <div className='calendar-options'>
                    <Weeks<OrderDto>
                        activeMonthIndex={activeMonthIndex}
                        activeYear={activeYear}
                        elementsForThisMonth={ordersForThisMonth?.filter(
                            (order) =>
                                order.orderItems.some(
                                    (item) =>
                                        item.menuItem.costCenter ===
                                        MenuItemType.BAR
                                )
                        )}
                    />
                </div>
                <h3>{t('activities')}</h3>
                <div className='calendar-options'>
                    <Weeks<BookingSlot>
                        activeMonthIndex={activeMonthIndex}
                        activeYear={activeYear}
                        elementsForThisMonth={bookingsForThisMonth}
                        booking={true}
                    />
                </div>
            </div>
        </div>
    );
}
