import { getUserGroups } from './getUserGroups';
import { LoginType } from '../api-types/models/LoginDto';
import { CognitoUser } from '../interfaces/cognitoUser';

export function isBrunchUser(user: CognitoUser | null | undefined) {
    if (!user) {
        return false;
    }

    return getUserGroups(user).includes(LoginType.BRUNCH);
}
