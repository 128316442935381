import './index.css';
import React, { useEffect } from 'react';
import IPage from '../../interfaces/page';
import { useAppSelector } from '../../app/hooks';
import { selectUserSettings } from '../../app/userSlice';
import { Welcome } from './components/welcome';
import { Notifications } from './components/notifications';
import { FoodFilter } from './components/foodFilter';
import { Allergies } from './components/allergies';
import { Preferences } from './components/preferences';
import { Calendar } from './components/calendar';
import MenuItemsLoader from '../../components/loading/menuLoader';
import { Payment } from './components/payment';
import { Language } from './components/language';
import { Guests } from './components/guests';
import { CustomEvent } from '@piwikpro/react-piwik-pro';

const ProfilePage: React.FunctionComponent<IPage> = () => {
    const userSettings = useAppSelector(selectUserSettings);

    useEffect(() => {
        CustomEvent.trackEvent('page_view', 'Profile');
    }, []);

    if (!userSettings) {
        return <MenuItemsLoader></MenuItemsLoader>;
    }

    return (
        <div className='profile'>
            <div className='profile-content'>
                <Welcome user={userSettings} />
                <Calendar />
                <Preferences />
                <div className='user-options'>
                    <div className='column'>
                        {userSettings.notifications !== undefined && (
                            <Notifications
                                notifications={userSettings.notifications}
                            />
                        )}
                        <Language />
                        <Payment />
                    </div>
                    <div className='column'>
                        {userSettings.foodFilter !== undefined && (
                            <FoodFilter foodFilter={userSettings.foodFilter} />
                        )}
                        <Guests userSettings={userSettings} />
                    </div>
                    <div className='column'>
                        <Allergies allergies={userSettings.allergies} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
