import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Modal } from '../modal';
import { useState } from 'react';
import {
    addBookingSlot,
    updateBookingSlot,
    deleteSlot as deleteSlotRequest
} from 'api/booking';
import { ConfirmDeleteDialog } from 'components/confirmDeleteDialog';
import { BookingSlotForm } from './bookingSlotForm';
import {
    BookingSlot,
    BookingActivity,
    BookingSlotRequest
} from 'api-types/models/Booking';

import './index.css';
import { addMinutes, format } from 'date-fns';

interface IProps {
    date?: Date;
    setDate: (date?: Date) => void;
    closeModal: () => void;
    rooms: string[];
    selectedRoom?: string;
    addOrUpdateSlot: (
        slot: BookingSlot | BookingSlot[],
        update: boolean
    ) => void;
    deleteSlot: (slot: BookingSlot) => void;
    slot?: BookingSlot;
    activities: BookingActivity[];
}

export function AddBookingSlotModal({
    setDate,
    date,
    closeModal,
    selectedRoom,
    activities,
    rooms,
    addOrUpdateSlot,
    deleteSlot,
    slot
}: Readonly<IProps>) {
    const { t } = useTranslation();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [error, setError] = useState<string>();

    const form = useForm<BookingSlotRequest>({
        mode: 'all',
        defaultValues: slot
    });

    const {
        formState: { isValid },
        getValues,
        handleSubmit
    } = form;

    const handleAddSlot = async (slotBody: BookingSlotRequest) => {
        if (date) {
            if (slot) {
                updateBookingSlot(
                    {
                        ...slotBody,
                        from: date?.getTime() ?? 0,
                        duration: +slotBody.duration
                    },
                    slot.slotId
                ).then((response) => addOrUpdateSlot(response, true));
            } else {
                if (slotBody.lastSlotStartTime) {
                    try {
                        let startTime = date.getTime();
                        let addedSlots: BookingSlot[] = [];

                        while (startTime <= slotBody.lastSlotStartTime) {
                            const slot = await addBookingSlot({
                                ...slotBody,
                                duration: +slotBody.duration,
                                from: startTime,
                                repeatOnDays: slotBody.repeatOnDays ?? [],
                                repeat: slotBody.repeatWeekly,
                                date: format(date, 'yyyy-MM-dd'),
                                room: slotBody.room
                            });

                            if (slot.error) {
                                setError(slot.error);
                            } else {
                                addedSlots = addedSlots.concat(slot);
                            }
                            startTime = addMinutes(
                                new Date(startTime),
                                +slotBody.duration +
                                    +slotBody.breakBetweenSlotsInMinutes
                            ).getTime();
                        }
                        addOrUpdateSlot(addedSlots, false);
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    addBookingSlot({
                        ...slotBody,
                        duration: +slotBody.duration,
                        capacity: +slotBody.capacity,
                        from: date.getTime(),
                        repeatOnDays: slotBody.repeatOnDays ?? [],
                        repeat: slotBody.repeatWeekly,
                        date: format(date, 'yyyy-MM-dd'),
                        room: slotBody.room
                    }).then((response) => {
                        if (!response.error) {
                            addOrUpdateSlot(response, false);
                        } else {
                            setError(response.error.message);
                        }
                    });
                }
            }
        }
    };

    const handleDeleteSlot = (slot: BookingSlot) => {
        deleteSlotRequest(slot).then((response) => {
            if (!response.error) {
                deleteSlot(slot);
            } else {
                setError(response.error.message);
                setIsDeleteDialogOpen(false);
            }
        });
    };

    return (
        <>
            <Modal
                title={slot ? 'update_slot' : 'add_slot'}
                closeModal={closeModal}
                content={
                    <BookingSlotForm
                        date={date}
                        slot={slot}
                        setDate={setDate}
                        rooms={rooms}
                        activities={activities}
                        selectedRoom={selectedRoom}
                        form={form}
                        error={error}
                        handleAddSlot={handleAddSlot}
                    />
                }
                actionButtons={
                    <>
                        <button
                            type='button'
                            className='btn btn-gray'
                            onClick={closeModal}
                        >
                            {t('cancel')}
                        </button>
                        {slot !== undefined && (
                            <button
                                type='button'
                                className='btn-danger'
                                onClick={() => setIsDeleteDialogOpen(true)}
                            >
                                {t('remove')}
                            </button>
                        )}
                        <button
                            className='btn btn-blue'
                            onClick={handleSubmit(handleAddSlot)}
                            disabled={
                                !isValid ||
                                getValues('activityId') === t('select_session')
                            }
                        >
                            {t('submit')}
                        </button>
                    </>
                }
            />
            {slot !== undefined && isDeleteDialogOpen && (
                <ConfirmDeleteDialog
                    onConfirm={() => handleDeleteSlot(slot)}
                    onCancel={() => setIsDeleteDialogOpen(false)}
                    content={<p>{t('delete-slot-warning')}</p>}
                />
            )}
        </>
    );
}
