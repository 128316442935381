import {
    eachDayOfInterval,
    endOfWeek,
    format,
    isWeekend,
    startOfWeek
} from 'date-fns';
import { WeekType } from '../api-types/models/Booking';

export function getDaysArray(weekType: WeekType): string[] {
    // Create an array of dates for each day of the week (e.g., Sunday to Saturday).
    const weekDays = eachDayOfInterval({
        start: startOfWeek(new Date(), { weekStartsOn: 1 }),
        end: endOfWeek(new Date(), { weekStartsOn: 1 })
    }).filter((day) =>
        weekType === WeekType.WORKING ? !isWeekend(day) : true
    );

    // Map the date objects to their respective day names using the `format` function.
    return weekDays.map((date) => format(date, 'EEEE'));
}
