import { API } from 'aws-amplify';
import { MenuItemDto } from '../../api-types';
import formatDate from '../../utils/formatDate';
import { Category } from '../../api-types/models/Category';
import { Tax } from '../../api-types/models/Tax';
import { handleError } from '../../utils/handleError';

export function getMenu(date: Date): Promise<MenuItemDto[]> {
    return API.get('brunch-v2', `/menu/${formatDate(date)}`, {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export function uploadMenu(formData: FormData): Promise<MenuItemDto[]> {
    return API.post('brunch', '/menu/items', { body: formData })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export function deleteMenuItem(menuItem: MenuItemDto): Promise<boolean> {
    return API.del(
        'brunch-v2',
        `/menu/offer/${menuItem.id}/${menuItem.uuid}`,
        {}
    )
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function uploadMenuItemPhoto(
    menuItem: MenuItemDto,
    formData: FormData
): Promise<MenuItemDto | { error: string }> {
    return API.post('brunch-v2', `/menu/${menuItem.uuid}/image`, {
        body: formData
    })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function deleteMenuItemPhoto(
    menuItem: MenuItemDto
): Promise<MenuItemDto | { error: string }> {
    return API.del('brunch-v2', `/menu/${menuItem.uuid}/image`, {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function publishMenuItems(
    menuItems: string[],
    date?: string
): Promise<MenuItemDto[] | { error: string }> {
    return API.post('brunch-v2', '/menu/offer', { body: { date, menuItems } })
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function getGroups(): Promise<Category[] | { error: string }> {
    return API.get('brunch-v2', '/menu/groups', {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function getCategories(): Promise<Category[] | { error: string }> {
    return API.get('brunch-v2', '/menu/categories', {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function getAllergens(): Promise<Category[] | { error: string }> {
    return API.get('brunch-v2', '/menu/allergens', {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}

export async function getTaxOptions(): Promise<Tax | { error: string }> {
    return API.get('brunch-v2', '/menu/tax', {})
        .then((response) => response)
        .catch((error) =>
            handleError(
                error?.response?.data !== ''
                    ? error?.response?.data
                    : error.response ?? error
            )
        );
}
