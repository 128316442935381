import './index.css';
import React, { useEffect, useState } from 'react';
import IPage from '../../interfaces/page';
import logging from '../../config/logging';
import Menu from '../../components/menu';
import { useAppSelector } from '../../app/hooks';
import { selectDate } from '../../components/calendar/calendarSlice';
import { LoginType } from '../../api-types/models/LoginDto';
import { selectAuthUser } from '../../app/authSlice';
import { getUserGroups } from '../../utils/getUserGroups';

const HomePage: React.FunctionComponent<IPage> = (props) => {
    const selectedDate = useAppSelector(selectDate);
    const user = useAppSelector(selectAuthUser);

    const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);

    const loginType = localStorage.getItem('login');

    useEffect(() => {
        logging.info(`Loading ${props.name}`);
    }, [props.name]);

    return (
        <div className='home'>
            <div
                className={`home-content ${
                    user && getUserGroups(user).includes(LoginType.BRUNCH)
                        ? 'brunch'
                        : ''
                }`}
            >
                <Menu
                    date={selectedDate}
                    showSuccessToast={showSuccessToast}
                    setShowSuccessToast={setShowSuccessToast}
                    loginType={loginType}
                />
            </div>
        </div>
    );
};

export default HomePage;
