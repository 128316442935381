import './index.css';
import React, { ReactNodeArray, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';

import {
    addDays,
    addWeeks,
    format,
    isSameDay,
    isSameWeek,
    startOfWeek,
    subWeeks
} from 'date-fns';

import { enGB, sl } from 'date-fns/locale';

import { selectDate, setDate } from './calendarSlice';
import { selectAuthUser } from '../../app/authSlice';
import { isBrunchUser } from '../../utils/isBrunchUser';

const Calendar: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();

    const selectedDate = useAppSelector(selectDate);
    const user = useAppSelector(selectAuthUser);

    const lang = i18n.language;

    const loc = lang === 'en' ? enGB : sl;

    const [currentDay, setCurrentDay] = useState(selectedDate);
    const [previousWeek, setPreviousWeek] = useState<boolean>(false);
    const [nextWeek, setNextWeek] = useState<boolean>(false);

    useEffect(() => {
        if (isSameWeek(new Date(), currentDay)) {
            setPreviousWeek(false);
            setNextWeek(false);
        }
    }, [currentDay, selectedDate]);

    const handleChangeDate = (day: Date) => {
        dispatch(setDate(day));
    };

    const renderDays = () => {
        const dateFormat1 = 'iii';
        const dateFormat2 = 'dd';
        const days = [] as ReactNodeArray;
        const startDate = startOfWeek(currentDay, { weekStartsOn: 1 });
        for (let i = 0; i < 5; i++) {
            const day = addDays(startDate, i);
            days.push(
                <div
                    className={`day ${
                        isSameDay(day, selectedDate) ? 'selected' : ''
                    }`}
                    key={i}
                    onClick={() => handleChangeDate(day)}
                >
                    <div className='number'>
                        {format(addDays(startDate, i), dateFormat2, {
                            locale: loc
                        })}
                    </div>
                    <div className='weekday'>
                        {format(addDays(startDate, i), dateFormat1, {
                            locale: loc
                        })}
                    </div>
                </div>
            );
        }
        return <div className='week'>{days}</div>;
    };

    const changeWeekHandle = (btnType: string) => {
        if (btnType === 'prev') {
            setCurrentDay(subWeeks(currentDay, 1));
            setPreviousWeek(true);
        }
        if (btnType === 'next') {
            setCurrentDay(addWeeks(currentDay, 1));
            setNextWeek(true);
        }
    };

    return (
        <div className='weekly'>
            <div className='button-wrapper'>
                <div
                    className='button prev'
                    hidden={previousWeek && !isBrunchUser(user)}
                    onClick={() => changeWeekHandle('prev')}
                >
                    <div className='icon'>
                        <img src='/images/ico_prev.png' alt='previous' />
                    </div>
                </div>
            </div>
            {renderDays()}
            <div className='button-wrapper'>
                <div
                    className='button next'
                    hidden={nextWeek && !isBrunchUser(user)}
                    onClick={() => changeWeekHandle('next')}
                >
                    <div className='icon'>
                        <img src='/images/ico_next.png' alt='next' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calendar;
