import './index.css';
import { useAppSelector } from '../../app/hooks';
import {
    selectPreparedConstantMenuItems,
    selectPreparedMenuItems
} from '../../app/menuSlice';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { format } from 'date-fns';

interface IProps {
    handleSubmitPreparedMenuItems: () => void;
    closeModal: () => void;
}

export function SummaryForPreparedMenuItems({
    handleSubmitPreparedMenuItems,
    closeModal
}: IProps) {
    const { t } = useTranslation();

    const preparedMenuItems = useAppSelector(selectPreparedMenuItems);
    const preparedConstantMenuItems = useAppSelector(
        selectPreparedConstantMenuItems
    );

    return (
        <div className='summary'>
            <h3>{t('summary_title')}</h3>
            <div className='menu-items'>
                {Object.keys(preparedMenuItems)
                    .sort((a, b) => (a < b ? -1 : 1))
                    .map((date) => (
                        <div key={date} className='date'>
                            <h3>{format(new Date(date), 'PPPP')}</h3>
                            {Object.keys(
                                groupBy(preparedMenuItems[date], 'costCenter')
                            ).map((costCenter: string) => (
                                <div key={`${date}-${costCenter}`}>
                                    <h4>{costCenter}</h4>
                                    {groupBy(
                                        preparedMenuItems[date],
                                        'costCenter'
                                    )[costCenter]?.map((menuItem) => (
                                        <p key={`${date}-${menuItem.uuid}`}>
                                            {menuItem.title}
                                        </p>
                                    ))}
                                </div>
                            ))}
                            <br />
                            <hr />
                        </div>
                    ))}
                <h3>{t('constants')}</h3>
                {preparedConstantMenuItems.map((menuItem) => (
                    <p key={`constant-${menuItem.uuid}`}>{menuItem.title}</p>
                ))}
            </div>
            <div className='actions'>
                <button className='btn btn-gray' onClick={closeModal}>
                    {t('cancel')}
                </button>
                <button
                    className='btn btn-blue'
                    onClick={handleSubmitPreparedMenuItems}
                >
                    {t('confirm')}
                </button>
            </div>
        </div>
    );
}
