import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { MenuItemDto } from '../api-types';

export interface RegistryState {
    registryItems: MenuItemDto[];
}

const initialState: RegistryState = {
    registryItems: []
};

export const registrySlice = createSlice({
    name: 'registry',
    initialState,
    reducers: {
        setRegistryItems(state, action) {
            return {
                ...state,
                registryItems: action.payload
            };
        },
        updateRegistryItem(state, action) {
            const { payload } = action;
            return {
                ...state,
                registryItems: state.registryItems.map((item) =>
                    item.uuid !== action.payload.uuid ? item : payload
                )
            };
        },
        addRegistryItem(state, action) {
            return {
                ...state,
                registryItems: [action.payload, ...state.registryItems]
            };
        }
    }
});

export const selectRegistryItems = (state: RootState): MenuItemDto[] => {
    return state.registry.registryItems;
};

export const { setRegistryItems, updateRegistryItem, addRegistryItem } =
    registrySlice.actions;

export default registrySlice.reducer;
