import React, { Suspense } from 'react';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { isMobile, isSmartTV } from 'react-device-detect';
import { createRoot } from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import PiwikPro from '@piwikpro/react-piwik-pro';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

const msalInstance = new PublicClientApplication(msalConfig);

// Initialize PiwikPro analytics
PiwikPro.initialize(
    process.env.REACT_APP_ANALYTICS_APP_ID ?? '',
    process.env.REACT_APP_ANALYTICS_APP_URL ?? ''
);

// Redirect to custom schema for mobile devices
if (
    isMobile &&
    !isSmartTV &&
    !['/status', '/observer', '/bar'].includes(location.pathname)
) {
    switch (location.pathname) {
        case '/login':
            window.location.href = `kitchenapp://${location.hostname}${location.pathname}${location.search}`;
            break;
        case '/logout':
            window.location.href = `kitchenapp://${location.hostname}${location.pathname}`;
            break;
        default:
            break;
    }

    root.render(
        <React.StrictMode>
            <h2 className='mobile-warning'>
                There is a mobile app available. Please download it and use it!
            </h2>
        </React.StrictMode>
    );
} else {
    root.render(
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <Suspense fallback={<div> Loading </div>}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Suspense>
            </Provider>
        </MsalProvider>
    );
}
