import { BookingSlot } from '../api-types/models/Booking';
import {
    addDays,
    addHours,
    addMinutes,
    areIntervalsOverlapping
} from 'date-fns';

export function findSlotsForTimeFrame(
    startOfWeekDate: Date,
    day: number,
    hour: number,
    slotsPerWeek: BookingSlot[]
) {
    const date = addDays(startOfWeekDate, day).setHours(hour);
    const slots: BookingSlot[] = [];
    slotsPerWeek.forEach((slot) => {
        const intevalLeft = {
            start: new Date(slot.from),
            end: addMinutes(slot.from, slot.duration)
        };
        const intervalRight = { start: new Date(date), end: addHours(date, 1) };

        if (
            areIntervalsOverlapping(intevalLeft, intervalRight) &&
            !slots.includes(slot)
        ) {
            slots.push(slot);
        }
    });

    return slots;
}
